:root {
  --mainColor: #5659ff;
  --secondColor: #a483f0;
  --azure: #269ae2;
  --textColor: #2e3242;
  --lightGrey: #eaedf0;
  --milkyWhite: rgb(247, 247, 247);
  --danger: #ff5869;
  --font: Sohne, sans-serif;
  --logoFont: "Tilt Warp", sans-serif;
}

/* Scroll bars */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 6px;

}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Logo page */
.logo_page_cont {
  width: 100%;
  height: 100vh;
  text-align: center;
  font-size: 60px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.logo_cont_page {
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo_cont_page p {
  margin-bottom: 0px;
}

.logo_cont_page span {
  margin-bottom: 0px;
  font-size: 45px;
}

.logo_cont_page .last_span_title {
  font-size: 20px;
}

#title_logo {
  margin-top: 200px;
  font-size: 180px;
  color: var(--textColor);
  /* font-weight: bold; */
  margin-bottom: 0px;
  cursor: pointer;
  font-family: var(--logoFont);
}

#title_logo i {
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slogan {
  margin-left: 100px;
  font-size: 40px;
}

/* Policies */
.policies_container {
  text-align: start;
}

.policies_cont {
  border: 2px solid var(--lightGrey);
  border-radius: 20px;
  background-color: var(--milkyWhite);
  width: 50%;
  margin: auto;
  padding: 20px;
  margin-bottom: 50px;
}

.policy_title_logo {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  align-items: center;
  width: 50%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 0px;
  cursor: pointer;
}

.policy_title_logo i {
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.policy_title_logo p {
  font-family: var(--logoFont);
  margin-bottom: 0px;
}

.title_policy {
  font-size: 30px;
  margin-bottom: 5px;

}

.declaration_policy {
  font-size: 12px;
  margin-bottom: 10px;
}

.section_titles {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}

.section_sub_title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description_policy_title {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 12px;
}

.description_policy {
  margin-bottom: 10px;
  font-size: 12px;
}

/* Body */
body {
  background-color: white;
  font-family: var(--font);
  font-size: 20px;
  color: var(--textColor);
}

/* Main Frame For all pages */
.fade-in-section {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-in-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.main_frame {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* Navbar */
.main_navbar {
  position: sticky;
  top: 0;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  backdrop-filter: blur(10px);
  z-index: 25;
  border-radius: 10px;
  padding: 5px;
  transition: all 0.3s ease;
}

.main_navbar.scrolled {
  top: 20px;
  width: 80%;
  box-shadow: 2px 2px 20px 10px rgb(245, 245, 250, 0.8);
}

.navbar {
  width: 90%;
  margin: auto;
  height: 50px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.main_navbar a {
  color: var(--textColor);
  text-decoration: none;
}

.nav_cont_1 {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav_cont_1 span {
  font-size: 15px;
}

.nav_cont_1:last-child {
  flex-direction: row-reverse;
}

.nav_cont_1 a {
  text-decoration: none;
}

#title_navbar {
  font-size: 25px;
  color: var(--textColor);
  margin-bottom: 0px;
  cursor: pointer;
  font-family: var(--logoFont);
}

#title_navbar i {
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.links_navbar {
  margin-right: 20px;
}

.waitin_list_btn {
  text-align: center;
  border: 1px solid var(--lightGrey);
  width: auto;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  background: linear-gradient(to right, var(--secondColor), var(--azure));
  background-clip: padding-box;
}

.waitin_list_btn_home{
  text-align: center;
  border: 1px solid var(--lightGrey);
  width: auto;
  padding: 15px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  color: white;
  background: linear-gradient(to right, var(--secondColor), var(--azure));
  background-clip: padding-box;
}

.register_btn {
  text-align: center;
  border: 1px solid var(--lightGrey);
  width: auto;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 15px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login_btn {
  text-align: center;
  border: 1px solid transparent;
  background-color: transparent;
  width: auto;
  padding: 5px;
  border-radius: 4px;
  color: var(--textColor);
  font-size: 15px;
}

/* Policy popup */
.policy_popup_cont {
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
  padding: 20px;
  height: 200px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 150px;
  z-index: 20;
  background-color: white;
}


/* Main Content */
.main_content {
  height: 100vh;
  text-align: center;
  /* margin-bottom: 50px; */
}

.main_section {
  display: flex;
  padding-top: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.scroller_cont {
  width: 60%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 60px;
  font-weight: bolder;
}

.main_title {
  margin-bottom: 0px;
}

.descriptions_sec {
  width: 100%;
  font-weight: bold;
  margin: auto;
  text-align: center;
  height: 100px;
}

.main_description {
  color: gray;
  font-size: 20px;
}

.ia_span {
  font-weight: bold;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.widget_scroller_arrow_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 735px;
  margin: auto;
  transition: all 0.2s ease-in;
}

.arrow_widget_scroller {
  border: 1px solid transparent;
  background-color: transparent;
  font-size: 20px;
}

.widget_scroller {
  height: 100px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.scrolling {
  display: flex;
  width: max-content;
  transition: all 0.5s ease;
  /* Adjust timing and easing as necessary */
}

.widget_element {
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  width: 220px;
  height: 42px;
  border-radius: 50px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--textColor);
  font-weight: bold;
  transition: border 0.5s ease-in-out;
  cursor: pointer;
}


.widget_element:hover {
  border: 1px solid var(--mainColor);
}

.widget_element span {
  font-size: 25px;
  height: 100%;
  width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-around;

}

.widget_element p {
  width: 170px;
  text-align: center;
  font-size: 15px;
  margin-bottom: 0px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* @keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
} */

.scrolling {
  display: flex;
  animation: scroll 10s linear infinite;
}


.scrolling {
  display: flex;
  animation: scroll 10s linear infinite;
}

/* Globe Section */
.globe_home{
  background-color: var(--textColor);
  width: 100%;
  height: 800px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  /* box-shadow: 2px 2px 1000px 2px var(--secondColor); */
  border-radius: 30px;
  margin-bottom: 100px;
}
.globe_elem{
  width: 50%;
  height: 100%;
  color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;  
}

.title_globe_cont{
  width: 500px;
}

.globe_title{
  font-size: 40px;
  margin-bottom: 10px;
}

.globe_subtitle{
  font-size: 20px;
}


/* OnBoarding Section */
.onboarding_container {
  height: 100%;
  width: 100%;
  text-align: center;
  margin-bottom: 200px;
}

.steps_title {
  font-size: 35px;
  font-weight: bold;
}

.steps_desc {
  font-weight: bold;
  color: gray;
}

.onboarding_sections {
  height: 300px;
  width: 90%;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card_onboarding {
  border: 2px solid var(--lightGrey);
  width: 23%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
  transition: all 0.3s ease-in;
  padding: 10px;
  border: 1px solid var(--lightGrey);
  height: 100%;
  background-color: white;
}

.card_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  text-align: start;
  background-color: transparent;
  opacity: 0.8;
}

.arrow {
  display: none;
  /* Hide arrows on larger screens */
}

.card_icon {
  margin-right: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  font-size: 20px;
  color: var(--danger);
  border: 2px solid var(--danger);
  border-radius: 10px;
  width: 40px;
  padding: 5px;
  text-align: center;
}

.card_title p {
  margin-bottom: 0px;
}

.card_text {
  height: 80%;
  padding: 10px;
  text-align: start;
  border-radius: 20px;
  background-color: white;
}

.personalized_words {
  font-weight: bold;
  color: var(--mainColor);
}

/* payment Process */
.payment_process_container {
  height: 100%;
  width: 100%;
  text-align: center;
  height: 800px;
  background-color: var(--milkyWhite);
  padding: 20px;
  margin-bottom: 100px;
}

.pay_cont {
  border: 2px solid var(--lightGrey);
  border-radius: 20px;
  height: 550px;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pay_container {
  width: 50%;
  margin: auto;
  height: 100%;
  padding: 20px;
}

.phone_cont {
  border: 1px solid var(--textColor);
  background-color: var(--textColor);
  width: 270px;
  margin: auto;
  height: 95%;
  border-radius: 30px;
  margin-bottom: 2%;
  padding: 3px;
}

.phone_screen {
  border: 1px solid var(--lightGrey);
  width: 100%;
  height: 100%;
  border-radius: 27px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.phone_camera {
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  width: 80px;
  margin: auto;
  height: 12px;
  border-radius: 50px;
}

.phone_content {
  height: 90%;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid black; */
}

.phone_hour {
  font-size: 50px;
  margin-bottom: 0px;
}

.phone_date {
  font-size: 15px;
  margin-bottom: 0px;
  color: gray;
  margin-bottom: 20px;
}

.phone_notification_cont {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes scaleUp {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.phone_notification {
  height: 50px;
  width: 95%;
  border: 1px solid var(--lightGrey);
  background-color: rgb(128, 128, 128, 0.1);
  filter: blur(0.1);
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 10px;
  transition: transform 0.5s ease-in-out;
}

.phone_notification.active {
  animation: scaleUp 1s ease-in-out;
}

.message_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.message_header {
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message_header i {
  font-size: 12px;
  color: var(--mainColor);
  margin-right: 5px;
}

.message_title {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 0px;
  width: 80%;
  text-align: start;
}

.hour_mex {
  font-size: 10px;
  color: gray;
}

.message_not {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}

.message_not p {
  margin-bottom: 0px;
  margin-left: 20px;
  font-size: 10px;
}

.phone_bar {
  height: 5px;
  background-color: var(--lightGrey);
  border: 1px solid var(--lightGrey);
  border-radius: 50px;
  width: 120px;
  margin: auto;
}

.phone_shadow {
  /* background-color: var(--lightGrey); */
  height: 0%;
  width: 240px;
  margin: auto;
  box-shadow: 2px 20px 30px 3px black;
  border-radius: 150px;
}

.pay_desc_title {
  width: 100%;
  text-align: start;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 25px;
}

.pay_description {
  height: auto;
  margin-top: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid var(--mainColor);
  text-align: start;
}

/* Calendar Process */
.calendar_manage_container {
  height: 100%;
  width: 100%;
  text-align: center;
  height: 100%;
  margin-bottom: 100px;
}

.calendar_process {
  width: 100%;
}

.cal_cont {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.cal_sec {
  width: 50%;
  height: 400px;
}

.long_term_img {
  height: 90%;
  width: 90%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../public/IMG/scooter_boy.jpg");
}

.cal_desc_cont {
  margin: auto;
  margin-top: 10%;
  height: auto;
  width: 80%;
  text-align: start;
  border: 2px solid var(--danger);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
}

.cal_desc_title {
  font-size: 20px;
  font-weight: bold;
  color: var(--danger);
}

/* Property marketing */
.property_marketing {
  width: 1200px;
  height: auto;
  margin-bottom: 100px;
  max-width: 100%;
  margin: auto;
  text-align: center;
  overflow: hidden;
  margin-bottom: 200px;
}

.arrow_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 20px;
}

.arrow_widget_scroller_prop {
  border: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  width: 100px;
  font-size: 20px;
  border-radius: 10px;
}

.property_marketing_cont {
  width: 95%;
  height: 500px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  transition: transform 0.5s ease;
}

.property_marketing_card {
  width: 600px;
  height: 100%;
  border-radius: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.property_marketing_card_img_cont {
  border: 1px solid var(--lightGrey);
  width: 100%;
  height: 65%;
  border-radius: 20px;
  box-shadow: 2px 2px 20px 2px var(--milkyWhite);
}

.property_marketing_desc_cont {
  width: 100%;
  height: 35%;
  /* border: 2px solid var(--lightGrey); */
  padding: 5px;
  background-color: white;
}

.description_title {
  width: 100%;
  color: var(--mainColor);
  text-align: start;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 20px;
}

.description_desc {
  width: 100%;
  font-size: 15px;
  text-align: start;
}

/* Website building */
.website_building_container {
  border: 1px solid black;
  width: 100%;
  margin-bottom: 100px;
  height: 500px;
}

/* Tenand or landlord */
.main_tll {
  width: fit-content;
  margin: auto;
  height: 100vh;
}

.greetings {
  font-size: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
}

.main_tll_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.choise_box {
  border: 2px solid var(--lightGrey);
  width: 400px;
  margin-right: 30px;
  height: 400px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
}

.choise_box:hover {
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
  border: 2px solid gray;
}

.choise_box.selected {
  border-color: var(--mainColor);
}

/* Choise box DEMO */
.demo_box{
  pointer-events: none;
  filter: blur(5px);
}
/* ______________ */

.moving_arrows_btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}


.choise_box_title {
  width: 100%;
  font-size: 35px;
  color: gray;
  text-align: center;
}

.img_container {
  height: 280px;
  width: 250px;
}

.img_cont_house {
  background-image: url("../public/IMG/roof_red_house.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.img_cont_boxes {
  background-image: url("../public/IMG/boxes2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.img_cont_re_broker {
  background-image: url("../public/IMG/re_broker.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

/* Welcome Message */
.fade-container {
  opacity: 0;
  transition: opacity 4s ease-in-out;
}

.fade-container.fade-in {
  opacity: 1;
}

.fade-container.fade-out {
  opacity: 0;
}

.end_register {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

#welcome_title {
  font-size: 70px;
  color: var(--textColor);
  /* font-weight: bold; */
  margin-bottom: 0px;
  cursor: pointer;
  font-family: var(--logoFont);
}

#welcome_title i {
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.go_dashboard {
  color: var(--mainColor);
  text-decoration: none;
  font-size: 30px;
  cursor: pointer;
}

.scroller_cont_welcome {
  height: 150px;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: center;
}

.scrollable_titles_welcome {
  margin-bottom: 0px;
  font-size: 135px;
  height: 150px;
  font-weight: bold;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: scrollUpWelcome 15s ease-in-out infinite;
  display: inline-block;
}

@keyframes scrollUpWelcome {

  0%,
  10% {
    transform: translateY(0);
  }

  10%,
  20% {
    transform: translateY(-150px);
  }

  20%,
  30% {
    transform: translateY(-300px);
  }

  30%,
  40% {
    transform: translateY(-450px);
  }

  40%,
  50% {
    transform: translateY(-600px);
  }

  50%,
  60% {
    transform: translateY(-750px);
  }

  60%,
  70% {
    transform: translateY(-900px);
  }

  70%,
  80% {
    transform: translateY(-1050px);
  }

  80%,
  90% {
    transform: translateY(-1200px);
  }

  90%,
  100% {
    transform: translateY(-1350px);
  }



}

/* Moving Arrows */
.moving_arrow_cont {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.moving_arrows_btn {
  height: 100%;
  width: 100px;
  border: 2px solid var(--lightGrey);
  background-color: var(--lightGrey);
  color: var(--mainColor);
  border-radius: 5px;
  font-size: 25px;
}

/* Pricing */
.pricing_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.pricing_block {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 30px;
  border: 1px solid var(--lightGrey);
  padding: 50px;
  margin-bottom: 150px;
}

.pricing_block_title_cont {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 50%;
}

.pricing_block_title {
  font-size: 60px;
  width: 100%;
  text-align: start;
}

.pricing_block_subtitle {
  font-size: 20px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pricing_block_main {
  border: 1px solid var(--lightGrey);
  width: 550px;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 2px 2px 20px 2px var(--milkyWhite);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
}

.method_pricing_block {
  height: 40%;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.method_pricing_title {
  font-weight: bold;
  color: gray;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.method_pricing_block_inner {
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  border-radius: 10px;
  width: 48%;
  height: 100%;
  text-align: center;
  padding: 10px;
}

.flat_fee{
  width: 100%;
}

.method_pricing_block_title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
}

.fee_cost {
  font-size: 30px;
  font-weight: bold;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fees_table_container {
  height: 100%;
  width: 80%;
  margin: auto;
  margin-bottom: 100px;
}

.fee_table_title {
  font-weight: bold;
  color: var(--textColor);
}

.fee_table {
  width: 100%;
  height: auto;
}

.body_fee {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.body_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
  border: 1px solid var(--lightGrey);
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.body_cell_first,
.body_cell_second,
.body_cell_third {
  width: 33%;
  height: 100%;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: var(--textColor);
}

.body_cell_first {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list_functionallities_title {
  font-size: 15px;
}

.list_functionallities_section {
  font-size: 12px;
  margin-bottom: 5px;
}

.list_functionallities_section i {
  color: var(--mainColor);
}

.list_functionallities_section .circle {
  color: seagreen;
}

.fee_third {
  font-size: 15px;
  color: seagreen;
  text-decoration: underline;
  cursor: pointer;
}

.body_cell_third {
  border: 1px solid var(--lightGrey);
  width: 30%;
  margin: auto;
  text-align: center;
  color: var(--mainColor);
}

.show_fee {
  border: 1px solid var(--mainColor);
  transition: all 0.5s ease-in;
}

.hide_fee {
  border: 1px solid var(--lightGrey);
  transition: all 0.5s ease-in;
}

/* Payment transactions */
.payment_transactions_main_cont {
  width: 80%;
  margin: auto;
  height: 500px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 200px;
  padding: 50px;
}

.payment_transactions_cont {
  position: relative;
  width: 80%;
  margin: auto;
  height: 500px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 200px;
  padding: 50px;
  border-radius: 30px;
  background-color: white;
  z-index: 1;
}

.payment_transactions_cont::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  padding: 2px;
  background: linear-gradient(90deg, var(--mainColor), var(--milkyWhite), white, var(--milkyWhite), var(--secondColor), var(--milkyWhite), var(--azure));
  background-size: 300% 300%;
  animation: rotate-border 5s linear infinite;
  z-index: -1;
  mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  -webkit-mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask-composite: exclude;
}

@keyframes rotate-border {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.payment_transaction_title_cont {
  width: 50%;
  height: 100%;
}

.payment_transaction_title {
  font-size: 60px;
}

.payment_transaction_subtitle {
  font-size: 20px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.transactions_punchline {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  width: 70%;
  margin: auto;
  height: 400px;
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.svg_cont {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 20px;
}

.svg_cont svg {
  width: 100%;
  height: 110%;
  color: white;
  rotate: 90deg;
}

.transaction_title {
  font-size: 35px;
  text-align: start;
  font-weight: bold;
  margin-bottom: 0px;
}

.transaction_subtitle {
  width: 100%;
  padding-left: 5px;
  color: gray;
  font-weight: bold;
}

.transaction_desc {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* box-shadow: 2px 2px 10px 2px var(--lightGrey); */
  border: 1px solid var(--lightGrey);
  padding: 10px;
}

.bullet_points {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 10px;
}

.bullet_points p {
  margin-bottom: 0px;
}


.bullet_point_explanation_title {
  font-size: 18px;
  font-weight: bold;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
}

.bullet_point_explanation {
  font-weight: bold;
  color: gray;
  font-size: 15px;
}

/* Animations transactions */
.phone_transactions_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tenant_manager_container {
  border: 2px solid var(--lightGrey);
  border-radius: 20px;
  width: 300px;
  height: 40px;
  margin-bottom: 20px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.tenant_manager_btns {
  border: 1px solid lightgray;
  background-color: var(--lightGrey);
  width: 32%;
  height: 100%;
  font-size: 12px;
  border-radius: 20px;
  transition: all 0.3s ease-in;
  font-weight: bold;
  color: gray;
  pointer-events: none;
}

.tenant_manager_btns:hover {
  background-color: var(--mainColor);
  color: white;
  border: 1px solid var(--mainColor);
}

.phone_transaction {
  border: 3px solid lightgray;
  border-bottom: 0px;
  height: 350px;
  width: 300px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 5px;
}

.phone_screen_transaction {
  border: 1px solid var(--lightGrey);
  border-radius: 27px;
  height: 100%;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding-top: 30px;
  box-shadow: 2px 2px 10px 2px var(--lightGrey);

}

.transaction_section {
  min-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.transaction_section_title {
  color: var(--mainColor);
  font-weight: bold;
  opacity: 0.8;
  font-size: 15px;
  margin-bottom: 0px;
  height: 10%;
}

.creation_tenant_transaction_cont {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  border-radius: 20px;
  height: 80%;
  width: 100%;
  overflow: hidden;
  padding: 10px;
}

.rows_transactions {
  height: 30%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding-left: 10px;
  justify-content: space-between;
  transform: scale(0.8);

}

.add_rows_transactions {
  width: 15%;
  text-align: center;
  color: var(--secondColor);
  border: 1px solid var(--secondColor);
  height: 70%;
  border-radius: 10px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.content_row_transaction {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  animation: grow 0.5s ease-in-out forwards
}

@keyframes grow {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.rows_transactions_animation {
  animation: grow 0.5s ease-in-out forwards;
  cursor: pointer;
}

.rows_transactions:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.rows_transactions_img {
  border: 1px solid var(--azure);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: var(--azure);
}

.rows_transactions_tenant {
  border: 1px solid white;
  width: 70%;
  height: 15px;
  border-radius: 50px;
  background-color: white;
}

.combine_transaction_property {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prop_tenant_transaction {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.rows_transactions_img_house {
  height: 35px;
  width: 35px;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
}

#ball,
#house {
  width: 70px;
  height: 70px;
  position: relative;
}

/* Define the animations */
@keyframes moveRight {
  from {
    left: 0;
  }

  to {
    left: 50%;
  }
}

@keyframes moveLeft {
  from {
    left: 0;
  }

  to {
    left: -50%;
  }
}

.animate-ball {
  animation: moveRight 2s ease forwards;
}

.animate-house {
  animation: moveLeft 2s ease forwards;
}


.payment_transaction_cont {
  border: 1px solid var(--milkyWhite);
  width: 100%;
  height: 70%;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test {
  font-size: 80px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: bounce 2s infinite;
}

.shadow_pay {
  background-color: gray;
  box-shadow: 2px 2px 5px 2px gray;
  width: 70px;
  height: 10px;
  border-radius: 100%;
  opacity: 0.2;
}

/* About us */
.about_us_container {
  border: 1px solid black;
  width: 80%;
  margin: auto;
  height: 1000vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* Waitlist form */
.waiting_list_container {
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
}

.waiting_list_cont {
  border: 2px solid transparent;
  height: 100%;
  width: 49%;
  margin: auto;
  border-radius: 30px;
  padding: 20px;
}

.waiting_list_form_title {
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: bold;
  color: gray;
}

.first_waiting {
  width: 49%;
  border: 2px solid transparent;
  color: var(--mainColor);
  /* background-color: var(--mainColor); */
}

.waitlist_title {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-bottom: 0px;
  cursor: pointer;
}

.waitlist_title i {
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.waitlist_title p {
  font-family: var(--logoFont);
  margin-bottom: 0px;
  color: var(--textColor);
}

.main_img_waitlist {
  background-image: url("../public/IMG/city.jpg");
  height: 450px;
  width: auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.input_label_waitlist_cont {
  border: 1px solid transparent;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 10px;
  margin-bottom: 10px;
}

.user_type_container_waitlist label,
.input_label_waitlist_cont label {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  color: gray;
}

.input_waitlist {
  width: 60%;
  border: 1px solid transparent;
  border-bottom: 2px solid var(--lightGrey);
  height: 35px;
  font-size: 15px;
  font-weight: bold;
  transition: all 0.3s ease-in;
  color: var(--mainColor);
}

.input_waitlist:focus {
  outline: none;
  font-weight: normal;
  transition: all 0.3s ease-in;
  color: var(--textColor);
}

.input_waitlist:hover {
  border-bottom: 2px solid var(--mainColor);
}

.user_type_container_waitlist {
  /* border: 1px solid var(--lightGrey); */
  height: 215px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* padding: 20px; */
  margin-bottom: 20px;
}

.user_type_container_waitlist_cont {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.user_type_card_waitlist {
  border: 2px solid var(--lightGrey);
  /* background-color: var(--milkyWhite); */
  height: 100%;
  width: 180px;
  padding: 10px;
  margin-right: 20px;
  transition: all 0.3s ease-in;
  cursor: pointer;

}

.selected_usertype {
  border: 2px solid var(--mainColor);
  border-radius: 10px;
  color: var(--mainColor);
}

.user_type_card_waitlist:hover {
  border-radius: 10px;
  box-shadow: 2px 2px 5px 2px var(--lightGrey);
  border: 2px solid lightgray;
  color: var(--mainColor);
}

.usertype_title {
  width: 100%;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
}

.img_cont_waitlist {
  height: 130px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.problematics_cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.options_problems {
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  height: 30px;
  font-size: 13px;
  margin-right: 10px;
  padding: 5px;
  border-radius: 50px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.options_problems:hover {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
}

.selected_problem {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
}

.waitlist_btn_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.waitin_list_btn_start {
  text-align: center;
  border: 1px solid var(--lightGrey);
  width: auto;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  background: linear-gradient(to right, var(--secondColor), var(--azure));
  background-clip: padding-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 220px;
  height: 40px;
  margin-right: 10px;
}

.waitin_list_btn_start span {
  width: 100%;
  margin-right: 20px;
}

.waitin_list_btn_start .spinner_cont {
  width: 20px;
  height: 20px;
}

.waitin_list_btn_start #spinner {
  color: white;
}

.waitin_list_btn_no {
  border: 1px solid transparent;
  border-bottom: 1px solid gray;
  background-color: transparent;
  color: gray;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
}

.waitin_list_btn_no span {
  width: 100%;
  margin-right: 20px;
}

/* Waitlist confirmation */
.waitlist_confirm_main_cont {
  height: 100vh;
  width: 100%;
  margin: auto;
  padding: 20px;
}

.waitlist_confirm_title {
  width: 100%;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  color: var(--mainColor);
  margin-bottom: 0px;
}

.waitlist_confirm_subtitle {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: gray;
}

.suprise_waitlist {
  position: relative;
  width: 80%;
  margin: auto;
  height: 500px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  padding: 50px;
}


.suprise_waitlist_cont {
  width: 50%;
  height: 100%;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
  /* Smooth transition for both opacity and movement */
}

.waitlist_animation {
  opacity: 1;
}

.show_text {
  opacity: 1;
  transform: translateY(0);
}

.rocket_img {
  background-image: url("../public/IMG/rocket.jpg");
  height: 340px;
  width: 340px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  rotate: 0deg;
  margin: auto;
  border-radius: 50%;
  position: relative;
  top: -200px;
  transition: top 1s ease-in-out, opacity 1s ease-in-out, transform 1s ease-in-out;
  /* Add transform transition for rotation */
  opacity: 0;
}

.rocket_down {
  top: 0;
  opacity: 1;
  transform: rotate(0deg);
}

.rocket_up {
  top: -200px;
  opacity: 0;
  transform: rotate(45deg);
}

.explanation_waitlist_title {
  color: var(--mainColor);
  font-weight: bold;
  font-size: 45px;
  margin-bottom: 0px;
}

.explanation_waitlist_subtitle {
  color: gray;
  font-size: 25px;
  font-weight: bold;
}

.explanation_waitlist {
  border: 1px solid var(--lightGrey);
  padding: 20px;
}

.waitlist_confirm_btn_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.explanation_waitlist button {
  margin-top: 20px;
}

.explanation_waitlist p {
  margin-bottom: 10px;
  font-size: 20px;
  /* color: gray; */
}

.become_partner_btn {
  border: 2px solid var(--mainColor);
}

/* Check email */
.check_email_cont {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  height: 300px;
  width: 600px;
  margin: auto;
  margin-top: 100px;
  border-radius: 30px;
  color: white;
  padding: 20px;
  text-align: center;
}

.check_email_title {
  font-size: 40px;
  /* font-family: var(--logoFont); */
  font-weight: bold;
  margin-bottom: 20px;
}

.check_email_cont i {
  font-size: 60px;
  margin-bottom: 50px;
}

.close_home {
  font-size: 12px;
}

.link_email {
  color: white;
}

.link_email:hover {
  color: white;
}

/* Footer */
.footer_homepage {
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  height: 400px;
  width: 100%;
  margin: auto;
}

.footer_container {
  width: 60%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: 100px; */
}

.sections_footer {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
}

.footer_titles {
  font-weight: bold;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.link_cont_footer {
  height: 100%;
  width: 100%;
}

.link_footer {
  font-size: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.link_footer:hover {
  color: var(--mainColor);
}

.language_select {
  border: 1px solid var(--mainColor);
  font-size: 15px;
  background-color: var(--milkyWhite);
  padding: 5px;
  border-radius: 5px;
}

.language_select:focus {
  outline: none;
}

.section_footer_text {
  width: 100%;
  padding: 20px;
  height: 100%;
  margin: auto;
  /* margin-top: 100px; */
  /* margin-bottom: 300px; */
}

.brand_logo {
  font-weight: bold;
  font-size: 25px;
}

.brand_rights {
  font-size: 12px;
  margin-bottom: 5px;
}

.logo_social_container {
  margin: auto;
  text-align: start;
  width: 100%;
  margin-bottom: 20px;
}

.email_contact_footer {
  font-size: 15px;
  font-weight: bold;
}

.logo_social_container i {
  margin-right: 10px;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.logo_social_container i:hover {
  color: var(--mainColor);
}

.newsletter_cont {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.newsletter_title {
  margin-bottom: 0px;
  color: var(--mainColor);
  font-size: 15px;
}

.input_newsletter {
  border: 1px solid var(--mainColor);
  border-radius: 5px;
  font-size: 15px;
  padding-left: 5px;
  width: 200px;
  height: 30px;
  margin-right: 5px;
}

.input_newsletter:focus {
  outline: none;
}

.input_newsletter::placeholder {
  color: lightgray;
}

.button_newsletter {
  height: 30px;
  font-size: 20px;
  width: 40px;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.button_newsletter #spinner {
  color: white;
  width: 15px;
  height: 15px;
}


/* Register Initial page */
.register_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.register_conts_reg,
.register_conts {
  width: 100%;
  height: 100%;
}

.reg_cont {
  margin: auto;
  margin-top: 100px;
  width: 450px;
  height: auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* box-shadow: 200px 10px 1000px 1px var(--mainColor); */
  border: 2px solid var(--lightGrey);
  margin-bottom: 10px;
}

.title_form {
  font-family: var(--logoFont);
  text-align: start;
  width: 100%;
  color: var(--textColor);
  cursor: pointer;
}

.btn_a_start {
  width: 100%;
  height: 40px;
  /* border-radius: 10px; */
}

.start_btn {
  border: 2px solid var(--milkyWhite);
  font-size: 15px;
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  color: white;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in;
}

.start_btn:hover {
  border: 2px solid var(--mainColor);
}

.register_form_first {
  padding: 10px;
  border: 1px solid var(--lightGrey);
  height: 40px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  width: 100%;
  margin-bottom: 20px;
  color: gray;
  font-size: 15px;
}

.register_form_first:focus {
  outline: none;
}

.register_form_first::placeholder {
  font-size: 13px;
  color: rgb(184, 181, 181);
}

.persona_reg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.person_reg {
  width: 49%;
}

.password_eye {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  justify-content: space-between;
}

.show_passowrd {
  margin-bottom: 0px;
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  height: 40px;
  width: 15%;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 15px;
  border: 2px solid var(--lightGrey);
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.phone_number {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.phone_number select {
  width: 15%;
  margin-bottom: 20px;

  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  height: 40px;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 15px;
  border: 2px solid var(--lightGrey);
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.phone_number input {
  width: 83%;
}

.already_present {
  font-size: 12px;
  text-align: start;
  width: 100%;
  margin-bottom: 0px;
}

.already_present span {
  color: var(--mainColor);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.mini_logo {
  margin-bottom: 0px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.second_cont_reg {
  width: 100%;
  margin: auto;
  height: auto;
  border-radius: 5px;
  /* border: 2px solid var(--milkyWhite); */
  /* background-color: var(--milkyWhite); */
  margin-bottom: 10px;
}

.priv_mail_cont {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: gray;
  padding: 5px;
  border-radius: 5px;
}

.priv_mail_cont p {
  margin-bottom: 0px;
}

.priv_mail_cont input {
  margin-right: 5px;
  border: 1px solid var(--lightGrey);
  box-shadow: 0px 0px 5px 0px none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.priv_mail_cont input:hover {
  box-shadow: 0px 0px 3px 0px var(--azure);
}


/* Spinner and errors */
.spinner_cont {
  width: 100%;
  text-align: center;
}

#spinner {
  color: var(--mainColor);
  font-size: 15px;
  height: 20px;
  width: 20px;
}

.error_message {
  color: var(--danger);
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
}

/* Loading table */
.loading_table_cont{
  border: 1px solid var(--lightGrey);
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  margin: auto;
  height: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.row_loading_table{
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cell_loading_table{
  width: 16.3%;
  height: 100%;
  padding: 5px;
  border: 1px solid var(--milkyWhite);
  border-radius: 5px;
}

.inner_cell_tabl{
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.inner_cell_tabl::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--milkyWhite) 50%, rgba(255, 255, 255, 0) 100%);
  animation: beam 2s linear infinite;
}

/* Control panel */
.control_panel_cont {
  display: flex;
  flex-direction: column;
  background-color: var(--textColor);
}

.control_panel_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: var(--textColor);
}

/* main_nav_page */
.main_nav_page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-top-right-radius: 20px;
  overflow: hidden;
}

.open_main_nav_page{

}

/* Control navbar */
.control_navbar {
  width: 100%;
  height: 50px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  background-color: var(--textColor);
  color: white;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 25;
}

.control_nav_cont {
  width: 50%;
  display: flex;
  align-items: center;
}

.control_nav_cont:last-child {
  padding-right: 50px;
  flex-direction: row-reverse;
}

.icon_cont_nav {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

}

.control_nav_link {
  margin-bottom: 0px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.control_nav_link:hover {
  color: var(--mainColor);
}

.bell {
  padding: 0px;
  font-size: 20px !important;
  background-color: transparent !important;
  color: var(--textColor) m !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 0px 0px 0px transparent !important;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
}

.bell:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--mainColor) !important;
}

.bell::after {
  display: none !important;
  background-color: transparent;
  border: 1px solid transparent;
}

.bell_to_read {
  border: 2px solid white;
  height: 12px;
  width: 12px;
  margin-left: -10px;
  border-radius: 100px;
  background-color: var(--mainColor);
}

.custom-dropdown-menu-notification {
  font-size: 15px;
  border: 2px solid var(--lightGrey);
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  border-radius: 10px;
  padding: 5px;
  width: 300px;
}

.notifications_center {
  width: 100%;
}

.notifications_title {
  padding-left: 5px;
  padding-right: 10px;
  padding-top: 5px;
  font-weight: bold;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.notifications_title i {
  font-size: 12px;
  border-radius: 5px;
  color: var(--mainColor);
  padding: 2px;
  cursor: pointer;
}

.loading_notification {
  animation: flash 0.5s infinite;
}

.notification {
  font-size: 12px;
  border-bottom: 1px solid var(--lightGrey);
  margin-bottom: 5px;
  height: auto;
  padding: 5px;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.notification:hover {
  background-color: var(--lightGrey);
  cursor: pointer;
  border-radius: 5px;
}

.search_field {
  border: 1px solid transparent;
  border-radius: 5px;
  width: 200px;
  height: 30px;
}

.custom-dropdown-menu-settings {
  font-size: 15px;
  border: 2px solid var(--lightGrey);
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  border-radius: 10px;
  padding: 5px;
  width: 200px;
}

.clear_notifications_cont {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
  font-weight: bold;
  color: var(--mainColor);
}

.notification_cont {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 95%;
}

.clear_notification {
  margin-bottom: 0px;
  cursor: pointer;
}

.to_read {
  width: 7px;
  height: 7px;
  border-radius: 100px;
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
  margin-right: 5px;
}

.notification_title {
  font-weight: bold;
}

.chevron_notification {
  color: var(--mainColor);
  margin-right: 10px;
  font-size: 14px;
  transition: all 0.3s ease-in;
}

.notification_desc {
  font-size: 12px;
}

.notification_timestamp {
  text-align: end;
  width: 20%;
}

.notification_only {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.no_notifications {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--mainColor);
  margin-top: 10px;
  margin-bottom: 10px;

}

/* Switch test*/
.switch_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.switch {
  width: 45px;
  height: 25px;
  border-radius: 15px;
  position: relative;
  background-color: lightgray;
  transition: background-color 0.3s;
  border: 2px solid var(--milkyWhite);
}

.switch.on {
  background-color: var(--mainColor);
  /* Color when on */
}

.switch-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: transform 0.3s;
}

.switch-circle.active {
  transform: translateX(19px);
}

/* Settings */
.settings {
  width: 100%;
  height: 100%;
}

.section_settings {
  height: auto;
  width: 100%;
  border-bottom: 2px solid var(--lightGrey);
  margin-bottom: 10px;
}

.section_settings_title {
  font-size: 15px;
  color: var(--mainColor);
  font-weight: bold;
}

.link_app_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.app_link {
  margin-right: 1%;
  height: 100px;
  width: 31%;
  border-radius: 10px;
  border: 2px solid var(--milkyWhite);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  transition: border 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 1%;
}

.app_link:hover {
  border: 2px solid var(--lightGrey);
}

.app_link i {
  width: 10%;
  text-align: center;
  color: var(--mainColor);
  border: 2px solid var(--lightGrey);
  background-color: var(--lightGrey);
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
}

.link_app_desc {
  width: 90%;
  height: 100%;
  padding: 10px;
}

.title_link_app_desc {
  font-size: 15px;
  color: var(--mainColor);
  font-weight: bold;
  margin-bottom: 2px;
}

.desck_link_app {
  font-size: 12px;
  color: gray;
  font-weight: bold;
}

/* Settings  Dropdown*/

.settings_center {
  width: 100%;
}

.settings_link {
  font-size: 12px;
  margin-bottom: 2px;
  height: 30px;
  padding: 5px;
  transition: background-color 0.3s ease-in-out;
}

.settings_link i {
  font-size: 15px;
}

.settings_link:hover {
  background-color: var(--lightGrey);
  cursor: pointer;
  border-radius: 5px;
}

.settings_link_info {
  margin-bottom: 0px;
  font-weight: bold;
  color: var(--mainColor);
  padding-left: 5px;
}

.settings_link_info .spinner_cont {
  font-size: 10px;
  height: 15px;
  width: 15px;
  text-align: center;
}

.settings_link_info .spinner_cont #spinner {
  font-size: 10px;
  height: 15px;
  width: 15px;
  text-align: center;
}


.last_link {
  font-size: 12px;
  border-bottom: 1px solid var(--lightGrey);
  font-weight: bold;
  margin-bottom: 5px;
  height: 30px;
  padding: 5px;
  color: var(--danger);
  transition: background-color 0.3s ease-in-out;
}

.last_link i {
  font-size: 15px;
}

.last_link:hover {
  background-color: #f8d7da;
  cursor: pointer;
  border-radius: 5px;
}


.last_link p {
  margin-bottom: 2px;
}

.logout_from_account {
  font-size: 10px;
  margin-bottom: 2px;
}

/* Dropdown notification */

.no_session_cont {
  width: 100%;
  height: 400px;
  text-align: center;
  color: var(--danger);
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.no_session_mex {
  font-size: 20px;
  margin-bottom: 70px;
}

/* Main view container */
.main_view_container {
  width: 100%;
  bottom: 0;
  top: 0;
  overflow: auto;
}

/* Sidebar toggle */
.toggle_sidebar{
  background-color: white;
  height: 100%;
  margin: auto;
  width: 35px;
  margin-left: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  color: white;
}

.toggle_sidebar:hover{
  opacity: 1;
  color: var(--textColor);
}

.toggle_sidebar i{
  width: 25px;
  height: 25px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 100%;
  border: 1px solid transparent;
  transition: all 0.3s ease-in;
}

.toggle_sidebar i:hover{
  background-color: var(--lightGrey);
  border: 1px solid var(--lightGrey);
}

.toggle_sidebar_closed{
  margin-left: 50px;
  transition: all 0.3s ease-in;

}

/* Sidebar */
.sidebar_cont {
  width: 240px;
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  background-color: white;
  border-right: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: gray;
  border-top-left-radius: 20px;
  transition: all 0.3s ease-in;
  overflow: hidden;
}

.closed_sidebar{
  width: 50px;
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  background-color: white;
  border-right: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: gray;
  border-top-left-radius: 20px;
  overflow: hidden;
}

.sidebar_header {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
}

.sidebar_logo {
  margin-bottom: 0px;
  width: fit-content;
  text-align: start;
  font-size: 20px;
  font-family: var(--logoFont);
  /* border: 1px solid var(--lightGrey); */
  /* background-color: white; */
  height: 100%;
  border-radius: 10px;
  text-align: center;
  padding-left: 10px;
}

.sidebar_logo i {
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Navbar demo */
/* .control_nav_cont span{
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  background-color: white;
  color: var(--mainColor);
  margin-left: 10px;
} */

.sidebar_body {
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: 10px;
  padding-top: 30px;
  overflow-y: auto;
  width: 240px;
  margin: auto;
}

.sidebar_body_main {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar_body_second {
  width: 100%;
  height: 30%;
  background-color: var(--milkyWhite);
  border-radius: 10px;
  padding: 5px;
  border: 1px solid var(--lightGrey);
}

.closed_sidebar_second{
  display: none;
}


.link_container {
  width: 100%;
  height: auto;
}

.link_container_anim {
  width: 100%;
  max-height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.link_container_expanded {
  padding-bottom: 10px;
  max-height: 150px;
  overflow: auto;
}

.tenant_info {
  border: 2px solid lightgray;
  background-color: white;
  border-radius: 10px;
  height: auto;
  padding: 10px;
  text-align: center;
}

.tenant_title {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 5px;
}

.tenant_parag {
  font-size: 12px;
  margin-bottom: 0px;
}

.link_title {
  font-size: 12px;
  color: gray;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.side_link {
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease-in-out;
  height: 35px;
  border: 1px solid transparent;
  margin-bottom: 2px;
}

.side_link:hover {
  color: var(--textColor);
  background-color: var(--lightGrey);
  border-radius: 10px;
}

.side_link i {
  width: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* Sidelink demo building */
.building_span{
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  font-size: 10px;
  padding: 2px 5px;
  margin-left: 10px;
  border-radius: 100px;
}

.blocked{
  pointer-events: none;
}
/* __________________ */

.main_side_link_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 100%;
}

.main_side_link_cont p {
  margin-bottom: 0px;
}

.chevron_side_link {
  height: 100%;
  width: 10%;
  font-size: 12px !important;
}

.side_link_opened {
  font-size: 12px;
  margin: auto;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 175px;
  margin: auto;
  cursor: pointer;
  height: 30px;
  transition: all 0.3s ease-in;
  border: 0px solid transparent;
}

.side_link_opened:hover {
  color: var(--textColor);
  border-right: 1px solid var(--lightGrey);
}

.side_link_bar {
  width: 15px;
  margin-right: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.side_link_bar_top,
.side_link_bar_bottom {
  height: 50%;
  border-left: 2px solid lightgray;
}

.side_link_bar_top {
  border-bottom: 2px solid lightgray;
}

.side_link_opened .side_link_bar .side_link_bar_top {
  border-left: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
}

.side_link_opened .side_link_bar .side_link_bar_bottom {
  border-left: 2px solid lightgray;
}

.link_container_expanded .side_link_opened:last-child .side_link_bar .side_link_bar_top {
  border-left: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  border-bottom-left-radius: 7px;
}

.link_container_expanded .side_link_opened:last-child .side_link_bar .side_link_bar_bottom {
  border-left: none;
}


.side_link_opened p {
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.side_link_opened p i {
  margin-bottom: 0px;
  font-size: 15px;
  margin-right: 5px;
  width: 15px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.soon_available {
  border: 2px solid lightgray;
  background-color: lightgray;
  display: flex;
  cursor: no-drop;
  padding-right: 10px;
}

.small {
  font-size: 12px;
}

.soon_available:hover {
  border: 2px solid lightgray;
  background-color: lightgray;
  color: gray;
}

.soon_available_mex {
  border: 1px solid gray;
  background-color: var(--lightGrey);
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

#coming_soon_tooltip {
  width: fit-content;
}

#coming_soon_tooltip .tooltip-inner {
  border-radius: 5px;
  background-color: var(--lightGrey);
  border: 1px solid lightgray;
  color: var(--textColor);
  font-size: 12px;
  height: 30px;
  padding-top: 3px;
}

#coming_soon_tooltip p {
  margin-bottom: 0px;
  width: 100%;
  text-align: center;

}

.coming_soon_text {
  width: 75%;
}

.sidebar_footer {
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: end;
  padding: 10px;
}

.link_cont_footer_sidebar {
  width: 100%;
  /* border: 1px solid lightgray; */
  height: 100%;
  /* border-radius: 10px; */
  /* background-color: var(--lightGrey); */
  /* padding: 10px; */
}

.profile_cont_link {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.profile_cont_link i {
  margin-right: 5px;
}

.language_select_sidebar {
  border: 1px solid var(--lightGrey);
  background-color: white;
  font-size: 14px;
  border: 1px solid var(--mainColor);
  border-radius: 5px;
  height: 25px;
  color: var(--textColor);
}

.language_select_sidebar:focus {
  outline: none;
}

.side_home_cont {
  border: 1px solid var(--textColor);
  width: 0%;
  /* Default collapsed width */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 20px !important;
  background-color: var(--textColor);
  transition: all 0.3s ease-in-out;
}

/* Expanded width */
.side_home_cont.expanded {
  width: 40%;
  /* Expanded width when menu is open */
}

/* Chevron container */
.chevron_side_home_cont {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.chevron_side_home_cont button {
  border: 1px solid transparent;
  color: gray;
  background-color: transparent;
}

/* Sections style */
.main_view_section {
  height: auto;
  width: 100%;
  padding: 20px;
}

.home_main_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home_main_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.title_main_view_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title_main_view {
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 10px;
}

.subtitle_main_view {
  font-size: 14px;
  color: gray;
  font-weight: bold;
}

.back-btn_chevron {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  color: var(--mainColor);
  cursor: pointer;
}

.home_container {
  height: auto;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.greetings_home_container {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  text-align: center;
}

.dash_date{
  margin-bottom: 0px;
  font-size: 15px;
}

.activity_bar{
  height: 40px;
  margin-bottom: 40px;
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.activity_bar_dash{
  border: 1px solid lightgray;
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
  width: 33%;
  height: 100%;
  border-radius: 100px;
}

.change_dashboard_widget_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  font-size: 15px;
  color: white;
  border-radius: 5px;
}

.double_cont{
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card_dash{
  width: 49%;
  border: 1px solid lightgray;
  height: 100%;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

/* Popup */
#popup_success,
#popup_error {
  color: white;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  right: 50px;
  width: auto;
  padding: 5px;
  height: 40px;
  border-radius: 5px;
  /* opacity: 0.8; */
  animation: fadeIn 1s linear;
}

#popup_error {
  border: 1px solid var(--danger);
  background-color: var(--danger);
}

#popup_success {
  border: 1px solid seagreen;
  background-color: seagreen;
}

.popup_mex {
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.popup_mex i {
  margin-right: 5px;
}

.popup_loading_bar {
  border: 1px solid var(--milkyWhite);
  width: 100%;
  height: 5px;
  background-color: white;
  border-radius: 50px;
  animation: shrinkBar 5s linear forwards;
}

@keyframes shrinkBar {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}

/* Complete registration */
.complete_registration_container {
  border: 1px solid transparent;
  border-left: 5px solid orange;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  padding: 10px;
  opacity: 0.8;
  color: orange;
  margin-bottom: 30px;
  background-color: rgba(255, 166, 0, 0.144);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.complete_reg_title {
  font-size: 15px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 80%;
}

.complete_reg_main_title {
  color: orange;
  font-weight: bold;
  margin-bottom: 0px;
}

.complete_reg_main_desc {
  font-size: 12px;
  margin-left: 15px;
  margin-bottom: 0px;
  color: var(--textColor);
}

.steps_container {
  width: 20%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.steps_count {
  width: 70%;
  margin-bottom: 0px;
  font-size: 12px;
  color: orange;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
}

.complete_configuration_btn {
  border: 1px solid orange;
  background-color: orange;
  color: white;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
  width: 28%;
  margin-right: 5px;
}

.complete_later_configuration_btn {
  border: 1px solid var(--textColor);
  background-color: var(--textColor);
  color: white;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
  width: 25%;
}

.sec_complete_reg_cont {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.step_indicator_main {
  width: 350px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.step_ind_cont {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.step_ind_title {
  font-size: 15px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 100px;
}

.step_ind_title i {
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.step_ind_conf_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 60px;
}

.icon_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
  height: 25px;
  cursor: pointer;
}

.icon_title i {
  width: 40px;
  height: 100%;
  font-size: 25px;
  color: var(--mainColor);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.icon_title p {
  margin-bottom: 0px;
  font-size: 15px;
  color: gray;
}

.connector {
  height: 20px;
  width: 1px;
  border: 1px solid var(--mainColor);
  margin-left: 18px;
}

.main_steps_cont {
  width: 100%;
  margin-left: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-direction: column;
}

.spinner_load_steps_cont {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}

.form_complete_reg {
  /* border: 2px solid var(--lightGrey); */
  /* box-shadow: 2px 2px 100px 2px var(--milkyWhite); */
  height: 695px;
  width: 500px;
  border-radius: 20px;
  padding: 25px;
  margin-left: 200px;
  margin-top: 0px;
}

.form_complete_reg_title {
  font-weight: bold;
  font-size: 25px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form_complete_reg_body {
  height: auto;
  margin-bottom: 70px;
}

.input_label {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.street_num_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#street_val {
  width: 72%;
}

.num_label_cont {
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#num_val {
  margin-left: 10px;
  width: 100%;
}

.input_label label {
  font-size: 15px;
  margin-bottom: 3px;
  color: gray;
}

.input_label input,
.input_label select {
  border: 1px solid var(--lightGrey);
  height: 40px;
  border-radius: 5px;
  font-size: 15px;
  padding-left: 5px;
  color: gray;
  cursor: pointer;
  transition: border 0.2s ease-in-out;
}

.input_label input:focus,
.input_label select:focus {
  border: 3px solid var(--mainColor);
  height: 40px;
  font-size: 15px;
  padding-left: 5px;
  color: gray;
  box-shadow: 2px 2px 5px 2px lightgray;
  outline: none;
}


.steps_navigation {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* Payment setup */
.stripe_complete_reg {
  /* border: 1px solid var(--lightGrey); */
  /* box-shadow: 2px 2px 10px 2px var(--milkyWhite); */
  height: auto;
  width: auto;
  border-radius: 20px;
  padding: 50px;
  margin-left: 200px;
  margin-top: 30px;
}


/* Payments section */
.payments_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.payments_cont_app {
  width: 550px;
  border: 2px solid var(--lightGrey);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
}

.payments_cont_app_new {
  width: 30%;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.payments_app_new {
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  width: 200px;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 30px;
  color: var(--mainColor);
}

.paymets_cont_app_title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20px;
}

.payments_cont_desc {
  font-size: 15px;
  margin-bottom: 10px;
}

.stripe_btn_payments {
  height: 35px;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
}

.revolut_btn_payments {
  height: 35px;
  border: 1px solid var(--textColor);
  background-color: var(--textColor);
  color: white;
  border-radius: 5px;
  font-size: 15px;
  width: 100%;
}

.payments_revolut_logo {
  height: 35px;
  color: var(--textColor);
  border-radius: 5px;
  font-size: 35px;
  width: 100%;
}

.payments_stripe_logo {
  font-size: 60px;
  margin-bottom: 0px;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: start;
  color: var(--mainColor);
}

.desc_payments {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  border: 1px solid lightgray;
  background-color: var(--milkyWhite);
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  /* Updated transition */
  overflow: hidden;
  /* Ensures hidden content doesn't show */
}

.fa-chevron {
  transition: transform 0.3s ease;
  /* For smooth chevron animation */
}

.pay_method {
  font-size: 20px;
  width: 100%;
  margin-right: 10px;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.sepa_logo {
  font-size: 35px;
}

.logo_card {
  margin-bottom: 0px;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  height: 100%;
  font-weight: bold;
}

.logo_card I {
  font-size: 30px;
  height: 100%;
  margin-right: 10px;
}

.sepa_desc {
  font-size: 12px;
  max-height: 0;
  /* Initially set to 0 for hidden */
  opacity: 0;
  /* Hidden initially */
  transition: max-height 0.3s ease, opacity 0.3s ease;
  /* Smooth transition */
}

.sepa_desc p {
  margin-bottom: 5px;
}

.sepa_desc.visible {
  max-height: 170px;
  /* Set a reasonable max height for the expanded state */
  opacity: 1;
}

.open_accounts {
  border: 2px solid var(--lightGrey);
  width: 100%;
  height: auto;
  border-radius: 10px;
  /* background-color: var(--milkyWhite); */
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.open_accounts_title {
  width: 100%;
  font-weight: bold;
  color: var(--mainColor);
}

.open_account_info {
  height: 70px;
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.open_account_logo {
  width: 10%;
  height: 100%;
  font-size: 40px;
  text-align: center;
}

.checkicon {
  width: 10%;
  text-align: center;
  color: seagreen;
}

.account_det_label {
  margin-bottom: 0px;
  width: 40%;
  text-align: start;
  font-size: 15px;
  font-weight: bold;
  color: gray;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.account_det_label label {
  margin-bottom: 5px;
}

.account_det_label p {
  margin-bottom: 0px;
}

.fees_container {
  height: 20px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fee_cont {
  border: 1px solid var(--mainColor);
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 50px;
  font-size: 12px;
  color: white;
  background-color: var(--mainColor);
}

.fee_cont_revolut {
  border: 1px solid var(--textColor);
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 50px;
  font-size: 12px;
  color: white;
  background-color: var(--textColor);
}

.fee_cont p {
  margin-bottom: 0px;
}

/* Registration completer */
.registration_complete_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 50px;
}

.registr_complete {
  border: 2px solid var(--lightGrey);
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  height: auto;
  width: 500px;
  border-radius: 10px;
  padding: 20px;
}

.registr_title {
  font-size: 25px;
  font-weight: bold;
  color: var(--mainColor);
}

.register_parag {
  font-size: 15px;
}

.back_to_home_btn {
  position: relative;
  width: 100%;
  height: 35px;
  background-color: #f3f3f3;
  /* Default background before progress */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  background-repeat: no-repeat;
  transition: background-size 0.1s ease-in-out;
  /* Smooth transition */
}

.back_to_home_btn:hover {
  opacity: 0.9;
}


/* Bookings */
.loading_mex_cont_err,
.loading_mex_cont {
  margin: auto;
  text-align: center;
  height: 450px;
  color: lightgray;
  padding-top: 100px;
}

.loading_mex_cont p {
  margin-bottom: 10px;
  font-size: 15px;
  color: var(--mainColor);
  opacity: 0.5;
}

.loading_mex_cont i {
  font-size: 40px;
  animation: rubberBand 1s linear infinite;
}

.loading_mex_cont_err p {
  color: var(--danger);
  opacity: 0.5;
  font-size: 15px;
}

.loading_mex_cont_err i {
  font-size: 40px;
  animation: jello 1s linear infinite;
}

.bookings_main {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookings_cal_main {
  width: 70%;
  height: 700px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.booking_function_bar {
  height: 40px;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.boocking_func_cont_search,
.boocking_func_cont {
  width: 50%;
  display: flex;
  align-content: center;
  height: 100%;
}

.boocking_func_cont {
  flex-direction: row;
}

.boocking_func_cont_search {
  flex-direction: row-reverse;
}

.add_new_booking_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 5px;
}

.booking_func_btns {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 5px;
}

.booking_func_btns:last-child {
  font-size: 15px;
}

.search_boocking_input {
  display: flex;
  width: 200px;
  border: 2px solid var(--lightGrey);
  border-radius: 5px;
  box-shadow: 2px 2px 10px 2px var(--milkyWhite);
}

.month_booking_cal {
  text-align: start;
  font-size: 15px;
  font-weight: bold;
  color: var(--textColor);
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: end;
}

.booking_calendar {
  max-height: 630px;
  min-height: 630px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid var(--lightGrey);
  overflow: hidden;
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
}

.calendar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: auto;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.calendar-header,
.calendar-rows {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.calendar-hour-row {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.calendar-hour-cell {
  width: 14.2%;
  border: 1px solid whitesmoke;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.calendar-hour-cell:hover {
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
  border: 1px solid lightgray;
  border-radius: 5px;
  overflow: hidden;
}

.calendar-quarter-cell {
  height: 25%;
  color: transparent;
  font-size: 0px;
  background-color: transparent;
  transition: all 0.1s ease-in-out;
  height: 100%;
  width: 100%;
}


.quarter-cell-time {
  height: 25%;
  color: transparent;
  font-size: 0px;
  background-color: transparent;
  transition: all 0.1s ease-in-out;
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
}

.quarter-cell-time:hover {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  color: gray;
  background-color: var(--lightGrey) !important;
  border-radius: 5px;
  border-left: 5px solid var(--azure);
}

.booking-info {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  color: white;
  border: 1px solid transparent;
  border-left: 5px solid var(--azure);
  /* top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px; */
  transition: all 0.2s ease-in-out;
  cursor: grab;
}

.booking-info:hover {
  color: white;
  border: 1px solid var(--azure);
  background-color: var(--azure);
  border-left: 5px solid var(--azure);
}

.booking_title {
  font-size: 10px;
  margin-bottom: 0px;
  width: 50%;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.booking_hour {
  width: 50%;
  margin-bottom: 0px;
}


/* Custom tooltip styles */
.tooltip .tooltip-inner {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 100%;
  background-color: var(--mainColor);
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  opacity: 3;
}

.tooltip {
  width: 300px;
  color: var(--secondColor);
}

.tooltip_title {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 15px;
  height: 10%;
  width: 100%;
  text-align: start;
}

.tooltip_body {
  height: 90%;
  width: 100%;
  text-align: start;
}

.tooltip_body p {
  margin-bottom: 0px;
}

.calendar-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  background-color: var(--milkyWhite);
  color: var(--textColor);
  padding: 10px;
  z-index: 1;
  height: 50px;
}

.navigate_cal_btn {
  border: 1px solid lightgray;
  background-color: lightgray;
  color: white;
  border-radius: 5px;
  height: 100%;
  width: 40px;
  margin-right: 5px;
  font-size: 12px;
}

.calendar-date {
  margin-bottom: 0px;
  width: 14.2%;
  border: 1px solid transparent;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 15px;
  font-weight: bold;
}

.today-date {
  border: 2px solid var(--textColor);
  color: var(--textColor);
  border-radius: 5px;
}

.calendar-rows {
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  z-index: 0;
}

.calendar-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
  overflow: hidden;
  border: 2px solid var(--lightGrey);
  transition: border 0.2s ease-in;
  border-radius: 10px;
  padding: 5px;
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
}

.calendar-row:hover {
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
  border: 2px solid var(--lightGrey);
  cursor: pointer;
}

.contacts_cell {
  border: 1px solid darkorange;
  height: 30px;
  width: auto;
  position: absolute;
  background-color: darkorange;
  border-radius: 7px;
  margin-left: 5px;
  margin-right: 5px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.contacts_cell i {
  margin-right: 5px;
}

.contacts_cell p {
  font-size: 12px;
  margin-bottom: 0px;
}

.calendar-cell {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 14.2%;
  transition: background-color 0.2s ease-in, border-radius 0.2s ease-in-out
}

.no_data_mex_cont {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 100px;
}

.no_data_mex_cont_side {
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 100px;
}

.no_data_mex {
  margin-bottom: 30px;
  color: var(--mainColor);
  font-weight: bold;
  opacity: 0.7;
}

.no_data_img {
  height: 100px;
  width: 100px;
  background-image: url("../public/IMG/empty_box.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  animation: bounce 2.5s infinite linear;
}

.side_bookings_main {
  width: 29%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid var(--lightGrey);
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  height: fit-content;
}

.side_booking_header {
  padding: 10px;
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: var(--milkyWhite);
}

.side_booking_title {
  margin-bottom: 0px;
  color: var(--textColor);
  font-weight: bold;
  font-size: 17px;
  text-align: center;
}

.side_booking_day_date {
  background-color: var(--milkyWhite);
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.day_date {
  font-size: 15px;
  color: var(--textColor);
  font-weight: bold;
}

.side_booking_body {
  padding: 10px;
  min-height: 630px;
  max-height: 630px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* padding-top: 30px; */
}

.hour_group {
  width: 100%;
  border-bottom: 1px solid var(--textColor);
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.hour_label {
  font-size: 15px;
  margin-bottom: 5px;
  color: var(--textColor);
  font-weight: bold;
}

.hour_booking {
  height: 8%;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  background-color: var(--mainColor);
  height: 100%;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.hour_booking:hover {
  box-shadow: 2px 2px 5px 2px var(--lightGrey);
}

.title_hour_booking {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
  width: 70%;
  padding-right: 5px;
  height: 100%;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.hour_hour_booking {
  width: 30%;
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-weight: bold;
}

.add_booking_prompt {
  border: 1px solid var(--azure);
  width: 100%;
  height: 20px;
  font-size: 12px;
  background-color: var(--azure);
  color: white;
  padding-left: 10px;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  width: 95%;
  margin: auto;
  cursor: pointer;
}

.add_booking_prompt_desc {
  margin-bottom: 0px;
}

/* Booking Modale */
.modal_booking .modal-dialog {
  max-width: 500px;
}

.header_modal {
  height: 70px;
  /* border: 1px solid var(--lightGrey); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
}

.modal_title_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  color: var(--mainColor);
  width: 80%;
}

.modal_title {
  border: 1px solid transparent;
  border-bottom: 2px solid var(--mainColor);
  font-size: 20px;
  width: 100%;
}

.modal_title::placeholder {
  color: lightgray;
}

.modal_title:focus {
  outline: none;
}

.modal_title_cont i {
  margin-right: 5px;
}

.close_x_modale {
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--textColor);
  width: 10%;
  text-align: center;
}

.body_modal {
  width: 100%;
  /* border: 1px solid black; */
  height: auto;
  padding: 10px;
}

.body_input_cont_modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
}

.booking_element_title {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
  color: var(--mainColor);
  width: 100%;
  height: auto;
}

.booking_type {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.booking_type_btn {
  font-size: 12px;
  margin-right: 5px;
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  color: var(--textColor);
  border-radius: 5px;
  transition: border 0.3s ease-in-out;
  margin-bottom: 5px;
}

.booking_type_btn:hover {
  color: var(--mainColor);
  border: 2px solid var(--mainColor);
}

.booking_date_cont {
  /* border: 1px solid var(--textColor); */
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.date_hour_cont_booking {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.date_hour_modal_input {
  border: 1px solid transparent;
  background-color: var(--milkyWhite);
  color: var(--textColor);
  border-radius: 5px;
  margin-right: 5px;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s ease-in-out;
}

.date_hour_modal_input:hover {
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
}

.date_hour_modal_input:focus {
  outline: none;
}

.divider_span_modal {
  width: 10px;
  text-align: center;
  padding-right: 5px;
  color: var(--mainColor);
}

.label_checkbox_cont {
  width: fit-content;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.label_checkbox {
  margin-bottom: 0px;
  font-size: 12px;
}

.input_checkbox {
  margin-right: 5px;
  cursor: pointer;
  height: 15px;
  width: 15px;
}

.booking_invited_cont {
  /* border: 1px solid black; */
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.invited_input_btn_cont_modal {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.invited_input_booking_modal {
  border: 1px solid transparent;
  border-bottom: 2px solid var(--mainColor);
  height: 20px;
  font-size: 12px;
  width: 60%;
}

.invited_input_booking_modal:focus {
  outline: none;
}

.invited_input_booking_modal::placeholder {
  color: lightgray;
}

.add_invited_btn {
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  color: var(--textColor);
  font-size: 12px;
  transition: background-color 0.3s ease-in-out;
}

.add_invited_btn:hover {
  background-color: var(--lightGrey);
}

.invited_boocking_users_cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.invited_boocking_users {
  width: fit-content;
  font-size: 12px;
  border: 1px solid var(--milkyWhite);
  height: 25px;
  background-color: var(--milkyWhite);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--lightGrey);
  margin-right: 5px;
  margin-bottom: 5px;
}

.invited_boocking_users p {
  margin-bottom: 0px;
  margin-right: 5px;
  padding: 5px;
}


.invited_boocking_users a {
  margin-bottom: 0px;
  margin-right: 5px;
  padding: 5px;
}

.invited_boocking_users i {
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 20px;
  border: 1px solid gray;
  background-color: gray;
  color: white;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
}

.booking_location_cont {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.position_booking_modal_input {
  border: 1px solid transparent;
  border-bottom: 2px solid var(--mainColor);
  font-size: 12px;
  width: 65%;
}

.position_booking_modal_input::placeholder {
  color: lightgray;
}

.position_booking_modal_input:focus {
  outline: none;
}

.booking_description_cont {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.description_booking_modal_input {
  border: 1px solid transparent;
  border-bottom: 2px solid var(--mainColor);
  font-size: 12px;
  width: 65%;
  max-height: 25px;
}

.description_booking_modal_input::placeholder {
  color: lightgray;
}

.description_booking_modal_input:focus {
  outline: none;
}

.footer_modal {
  /* border: 1px solid var(--lightGrey); */
  height: 60px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.footer_btn_cont {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: fit-content;
}

.close_modal_btn {
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  border-radius: 5px;
  font-size: 12px;
  color: var(--textColor);
  height: 30px;
  margin-right: 5px;
}

.save_modal_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  border-radius: 5px;
  font-size: 12px;
  color: white;
  height: 30px;
  width: max-content;
  transition: all 0.3s ease-in;
}

.delete_record_btn {
  border: 1px solid var(--lightGrey);
  background-color: white;
  border-radius: 5px;
  color: lightgray;
  height: 100%;
  width: 50px;
  font-size: 15px;
  transition: border 0.3s ease-in-out;
}

.delete_record_btn:hover {
  border: 1px solid var(--danger);
  color: var(--danger)
}

/* Modal share prop */
.modal_share_prop .modal-dialog {
  min-width: 700px;
  top: 100px;
  border-radius: 20px;
  overflow: hidden;
}

.share_modal_body{
  height: auto;
  border-radius: 20px;
  padding: 10px;
}

.header_modal_share_prop{
  width: 100%;
  height: 50px;
  padding-left: 10px;
  font-size: 25px;
  color: var(--textColor);
  font-weight: bold;
}

.header_modal_share_prop p{
  margin-bottom: 0px;
}

.main_modal_share_prop{
  width: 100%;
  height: auto;
  border: 1px solid var(--lightGrey);
  box-shadow: 2px 2px 10px 2px var(--milkyWhite);
  border-radius: 20px;
}

.main_modal_share_prop_cont{
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.share_prop_item{
  border: 1px solid lightgray;
  width: 205px;
  margin: 5px;
  height: 50px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.share_prop_item:hover{
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.share_prop_item i{
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  font-size: 25px;
}

.share_prop_item p{
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
  font-size: 14px;
}

.share_prop_item span{
  width: 29%;
  font-size: 10px;
  color: white;
  text-align: center;
  border: 1px solid var(--mainColor);
  margin-right: 5px;
  border-radius: 100px;
  background-color: var(--mainColor);
}

/* Insert new property */
.property_new_add_cont{
  width: 100%;
  width: 290px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  border: 1px solid lightgray;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.property_new_add_cont:hover{
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.mini_img_cont_add{
  border: 1px solid var(--lightGrey);
  height: 250px;
  width: 100%;
  border-radius: 20px;
  background-image: url("../public/IMG/house_indrro.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
}

.property_new_add_cont i{
  position: absolute;
  font-size: 35px;
  color: var(--mainColor);
}


/* Properties */
.property_bar_table_cont {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.property_func_bar {
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.property_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.property_table_container {
  height: 550px;
  width: 74%;
  overflow-x: hidden;
  overflow-y: scroll;
  /* border-top: 2px solid var(--mainColor); */
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  /* padding-right: 10px; */
}

.property_table_container_title {
  padding: 10px;
  color: var(--mainColor);
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.property_table_container_title i {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  font-size: 20px;
}

.property_table_container_title p {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
}

.property_table_container_cont {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.property_filter {
  width: 25%;
  height: 550px;
  border: 2px solid var(--lightGrey);
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  overflow-y: scroll;
  overflow-x: hidden;
}

.prop_filter_title_cont {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 10px;
  border-bottom: 2px solid var(--lightGrey);
}

.prop_filter_title {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  color: gray;
}

.prop_filter_title p {
  margin-bottom: 0px;
  font-weight: bold;
}

.prop_filetr_cont {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.multi_input_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.prop_filter_title_input {
  display: flex;
  flex-direction: column;
  /* align-items: start; */
  width: 100%;
  margin-bottom: 10px;
}


.prop_filter_title_input label {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
  color: gray;
}

.first {
  width: 75%;
}

.second {
  width: 23%;
}

.prop_filter_title_input select {
  font-size: 12px;
  width: 100%;
  border: 2px solid var(--lightGrey);
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.prop_filter_title_input select:focus {
  outline: none;
}

.prop_filter_btn_cont {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid var(--lightGrey);
}

.filter_btn {
  font-size: 12px;
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  border-radius: 5px;
  color: var(--textColor);
  margin-left: 5px;
}

.property_details {
  border: 2px solid var(--lightGrey);
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.property_details:hover {
  /* border: 2px solid gray; */
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.property_img_cont {
  width: 200px;
  border-right: 2px solid var(--lightGrey);
  background-color: var(--lightGrey);
  height: 100%;
}

.property_img {
  /* width: 100%; */
  /* height: 100%; */
  transition: opacity 0.5s ease-in-out;
  object-fit: cover;
}

.property_img_image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.property_img_no_image {
  height: 100%;
  width: 100%;
  background-image: url("../public/IMG/no_img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}


.property_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.body_prop1 {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.body_prop2 {
  width: 100%;
  height: 40%;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.det_prop_cont {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.det_prop {
  width: 19%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  border-radius: 5px;
  margin-right: 5px;
}

.det_desc {
  height: auto;
  margin-bottom: 0px;
  text-align: center;
  display: flex;
  margin-bottom: 0px;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.det_desc i {
  font-size: 15px;
  margin-right: 5px;
  color: var(--mainColor);
}

.small_p {
  font-size: 12px;
  color: var(--mainColor);
}

.prop_title {
  font-size: 15px;
  margin-bottom: 0px;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}

.body_prop_price {
  margin-bottom: 0px;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.body_prop_price_cont {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-around;
  height: 100%;
  padding-top: 15px;
  padding-right: 20px;
  width: 100%;
}

.next_res {
  font-size: 12px;
  width: 100%;
}

.next_res_conts {
  border: 2px solid var(--lightGrey);
  height: 100%;
  width: 100%;
  margin-right: 5px;
  border-radius: 5px;
}

.available_prop {
  font-size: 15px;
  margin-bottom: 0px;
}

.available {
  color: seagreen;
}

.non_available {
  color: darkorange;
}

.prop_price {
  font-weight: bold;
  font-size: 25px;
}

.prop_price span {
  font-size: 15px;
  color: var(--mainColor);
}

.title_prop_span {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.title_prop_span i {
  color: var(--danger);
  font-size: 15px;
  margin-right: 5px;
}

.title {
  color: var(--mainColor);
  font-weight: bold;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.title p {
  width: 100%;
  width: 170px;
  margin-bottom: 0px;
  padding-left: 5px;
}

.property_title {
  width: 100%;
  height: 60%;
  align-items: center;
  padding-top: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.property_unit_type {
  height: 40%;
  font-size: 12px;
  padding-bottom: 15px;
  color: var(--textColor);
}


.location {
  word-break: 50%;
  font-size: 12px;
}

.location p {
  margin-bottom: 0px;
  width: 100%;
}

.save_property_btn {
  color: white;
  background-color: var(--mainColor);
  border: 1px solid white;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 110px;
}

.save_property_btn #spinner {
  margin-right: 5px;
  color: white;
  height: 12px;
  width: 12px;
  font-size: 10px;
}

.add_property_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  border-radius: 5px;
  font-size: 12px;
  height: 30px;
  margin-right: 5px;
}

.filter_input_cont {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid var(--mainColor);
  padding: 3px;
  border-radius: 5px;
}

.filter_input_cont i {
  color: var(--mainColor);
  font-size: 15px;
}

.filter_input_cont input {
  border: 1px solid transparent;
  font-size: 12px;
  width: 250px;
}

.filter_input_cont input::placeholder {
  color: lightgray;
}

.filter_input_cont input:focus {
  outline: none;
}

.btn_prop_container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
}

.btn_prop_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 10px;
  cursor: pointer;
}

.btn_prop_update {
  border: 1px solid transparent;
  color: var(--mainColor);
  background-color: transparent;
  border-radius: 5px;
  font-size: 20px;
  height: 25px;
  margin-right: 5px;
}

.btn_prop_delete {
  border: 1px solid var(--danger);
  color: white;
  background-color: var(--danger);
  border-radius: 5px;
  font-size: 12px;
  height: 25px;
  margin-right: 5px;
}

/* Property modal */
.modal_property .modal-dialog {
  max-width: 650px;
}

.property_input_cont {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-bottom: 2px solid var(--lightGrey);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.modal_title_cont_prop {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  color: var(--mainColor);
  width: 75%;
}

.modal_title_cont_prop i {
  margin-right: 5px;
}

.modal_title_cont_prop p {
  margin-bottom: 0px;
}

.property_input_section_title {
  font-size: 12px;
  font-weight: bold;
  color: var(--mainColor);
  margin-bottom: 0px;
}

.details_split_cont {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label_input_cont_property {
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-wrap: wrap;
  height: 100%;
  width: 46%;
}

.label_input_cont_property_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.street_number_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  flex-wrap: wrap;
}

.property_input {
  width: 100%;
  height: auto;
  border: 1px solid transparent;
  font-size: 12px;
  border: 2px solid var(--lightGrey);
  border-radius: 5px;
  height: 25px;
}

.prop_street {
  width: 80%;
}

.prop_number {
  width: 17%;
}

.property_input::placeholder {
  color: lightgray;
}

.property_input:focus {
  outline: none;
}

.select_choise_prop_btn {
  border: 2px solid var(--lightGrey);
  font-size: 12px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: var(--milkyWhite);
  transition: border 0.3s ease-in-out;
}

.select_choise_prop_btn:hover {
  border: 2px solid var(--mainColor);
  color: var(--mainColor);
}

#file_input_property_img {
  display: none;
}

.upload_img_btn {
  border: 1px solid lightgray;
  background-color: var(--lightGrey);
  width: 100px;
  height: 40px;
  font-size: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Properties list */
.property_content {
  width: 100%;
  height: auto;
  padding-bottom: 100px;
}

.properties_card_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.property_card {
  width: 290px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
}

.header_img_cont {
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
}

.footer_img_cont {
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  bottom: 80px;
}

.property_btn_action {
  border: 1px solid var(--milkyWhite);
  width: auto;
  border-radius: 100px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background-color: var(--milkyWhite);
  color: var(--textColor);
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.property_btn_action p {
  margin-bottom: 0px;
}

.property_btn_action:hover {
  background-color: white;
  opacity: 1;
}

.property_img {
  height: 270px;
  border: 1px solid lightgray;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.property_img_blotter {
  height: 270px;
  border: 1px solid lightgray;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-image: url("../public/IMG/front_house.avif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.property_img:hover {
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.property_img_scroll_cont {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
  opacity: 1;
  transition: opacity 0.3s ease;
  font-size: 15px;
  position: absolute;
  top: 130px;
}

.property_btn_action_scroll {
  color: white;
  padding: 5px 10px 5px 10px;
}

.property_btn_action_scroll:hover {
  background-color: white;
  color: var(--textColor);
  border-radius: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.property_img:hover .property_img_scroll_cont {
  opacity: 0.8;
}

.property_btn_action_toggle .custom-dropdown-toggle {
  /* background-color: white; */
  color: white;
  border: none;
  cursor: pointer;
  padding: 2px 10px 2px 10px;
  border-radius: 100px;
  transition: all 0.3s ease-in;
  font-size: 20px;
}

.property_btn_action_toggle .custom-dropdown-toggle::after {
  display: none;
}

.property_btn_action_toggle .custom-dropdown-toggle:hover {
  background-color: white;
  color: var(--textColor);
}

.property_description_cont {
  height: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.property_description_cont p {
  margin-bottom: 0px;
}

.property_title_card {
  font-size: 14px;
  font-weight: bold;
}

.property_location_card {
  font-size: 12px;
}

.property_amount_card {
  font-size: 14px;
  font-weight: bold;
}

/* Modal save changes property */
.save_data_prop_mex{
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  font-size: 20px;
  color: var(--mainColor);
  font-weight: bold;
}

.footer_btn_cont_save_prop_data{
  width: fit-content;
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  justify-content: space-between;
  margin: auto;
}


/* New Property */
.property_fun_bar_new {
  height: fit-content;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.new_property_cont {
  width: 90%;
  margin: auto;
  height: auto;
  padding-top: 20px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.main_property_cont {
  width: 70%;
  height: 100%;
}

.imag_prop_cont {
  width: 100%;
  border: 1px solid lightgray;
  min-height: 200px;
  border-radius: 10px;
  padding: 20px;
}

.imag_prop_cont.error{
  border: 1px solid var(--danger);
}

.err_image{
  font-size: 12px;
  color: var(--danger);
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.imag_prop_cont_inner {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.imag_prop_cont_inner p {
  margin-bottom: 0px;
}

.imag_prop_cont_inner i {
  font-size: 40px;
  color: var(--azure);
}

.imag_prop_cont_inner_mex {
  font-size: 14px;
  color: gray;
}

.imag_prop_cont_inner_mex span {
  cursor: pointer;
}

#file_img_prop_input {
  display: none;
}

.image_preview_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  min-height: 300px;
  overflow-y: auto;
}

.image_preview {
  border: 1px solid lightgray;
  width: 30%;
  height: 250px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  position: relative;
  transition: all 0.3s ease-in;
  cursor: grab;
  transition: transform 0.2s ease, opacity 0.2s ease;
  margin: 5px;
}

.image_preview.dragging {
  cursor: grabbing;
  opacity: 0.5;
  transform: scale(1.05);
}


.image_preview:hover {
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.image_preview img {
  object-fit: cover;
  width: 100%;
}

.delete_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--milkyWhite);
  border-radius: 10px;
  color: var(--textColor);
  padding: 5px;
  cursor: pointer;
  font-size: 10px;
  transition: all 0.3s ease-in;
  opacity: 0.5;
}

.image_preview:hover .delete_icon {
  opacity: 1;
}

.delete_icon_spinner{
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--milkyWhite);
  border-radius: 10px;
  color: var(--textColor);
  padding: 5px;
  cursor: pointer;
  font-size: 10px;
  transition: all 0.3s ease-in;
}

.imag_prop_cont_inner_mex_add {
  cursor: pointer;
  height: 250px;
  width: 23%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 30px;
}

.secondary_property_cont {
  width: 29%;
  height: fit-content;
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 20px;
  background-color: var(--milkyWhite);
  font-size: 14px;
}

.specific_request_cont {
  border: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-radius: 20px;
  background-color: white;
  margin-bottom: 10px;
}

.btn_specific_req {
  flex-direction: row;
}

.room_specific_req,
.cal_specific_req {
  flex-direction: column;
}

.specific_request_label {
  font-weight: bold;
  margin-bottom: 0px;
}

.market_type_prop {
  height: 30px;
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  font-size: 12px;
  width: 49%;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.market_type_prop:hover {
  border: 1px solid var(--azure);
}

.market_type_prop_selected {
  border: 1px solid var(--azure);
  background-color: var(--azure);
  color: white;
}

.market_type_prop p {
  margin-bottom: 0px;
}

.unit_types_cont {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.unit_type_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.unit_title {
  font-size: 0.9rem;
  margin-top: 5px;
}

.specific_request_label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

/* Calendar property */
.calendar {
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  background-color: white;
  border: 1px solid var(--lightGrey);
}

.calendar.err {
  border: 1px solid var(--danger);
}

.calendar_header_prop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  height: 50px;
  border: 1px solid var(--lightGrey);
  padding: 10px;
  border-radius: 10px;
}

.calendar_header_prop span {
  font-size: 1rem;
}

.calendar_header_prop .fa-chevron-left,
.calendar_header_prop .fa-chevron-right {
  cursor: pointer;
  color: var(--azure);
  font-size: 14px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  margin-top: 10px;
  border: 1px solid var(--lightGrey);
  padding: 5px;
  border-radius: 10px;
}

.calendar-day.highlighted {
  background-color: var(--azure);
  color: white;
}


.calendar-day {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: white;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
  color: var(--textColor);
  transition: all 0.3s ease-in;
}

.calendar-day:hover {
  background-color: var(--azure);
  color: white;
}

.dates_cont_prop {
  width: 100%;
  border: 1px solid var(--lightGrey);
  background-color: white;
  height: 30px;
  margin-bottom: 5px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.available_now {
  width: 49%;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--lightGrey);

}

.available_now label {
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 5px;
  width: 90%;
}

.radio_prop {
  width: 10%;
  background-color: var(--azure);
  cursor: pointer;
}

.available_dates_prop {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  padding: 5px;
  font-size: 12px;
  color: gray;
  border: 1px solid var(--lightGrey);
}

.available_dates_prop.err {
  border: 1px solid var(--danger);
}


.section_property_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
}

.section_property_input_cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 10px;
  overflow: hidden;
}

.section_property_input_cont.error {
  border: 1px solid var(--danger);
  background-color: #ffe6e6;
  /* Light red background */
}

.section_property_input_cont.error i {
  color: var(--danger);
}


.label_property {
  width: 250px;
  margin-bottom: 0px;
  font-size: 14px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  background-color: var(--milkyWhite);
  border-right: 1px solid lightgray;
  font-weight: bold;
  padding: 0 10px;
}

.label_property i {
  font-size: 12px;
  color: lightgray;
}

.unchecked_mark {
  color: var(--lightGrey);
  width: 5%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.checked_mark {
  color: var(--azure);
  opacity: 0.8;
  width: 5%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.value_property {
  width: 95%;
  border: 1px solid transparent;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in;
  font-size: 15px;
  padding-left: 10px;
  color: gray;
}

.unit_types_cont {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.unit_type_block {
  height: 80px;
  width: 150px;
  border: 1px solid lightgray;
  background-color: var(--milkyWhite);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.add_new_unit_type {
  border: 1px solid transparent;
  background-color: white;
}

.unit_type_block:hover {
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.unit_type_block i {
  height: 50%;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  justify-content: space-around;
}

.unit_title {
  height: 50%;
  font-size: 14px;
  font-weight: bold;
}

.value_property_street {
  width: 55%;
  border: 1px solid transparent;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in;
  font-size: 15px;
  padding-left: 10px;
}

.value_property_num {
  width: 40%;
  border: 1px solid transparent;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in;
  font-size: 15px;
  padding-left: 10px;
  border-left: 1px solid lightgray;
}

.value_property_street:focus,
.value_property_num:focus,
.value_property:focus {
  outline: none;
  border-bottom: 2px solid var(--mainColor);
}

.value_property_street::placeholder,
.value_property_num::placeholder,
.value_property::placeholder {
  color: lightgray;
}

/* Room manager proeprty*/
.single_cont_title {
  color: gray;
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
}

.single_cont {
  border: 1px solid var(--lightGrey);
  border-radius: 20px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 10px;
}

.single_cont.error {
  border: 1px solid var(--danger);
}

.single_cont i {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  border-radius: 20px;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.single_cont i:hover {
  border: 1px solid lightgray;
  box-shadow: 2px 2px 10px 2px var(--milkyWhite);
}

.single_cont_input {
  width: 100%;
  border-radius: 20px;
  border: 1px solid transparent;
  font-size: 14px;
  padding-left: 10px;
}

.single_cont_input:focus {
  outline: none;
}

.number_unit_room {
  width: 60%;
  text-align: center;
  margin-bottom: 0px;
  font-size: 20px;
}

/* comfort property */
.comfort_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.comfort_option {
  padding: 2px 10px;
  margin-bottom: 5px;
  border-radius: 100px;
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  margin-right: 5px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.comfort_option:hover {
  border: 1px solid lightgray;
  box-shadow: 2px 2px 5px 2px var(--lightGrey);
}

/* Text editor */
.text_editor_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  overflow: hidden;
}

.text_editor_cont.err {
  border: 1px solid var(--danger);
}

.text_editor_header {
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--milkyWhite);
  padding: 5px 10px 5px 10px;
}

.header_btn_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.ai_assistant_editor_cont {
  width: 10%;
  height: 100%;
  text-align: center;
  border-right: 1px solid lightgray;
  padding-right: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.font_cont {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.words_length {
  margin-bottom: 0px;
  font-size: 12px;
  color: gray;
  margin-right: 5px;
}

.custom-dropdown-menu-ai-response {
  border: 1px solid var(--lightGrey);
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
  width: 350px;
  padding: 5px;
  border-radius: 10px;
  z-index: 20;
}

.ai_response {
  border: 1px solid var(--lightGrey);
  min-height: 50px;
  max-height: 100px;
  border-radius: 10px;
  margin-bottom: 5px;
  overflow: auto;
}

.ai_response_text {
  font-size: 12px;
  padding: 10px;
}

.use_ai_resp {
  font-size: 12px;
  color: var(--mainColor);
  font-weight: bold;
  margin-bottom: 0px;
  width: 100%;
  text-align: start;
  padding: 2px;
  margin-top: 10px;
}

.ai_loading {
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: flash 2s infinite;
}

.ai_preview {
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.5;
}

.ai_input_cont {
  width: 100%;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.ai_input {
  width: 80%;
  border: 1px solid transparent;
  font-size: 12px;
}

.ai_input::placeholder {
  color: lightgray;
}

.ai_input:focus {
  outline: none;
}

.send_ai {
  border: 1px solid var(--mainColor);
  border-radius: 100px;
  height: 100%;
  width: 20%;
  font-size: 12px;
  background-color: var(--mainColor);
  color: white;
}

.font_type_btn {
  border: 1px solid transparent;
  width: auto;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid lightgray;
  background-color: white;
  padding: 0px 10px;
  border-radius: 5px;
}

.font_type_btn select {
  margin-left: 10px;
  border: 1px solid white;
  background-color: white;
  cursor: pointer;
}

.font_type_btn select:focus {
  outline: none;
}

.text_editor {
  min-height: 200px;
  max-height: 400px;
  overflow: auto;
  width: 100%;
  padding: 10px;
  font-size: 14px;
}

.text_editor:focus {
  outline: none;
}


/* Tenants payment */
.tenants_payment {
  border: 2px solid var(--lightGrey);
  width: 100%;
  height: 500px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.tenants_payment_title {
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 0px;
  text-align: center;
  font-weight: bold;
  color: orange;
}

.tenants_payment_mex {
  font-size: 15px;
  margin-bottom: 0px;
}


/* My tenants */
.tenants_view {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tenants_graphs {
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  background-color: var(--milkyWhite);
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 5px;
}

.charts_cont {
  height: 100%;
  width: 33%;
  border: 2px solid white;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.charts_title {
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-top: 5px;
  font-weight: bold;
}

.charts_title i {
  font-size: 15px;
}

.stack_chart {
  width: 100%;
  height: auto;
}

.tenants_filter_cont {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 45px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid var(--lightGrey);
}

.ai_btn {
  border: 1px solid var(--mainColor);
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
  color: var(--mainColor);
  background-color: white;
  background-clip: border-box;
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 5px 10px;
}

.personalize_table_btn {
  margin-right: 5px;
  border: 1px solid lightgray;
  height: 30px;
  width: auto;
  font-size: 12px;
  border-radius: 5px;
}

/* Alert tenant */
.alert_message {
  border: 1px solid transparent;
  border-left: 5px solid orange;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  padding: 10px;
  opacity: 0.8;
  color: orange;
  margin-bottom: 5px;
  background-color: rgba(255, 166, 0, 0.144);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* Positioning context for child elements */
  opacity: 0;
  /* Start hidden */
  transform: translateY(-20px);
  /* Start slightly above */
  transition: opacity 0.5s ease, transform 0.5s ease;
  /* Transition for opacity and transform */
}

.alert_message.show {
  opacity: 1;
  /* Fade in */
  transform: translateY(0);
  /* Slide down into position */
}


.alert_text {
  margin-bottom: 0px;
  font-size: 15px;
}

.alert_text i {
  margin-right: 5px;
}

.alert_btn_cont {
  width: auto;
  display: flex;
  height: 100%;
}

.alert_btn_cont button {
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--textColor);
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

/* Personalize modale */
.modal_table_personalize.modal-dialog {
  max-width: 600px;
}

.body_modal_table_personalize {
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sidebar_modal {
  width: 400px;
  height: 100%;
  background-color: var(--milkyWhite);
  border-right: 2px solid var(--lightGrey);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
}

.body_modal {
  width: 100%;
  height: 100%;
}

.sidebar_modal_title {
  font-size: 15px;
  font-weight: bold;
  color: var(--mainColor);
  text-align: start;
  width: 100%;
}

.sidebar_modal_link {
  width: 100%;
  font-size: 12px;
  margin-bottom: 5px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  padding-left: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  border: 1px solid transparent;
  border-left: 2px solid var(--mainColor);
  color: gray;
}

.sidebar_modal_link:hover {
  color: var(--textColor);
  border: 1px solid lightgray;
  border-left: 2px solid var(--mainColor);
  border-radius: 5px;
}


/* Tenants */
.tenants_table_cont {
  max-height: 800px;
  width: 100%;
  border: 2px solid var(--lightGrey);
  padding: 5px;
  border-radius: 10px;
  overflow: auto;
}

.table_tenants {
  width: 100%;
  height: auto;
}

.table_tenants tr {
  height: 40px;
  transition: all 0.3s ease-in;
}

.table_tenants tr:hover {
  background-color: var(--milkyWhite);
}

.home_table_tenant {
  border: none;
}

.home_table_tenant .header_cell,
.home_table_tenant .body_cell {
  width: auto;
}


.home_table_tenant .tenants_table_header {
  padding-left: 10px;
}

.tenants_table_header {
  font-size: 15px;
  color: gray;
  height: 40px;
}

.contacts_header {
  height: 100%;
  padding: 10px;
}

.tenant_filters {
  border: 2px solid var(--lightGrey);
  height: 40px;
  border-radius: 5px;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.tenant_filters button {
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  margin-right: 5px;
  border-radius: 5px;
  color: var(--textColor);
}

.nav_btns_tenant {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}

.nav_btn_tenant {
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  font-size: 12px;
  border-radius: 5px;
  height: 100%;
  width: 100px;
  transition: all 0.3s ease-in-out;
}

.nav_btn_tenant:hover {
  background-color: var(--mainColor);
  color: white;
  border: 2px solid var(--mainColor);
}

.property_select_tenant_filter {
  border: 2px solid var(--lightGrey);
  width: 100px;
  font-size: 12px;
  border-radius: 5px;
}

.new_tenant_title_chevron_cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: unset;
  justify-content: space-between;
  padding-right: 40px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
}


.new_tenant_title_chevron_cont_open,
.new_tenant_title_chevron_cont:hover {
  border: 1px solid var(--lightGrey);
}

.new_tenant_check_chevron_cont {
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.circle_tenant {
  color: gray;
}

.check_tenant {
  color: seagreen;
}

.new_tenant_title_desc_cont {
  width: auto;
}

.new_tenant_title {
  font-size: 15px;
  font-weight: bold;
  color: var(--mainColor);
  margin-bottom: 5px;
}

.description_tenant_title {
  font-size: 12px;
  color: gray;
  margin-bottom: 10px;
}

.tenant_type_btn_cont {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  height: 35px;
}

.tenant_type_btn {
  margin-right: 5px;
  border: 2px solid var(--lightGrey);
  font-size: 12px;
  background-color: var(--milkyWhite);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.tenant_type_btn_contacts {
  font-size: 12px;
  border: 2px solid transparent;
  background-color: transparent;
  color: var(--mainColor);
  transition: all 0.3s ease-in-out;
}

.tenant_type_btn_contacts:hover {
  border-bottom: 2px solid var(--mainColor);
}

.tenant_type_btn_contacts.active {
  border-bottom: 2px solid var(--mainColor);
}

.error_border {
  border-bottom: 2px solid var(--danger) !important;
}


.tenant_type_btn:hover {
  border: 2px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
}

.tenant_type_btn.active {
  border: 2px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
}

.new_tenant_form {
  height: auto;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
}

.tenant_label_input_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 2px solid var(--lightGrey);
  padding-top: 10px;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 10px;
}

.label_input_cont_done {
  border: 2px solid seagreen;
}

.label_input_cont_done .new_tenant_title {
  color: seagreen !important;
}

.save_tenant_info_btn_container {
  width: 100%;
  text-align: end;
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  padding-right: 10px;
  padding-bottom: 10px;
}

.save_tenant_info_btn {
  color: white;
  background-color: var(--mainColor);
  border: 1px solid white;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px;
  transition: all 0.3s ease-in-out;
  display: flex;
  height: 30px;
}

.save_tenant_info_btn span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.save_tenant_info_btn:hover {
  border: 1px solid var(--mainColor);
}

.save_tenant_info_btn #spinner {
  margin-right: 5px;
  color: white;
  height: 15px;
  width: 15px;
  font-size: 10px;
}

.toggle_tenant_info_cont {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  opacity: 0.7;
}

.active_tenant_info {
  max-height: 1000px;
  opacity: 1;
}

.tenant_info_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: start;
  margin-bottom: 50px;
}

.tenant_info_cont {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.tenant_lab_in_cont {
  display: flex;
  flex-direction: row;
  align-items: start;
  height: auto;
  margin-bottom: 25px;
  margin-right: 20px;
  height: 35px;
}

.tenant_lab_in_cont.bordered {
  border: 1px solid var(--lightGrey);
  width: fit-content;
  height: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.rqeustes_fields {
  margin-left: 10px;
  font-size: 10px;
}

.tenant_info_single_cont {
  width: 50%;
  height: auto;
}

.tel_select_prefix {
  border: 2px solid transparent;
  border-right: 2px solid var(--mainColor);
  border-bottom: 2px solid lightgray;
  height: 100%;
  margin-bottom: 0px;
  width: 60px;
  font-size: 12px;
  color: var(--mainColor);
}

.tenant_input_phone:focus,
.tel_select_prefix:focus {
  outline: none;
}

.tenant_input_phone {
  width: 140px;
  border: 2px solid transparent;
  border-bottom: 2px solid lightgray;
  font-size: 12px;
  height: 100%;
}

.tenant_info_cont_find_contacts {
  min-width: 50%;
  max-width: fit-content;
  height: fit-content;
}

.tenant_info_cont_data_find_contacts {
  margin-top: 30px;
  margin-left: 10px;
}

.tenant_label {
  font-size: 12px;
  margin-bottom: 0px;
  margin-right: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
  font-weight: bold;
  width: 120px;
}

.garant_label {
  margin-top: 10px;
}

.tenant_input {
  border: 1px solid transparent;
  border-bottom: 2px solid lightgray;
  height: 100%;
  font-size: 12px;
  height: 100%;
  color: var(--textColor);
  padding: 0px;
  width: 200px;
  transition: all 0.3s ease-in-out;
}

.tenant_input:focus {
  outline: none;
  border-bottom: 2px solid var(--mainColor);
}

.property_choice_cont {
  width: 100%;
  height: 780px;
  /* margin-bottom: 50px; */
  /* border: 2px solid var(--lightGrey); */
  /* border-radius: 10px; */
  display: flex;
  flex-direction: row;
  align-items: start;
  /* padding: 10px; */
  padding-bottom: 5px;
  justify-content: space-between;
  overflow-y: auto;
  margin-bottom: 10px;
}

.payment_title {
  font-size: 12px;
  margin-bottom: 0px;
  padding-left: 5px;
  padding-top: 5px;
  font-weight: bold;
  color: var(--mainColor);
  position: sticky;
  top: 0;
  height: auto;
  width: 100%;
  background-color: white;
}

.payment_description {
  font-size: 10px;
  color: gray;
  margin-left: 5px;
}

.property_container {
  width: 64%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tenant_property_cont {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.tenant_add_property_btn {
  border: 1px solid lightgray;
  background-color: var(--lightGrey);
  color: var(--textColor);
  border-radius: 5px;
  font-size: 12px;
  height: 30px;
  width: fit-content;
  transition: all 0.3s ease-in-out;
}

.tenant_add_property_btn:hover {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
}

.property_choice {
  height: 100%;
}

.tenant_go_property_btn {
  border: 2px solid lightgray;
  background-color: var(--milkyWhite);
  font-size: 12px;
  border-radius: 5px;
  color: gray;
}

.tenant_go_property_btn i {
  font-size: 15px;
}

.property_item {
  border: 1px solid lightgray;
  height: auto;
  background-color: var(--milkyWhite);
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  padding: 5px;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.property_item p {
  margin-bottom: 0px;
  margin-right: 10px;
}

.checkBox_cont {
  width: 2%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title_prop {
  font-weight: bold;
  width: 8%;
}

.checkBox_cont input {
  cursor: pointer;
}

.checkBox_cont input:focus {
  outline: none;
}

.tenant_prop_det {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: var(--lightGrey);
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  margin-right: 5px;
  width: 30%;
}

.prop_expenses_cont {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: 5px;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.prop_expenses_cont:hover {
  border: 1px solid lightgray;
  cursor: pointer;
}

.title_prop_expenses {
  color: gray;
  margin-bottom: 2px;
  font-size: 10px;
}

.prop_ammount_expenses {
  font-weight: bold;
}

.rent_ammount_choise_cont {
  width: 35%;
}

.rent_amm_btns_cont {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
  width: fit-content;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid var(--lightGrey);
}

.rent_amm_btns {
  margin-left: 0px;
  font-size: 12px;
  border: 1px solid transparent;
  background-color: var(--milkyWhite);
  color: rgb(172, 172, 172);
  transition: all 0.3s ease-in;
}

.active_payment {
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  color: var(--textColor);
}

.rent_amm_btns i {
  font-size: 15px;
}

.rent_ammount_choise {
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  width: 100%;
  position: sticky;
  top: 0;
  right: 0;
  overflow: hidden;
}

.period_payment {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  margin-bottom: 5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: gray;
  transition: all 0.3s ease-in-out;
  margin-left: 5px;
  margin-right: 5px;
}

.period_payment:hover {
  color: var(--textColor);
  border: 1px solid gray;
}

.period_payment_label {
  font-size: 12px;
  width: 35%;
  height: 100%;
  margin-bottom: 0px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.period_payment_input {
  height: 100%;
  border: 1px solid transparent;
  border-bottom: 2px solid lightgray;
  background-color: var(--milkyWhite);
  height: 20px;
  font-size: 12px;
  width: 55%;
  color: gray;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.period_payment_input:focus {
  outline: none;
  color: var(--mainColor);
  border-bottom: 2px solid var(--mainColor);
}

.currency_period_payment {
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10%;
  margin-bottom: 0px;
}

.total_value {
  border: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  border-radius: 5px;
  padding: 2px;
  padding-left: 10px;
  height: 25px;
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: bold;
  color: gray;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.payment_meth_month {
  border: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  height: auto;
}

.payment_meth_month p {
  font-size: 12px;
  font-weight: bold;
  color: gray;
  margin-bottom: 0px;
  margin-left: 5px;
}

.find_contact_tenant_cont {
  border: 1px solid var(--lightGrey);
  height: auto;
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 50%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.input_icon_cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.input_icon_cont input {
  font-size: 12px;
  border: 1px solid transparent;
  border-bottom: 2px solid var(--mainColor);
  color: gray;
  padding-left: 5px;
  width: 95%;
  height: 30px;
}

.input_icon_cont i {
  font-size: 12px;
  color: var(--mainColor);
  width: 5%;
}

.find_contact_tenant_cont select {
  border: 1px solid transparent;
  font-size: 15px;
  color: var(--mainColor);
  font-weight: bold;
  padding-left: 20px;
}

.find_contact_tenant_cont input:focus,
.find_contact_tenant_cont select:focus {
  outline: none;
}

/* Personalized Payments */
.det_payment_personalized_cont {
  margin-bottom: 10px;
  height: auto;
}

.label_fraction_payment {
  font-size: 12px;
  margin-bottom: 0px;
  display: block;
  margin-left: 5px;
  color: gray;
}

.fraction_payment {
  height: 30px;
  border: 2px solid var(--lightGrey);
  margin-bottom: 5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: gray;
  transition: all 0.3s ease-in-out;
  margin-left: 5px;
  margin-right: 5px;
  width: auto;
  font-size: 12px;
}

.fraction_payment:focus {
  outline: none;
}

.from_to_container {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  color: gray;
  margin-bottom: 10px;
  border: 2px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
}

.from_to_payment {
  width: 49%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.from_to_payment label {
  margin-bottom: 0px;
  font-weight: bold;
}

.from_to_payment input {
  color: gray;
  border: 2px solid var(--lightGrey);
  border-radius: 5px;
  padding-left: 5px;
  height: 30px;
  width: 100%;
}

.mini_calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: auto;
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
}

.calendar_day {
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 2px;
}

.calendar_day:hover {
  border: 2px solid var(--azure);
  border-radius: 10px;
}

.calendar_day.selected {
  background-color: var(--azure);
  color: white;
}

.calendar_day.selected:hover {
  border-radius: 0px;
}

.calendar_day.start-date,
.calendar_day.start-of-week {
  background-color: var(--azure);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;

}

.calendar_day.end-date,
.calendar_day.end-of-week {
  background-color: var(--azure);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
}

.calendar_day.daily-radius {
  border-radius: 10px;
}

.calendar_weekday {
  font-size: 12px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  border-bottom: 2px solid var(--lightGrey);
  color: gray;
}

.month-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  color: gray;
}

.month-navigation button {
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  width: 35px;
  border-radius: 5px;
}

.checkbox_label_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  width: 30%;
}

.checkbox_label {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox_label label {
  margin-bottom: 0px;
  margin-right: 2px;
}

.checkbox_label input {
  cursor: pointer;
}

/* Documents */
.documents_tenant_cont {
  height: auto;
}

.doc_type_compile_bar_choise {
  width: 100%;
  border: 2px solid var(--lightGrey);
  height: 40px;
  border-radius: 10px;
  margin-bottom: 15px;
  padding-left: 5px;
}

.doc_type_compile_bar_btn {
  border: 1px solid var(--lightGrey);
  background-color: white;
  font-size: 12px;
  margin-right: 5px;
  border-radius: 5px;
  height: 80%;
  transition: all 0.3s ease-in;
  color: gray;
}

.doc_type_compile_bar_btn.template {
  pointer-events: none;
  opacity: 0.5;
  font-weight: bold;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.selected_option {
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  color: var(--mainColor);
}

.choise_documents {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.document_split {
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  height: 600px;
  transition: width 0.3s ease;
  /* Smooth transition */
  overflow: hidden;
  /* Hide overflow */
}

.first_split {
  overflow: auto;
  filter: blur(7px);
  pointer-events: none;
}

.contract_prebuild_header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
}

.contract_type {
  border: 1px solid var(--lightGrey);
  border-radius: 5px;
  font-size: 12px;
  height: 30px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.contract_type:focus {
  outline: none;
  border: 1px solid var(--mainColor);
  transition: all 0.3s ease-in;
}

.upload_file_link {
  border-bottom: 1px solid black;
  color: var(--mainColor);
  cursor: pointer;
}

.document_split_second_inner {
  /* border: 2px dotted var(--lightGrey); */
  border-radius: 20px;
  width: 80%;
  height: 100%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.upload_icon {
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  opacity: 0.8;
  margin-bottom: 15px;
}

.document_split_second_title {
  font-size: 15px;
  color: var(--textColor);
  font-weight: bold;
  color: gray;
  margin-bottom: 5px;
}

.document_split_second_subtitle {
  font-size: 10px;
  color: gray;
}

.personal_docs_upload_title {
  font-size: 12px;
  margin-bottom: 5px;
  width: 100%;
  text-align: start;
  color: gray;
}

.personal_docs_upload {
  overflow: auto;
  width: 100%;
  height: 400px;
  font-size: 12px;
  padding: 5px;
  border: 1px solid var(--lightGrey);
  border-radius: 10px;
  transition: all 0.3s ease-in;
}

.personal_docs_upload:hover {
  border: 1px solid var(--mainColor);
}

.personal_docs_list {
  display: flex;
  flex-direction: column;
}


.selected_file {
  border: 2px solid var(--lightGrey);
  margin-bottom: 5px;
  background-color: var(--milkyWhite);
  padding: 5px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in;
  width: 100%;
  transition: all 0.3s ease-in;
}

.selected_file:hover {
  border: 2px solid lightgray;
  background-color: lightgray;
}

.selected_filename_cont {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.selected_filename {
  margin-bottom: 0px;
  margin-right: 10px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.delete_selected_file {
  background-color: white;
  height: 100%;
  width: 30px;
  border: 1px solid var(--lightGrey);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-size: 12px;
  color: var(--danger);
  font-weight: bold;
}

.delete_selected_file:hover {
  background-color: var(--danger);
  color: white;
}

.needed_requests {
  width: 100%;
  height: auto;
  /* border: 2px solid var(--lightGrey); */
  border-radius: 10px;
  padding: 10px;
}

.needed_requests_title {
  font-size: 15px;
  font-weight: bold;
  color: gray;
  margin-bottom: 0px;
}

.needed_requests_subtitle {
  font-size: 12px;
  color: gray;
}

.needed_requests_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

/* Prebuild Contracts */
.contract_container {
  width: 100%;
  padding: 20px;
}

.contract_content {
  width: 100%;
  border: 1px solid var(--lightGrey);
  /* background-color: var(--milkyWhite); */
  border-radius: 20px;
  padding: 20px;
  font-size: 15px;
}

.contract_title {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}

.contract_title span {
  font-size: 20px;
  display: block;
}

.input_contracts {
  border: 1px solid var(--lightGrey);
  height: 30px;
  font-size: 15px;
  border-radius: 5px;
  padding-left: 10px;
  transition: all 0.3s ease-in;
}

.input_contracts:focus {
  outline: none;
  border: 1px solid var(--azure);
}

.section_splitter {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.section_policies_contract_title {
  font-weight: bold;
  margin-bottom: 0px;
}

.section_policies_contract_parag {}

.parag_contract {
  width: 100%;
  margin-bottom: 20px;
}

.parag_contract_sign {}

.parag_contract_sign_parag {
  margin-bottom: 15px;
  font-weight: bold;
}

.parag_contract_sign_counterparts_cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.parag_contract_sign_counterparts {
  width: 49%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.signer {
  width: 100%;
  height: 45%;
  border: 1px solid var(--lightGrey);
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.signer:hover {
  border: 1px solid var(--azure);
}

.signer_disabled {
  cursor: no-drop;
  opacity: 0.5;
}

.signer_disabled:hover {
  border: 1px solid var(--lightGrey);
}

.signer_label {
  font-style: italic;
  height: 25px;
  margin-bottom: 0px;
  width: 40%;
}

.signatures_contract_container {
  width: 60%;
  height: 100%;
  text-align: center;
}

.signatures_contract {
  height: 100%;
  width: auto;
  margin-left: auto;
}

.no_signature {
  font-size: 10px;
  color: gray;
  height: 100%;
  text-align: center;
  padding-top: 30px;
}

.policy_italic {
  font-style: italic;
}

/* Modal signing */
.canvas_signing_modale {
  width: 100%;
  cursor: crosshair;
}

.canvas_btn_cont {
  padding: 5px;
  border: 1px solid var(--lightGrey);
  border-radius: 10px;
  margin-bottom: 0px;
}

.clean_btn {
  border: 1px solid transparent;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 0px;
  height: 30px;
}

.modal_footer {
  width: 100%;
  text-align: end;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.signing_modal_header_choise {
  border: 2px solid var(--lightGrey);
  height: 40px;
  width: 50%;
  margin: auto;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 12px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signing_modal_header_btn {
  width: 32%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  font-size: 15px;
  transition: all 0.3s ease-in;
}

.sign_active {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
}

.qr_code_container {
  border: 1px solid var(--lightGrey);
  width: 100%;
  height: auto;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.qr_code_title {
  font-size: 15px;
  color: gray;
  margin-bottom: 5px;
}

.share_container {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--lightGrey);
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  align-content: center;
}

/* External Signature */
.external_signature_container {
  display: none;
}

/* Tenant account setup */
.tenant_account_setup_container {
  width: 100%;
  margin: auto;
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tenant_setup_cont {
  width: 100%;
  height: 100%;
  padding: 100px;
  text-align: center;
  justify-content: space-between;
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
}

.tenant_setup_account_title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.tenant_setup_account_title i {
  font-size: 20px;
}

.tenant_setup_account_subtitle {
  font-size: 12px;
}

.create_tenant_account_btn {
  border: 1px solid white;
  background-color: var(--mainColor);
  color: white;
  font-size: 15px;
  height: 40px;
  border-radius: 5px;
  transition: all 0.3s ease-in;
}

.create_tenant_account_btn:hover {
  border: 1px solid var(--mainColor);
}

.tenant_account_input_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tenant_id_input {
  border: 1px solid var(--mainColor);
  font-size: 15px;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  margin-right: 5px;
  padding-left: 5px;
}

.tenant_id_input::placeholder {
  color: lightgray;
}

.tenant_id_input:focus {
  outline: none;
}

/* Update tenant */
.update_tenant_form {
  border: 1px solid var(--lightGrey);
  padding: 20px;
  width: 100%;
  height: 800px;
}

/* Modal contactss */
.modal_contacts .modal-dialog {
  max-width: 650px;
}


/* contacts */
.contacts_content {
  width: 100%;
  height: 550px;
  overflow: hidden;
}

.range {
  width: 49%;
}

.contacts_content_filter_header {
  height: 40px;
  border-bottom: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  margin-bottom: 10px;
  border-radius: 10px;
}

.contacts_table_cont {
  min-height: 490px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid var(--lightGrey);
  padding: 10px;
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  border-radius: 10px;
  overflow: auto;
}

.table_contacts {
  width: 100%;
  height: auto;
}

.contacts_table_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 45px;
  align-items: center;
  padding-right: 27px;
  border-bottom: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
}

.contacts_header {
  padding-left: 15px;
  padding-right: 35px;
  width: 100%;
  height: fit-content;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_cell_first {
  width: 3%;
  height: 100%;
}

.header_cell {
  height: 100%;
  font-size: 15px;
  padding-left: 5px;
}


.header_cell_label {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
}

.contacts_table_body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 440px;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.contacts_record {
  border: 1px solid var(--lightGrey);
  border-left: 2px solid var(--lightGrey);
  padding: 5px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
}

.contacts_record:hover {
  border-left: 2px solid var(--mainColor);
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
}

.header_cell_first_col {
  width: 50px;
}

.body_cell_first_col {
  width: 40px;
  padding-left: 5px;
  text-align: start;
  font-size: 15px;
}

.missing_tenant_info {
  border: 1px solid orange;
  border-left: 3px solid orange;
}

.warning_table_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: orange;
}

.warning_table_cont p {
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 5px;
}

.missing_data_cel {
  color: orange;
}

.active_cell,
.no_missing_data_cell {
  color: seagreen;
  font-weight: bold;
}

.suspended_cell,
.pending_cell {
  color: orange !important;
  font-weight: bold;
}

.inactive_cell {
  color: var(--secondColor) !important;
  font-weight: bold;
}


.present_data_table_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

.present_data_table_cont p {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 5px;
}

.table_td_first {
  height: 100%;
  width: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.body_cell {
  height: 100%;
  font-size: 13px;
  height: 100%;
  border-radius: 5px;
  padding-left: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid var(--milkyWhite);
}

.body_cell:hover {
  background-color: var(--lightGrey);
}

.body_cell p {
  margin-bottom: 0px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}

.dropdown_item {
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.dropdown-toggle {
  background-color: transparent;
  color: var(--textColor);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
  font-size: 15px;
}

.dropdown-toggle:hover {
  color: var(--mainColor);
}

.custom-dropdown-menu {
  font-size: 15px;
  border: 2px solid var(--lightGrey);
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  border-radius: 10px;
  padding: 5px;
}

.drop_down_item {
  border-radius: 5px;
}

.drop_down_item:hover {
  background-color: var(--lightGrey);
}

.delete {
  color: var(--danger);
}

.disconnect {
  color: var(--secondColor);
}

.connect {
  color: orange;
}

.delete:hover {
  color: var(--danger);
}

/* Delete contacts modal */
.modal_contacts_delete .modal-dialog {
  max-width: 450px;
  margin-top: 10%;
}

.modal_title_cont_prop_delete p {
  color: var(--danger);
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 0px;
}

.modal_title_cont_prop_disconnect {
  color: var(--secondColor);
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 0px;
}

.delete_btn {
  border: 1px solid var(--danger);
  background-color: var(--danger);
}

.loading_opacity_btn{
  opacity: 0.5;
}

.disconnect_btn {
  border: 1px solid var(--secondColor);
  background-color: var(--secondColor);
}


.body_modal_delete {
  padding-left: 20px;
  padding-right: 20px;
}

.body_delete_modal {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}

.modal_delete_mex {
  font-size: 12px;
  margin-bottom: 5px;
}


.delete_confirmation_input {
  border: 2px solid var(--lightGrey);
  height: 30px;
  font-size: 12px;
  border-radius: 5px;
  width: 100%;
  transition: all 0.3s ease-in;
}

.delete_confirmation_input:focus {
  border: 2px solid var(--danger);
  outline: none;
}

/* Screening */
.screening_cont {
  height: auto;
  width: 100%;
  margin-bottom: 10px;
}

.screening_blocks_cont_title {
  font-weight: bold;
  color: var(--mainColor);
  margin-bottom: 5px;
  font-size: 14px;
}

.screening_blocks_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.screening_block {
  border: 1px solid lightgray;
  width: 200px;
  height: 150px;
  border-radius: 10px;
  background-color: var(--milkyWhite);
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: start;
  transition: all 0.3s ease-in-out;
  padding: 10px;
}

.screening_block:hover {
  box-shadow: 2px 2px 5px 2px var(--lightGrey);
}

.plus_block {
  border: 1px solid transparent;
  background-color: white;
  color: gray;
  cursor: pointer;
  transition: all 0.1s ease-in;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.plus_block:hover {
  /* box-shadow: 2px 2px 5px 2px var(--lightGrey); */
  box-shadow: 2px 2px 5px 2px transparent;
  color: var(--textColor);
}

.plus_block_inner {
  text-align: center;
}

.screening_block_inner {
  text-align: start;
  padding: 10px;
  width: 100%;
}

.screening_block_inner i {
  font-size: 20px;
  height: 10%;
}

.screening_block_inner_title {
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: bold;
  height: 30%;
  width: 100%;
}

.screening_block_inner_subtitle {
  font-size: 12px;
  height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Number of lines before ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.screening_block_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  font-size: 12px;
  border-radius: 5px;
}

.ai_block_screen {
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(90deg, var(--mainColor), var(--milkyWhite), white, var(--milkyWhite), var(--secondColor), var(--milkyWhite), var(--azure));
  background-size: 300% 300%;
  animation: rotate-border 5s linear infinite;
  z-index: -1;
  cursor: pointer;
}

.ai_block_screen_inner {
  border-radius: 8px;
  background-color: white;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.ai_block_title {
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}


/* New Screen */
.new_screen_cont {
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.new_screen_quest_block_cont {
  border: 1px solid var(--lightGrey);
  width: 20%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--milkyWhite);
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 10px;
  overflow: auto;
}

.selected_screen_type {
  border: 1px solid lightgray;
  background-color: var(--lightGrey);
  height: 40px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
}


.selected_screen_type i {
  cursor: pointer;
  width: 10px;
  text-align: center;
}

.grip_screen_type {
  width: 20px;
  color: var(--textColor);
  height: 100%;
  text-align: start;
  cursor: pointer;
}

.grip_screen_type i {
  cursor: grab;

}

.selected_screen_type_cont {
  width: 150px;
}

.selected_screen_type_title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  width: 100%;
}

.selected_screen_type_title:focus {
  outline: none;
  border-bottom: 1px solid var(--mainColor);
}

.selected_screen_type_subtitle {
  font-size: 10px;
  margin-bottom: 0px;
}

.new_screen_preview_cont {
  border-radius: 10px;
  width: 78%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.new_scree_spec_info_cont {
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  width: 20%;
  height: auto;
  height: 100%;
  border-radius: 10px;
}

.new_screen_preview_cont_func_bar {
  height: 50px;
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.new_screen_prev {
  border: 2px solid var(--lightGrey);
  width: 100%;
  height: 90%;
  border-radius: 10px;
  padding: 20px;
  overflow: auto;
}

.new_screen_prev_title_cont {
  width: 80%;
  margin: auto;
  height: 30px;
  margin-bottom: 25px;
}

.new_screen_prev_title {
  border: 1px solid transparent;
  font-size: 14px;
  color: gray;
}

.new_screen_prev_title:focus {
  outline: none;
}

.screen_options_modale {
  border: 1px solid var(--mainColor);
  height: 35px;
  font-size: 12px;
  border-radius: 5px;
  background-color: var(--mainColor);
  color: white;
}

.preview_item {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 50px;
}

.preview_item label {
  width: 70%;
  font-size: 14px;
  margin-bottom: 0px;
}

.editable_label {
  border: 1px solid transparent;
  font-size: 14px;
  width: 100%;
  background-color: var(--lightGrey);
  border-bottom: 2px solid var(--mainColor);
}

.editable_label:focus {
  outline: none;
}

.input_field_screen {
  border: 1px solid transparent;
  border-bottom: 2px solid var(--mainColor);
  font-size: 20px;
  width: 70%;
}

.input_field_screen:focus {
  outline: none;
}

.input_field_screen::placeholder {
  color: lightgray;
}

/* Screen types modal */
.modal_screen_types .modal-dialog {
  min-width: 800px;
  top: 100px;
  border-radius: 20px;
  overflow: hidden;
}

.screen_modal_cont {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.screen_modal_header {
  height: 40px;
  border-bottom: 1px solid var(--milkyWhite);
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--milkyWhite);
  padding: 10px;
}

.screen_modal_header p {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
  color: var(--textColor);
}

.screen_modal_body {
  height: 460px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--milkyWhite);
}

.sidebar_screen_modal {
  width: 199px;
  height: 100%;
  border-right: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.sidebar_screen_link {
  width: 100%;
  border: 1px solid white;
  background-color: white;
  height: 35px;
  font-size: 12px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  color: gray;
}

.sidebar_screen_link:hover {
  border: 1px solid var(--lightGrey);
  color: var(--textColor);
}

.sidebar_screen_link i {
  margin-right: 5px;
  font-size: 15px;
}

.sidebar_screen_link p {
  margin-bottom: 0px;
  font-weight: bold;
}

.body_screen_modal {
  width: 599px;
  height: 100%;
  border-top: 1px solid var(--lightGrey);
  border-left: 1px solid var(--lightGrey);
  border-top-left-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.screen_types_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}


.screen_types_cont {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.screen_types_cont_title {
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--mainColor);
  opacity: 0.8;
  font-weight: bold;
  margin-top: 5px;
}

.screen_type_choise {
  font-size: 14px;
  border: 1px solid white;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
}

.screen_type_choise:hover {
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
}

.screen_type_choise i {
  font-size: 12px;
  width: 25px;
  height: 25px;
  text-align: center;
  padding-top: 5px;
  border-radius: 8px;
  background-color: var(--lightGrey);
  color: var(--textColor);
  margin-right: 5px;
  border: 1px solid var(--lightGrey);

}

.screen_type_choise p {
  font-size: 12px;
  margin-bottom: 0px;
}

.selected_screentype_choise {
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
}

/* Messages */
.mex_main_view {
  width: 100%;
  height: 750px;
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.open_chat_cont {
  width: 25%;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  position: relative;
}

.open_chat_title {
  height: 60px;
  border-bottom: 2px solid var(--mainColor);
  text-align: start;
  padding: 10px;
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  color: var(--mainColor);
}

.toggle_buttons_chat {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid var(--mainColor);
}

.toggle_buttons_chat button {
  width: 48%;
  border-radius: 10px;
  border: 1px solid transparent;
  border: 1px solid var(--mainColor);
  background-color: var(--milkyWhite);
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
}

.toggle_buttons_chat button i {
  font-size: 20px;
  color: var(--mainColor);
}

.search_chat {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  padding: 10px;
}

.search_chat i {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--mainColor);
}

.filter_chat {
  border: 2px solid var(--lightGrey);
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  width: 90%;
  border-radius: 50px;
  height: 40px;
  padding-left: 10px;
  font-size: 15px;
}

.filter_chat:focus {
  outline: none;
}

.filter_chat::placeholder {
  color: lightgray;
}


.open_chats {
  height: 565px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: none;
}

.new_chat_btn {
  position: absolute;
  text-align: center;
  border: 1px solid var(--mainColor);
  margin: auto;
  top: 650px;
  right: 55px;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  background-color: var(--mainColor);
  color: white;
}

.chat_prev_cont {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border 0.3s ease-in;
}

.chat_prev_cont:hover {
  border: 1px solid var(--lightGrey);
}

.chat_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  border: 1px solid lightgray;
  overflow: hidden;
}

.avatar_chat_img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 60px;
  background-repeat: no-repeat;
  background-image: url("../public/IMG/avatar_male_beard.jpg");
}

.chat_info_cont {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chat_info {
  width: 80%;
  height: 100%;
}

.chat_name {
  font-size: 15px;
  color: var(--textColor);
  font-weight: bold;
  margin-bottom: 0px;
}

.last_mex {
  font-size: 12px;
  margin-bottom: 0px;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat_hour_cont {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.chat_hour {
  font-size: 12px;
  margin-bottom: 0px;
  color: gray;
}

.chat_cont {
  width: 75%;
  border-left: 2px solid var(--lightGrey);
}

.empty_chat_page {
  width: 75%;
  border: 2px solid var(--lightGrey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.empty_desc_cont {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.empty_desc_title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 35px;
}

.empty_desc_desc {
  margin-bottom: 0px;
  font-size: 20px;
}

.open_current_chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.header_cont {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.chat_header {
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 2px solid var(--mainColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.chat_user_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.chat_selected_user {
  margin-bottom: 0px;
  height: auto;
  margin-left: 10px;
  width: 90%;
}

.chat_view {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-content: space-between; */
  width: 100%;
  margin-top: 60px;
  padding-top: 20px;
  padding-bottom: 70px;
  height: 685px;
  overflow-y: scroll;
  overflow-x: none;
}

.no_available_messages {
  width: 100%;
  text-align: center;
  height: 100%;
  padding: 50px;
  font-size: 15px;
}

.no_available_messages i {
  font-size: 30px;
  animation: jello 2s linear infinite;
}


.entire_message {
  width: 65%;
}

.mex_recieved {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  margin-bottom: 10px;
}

.mex_recieved p {
  margin-bottom: 0px;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  padding: 5px;
  border-radius: 10px;
  min-width: 70px;
  padding-left: 20px;
}


.mex_recieved .entire_message {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
}


.mex_sended {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  font-size: 15px;
  margin-bottom: 10px;
}

.mex_sended p {
  margin-bottom: 0px;
  border: 1px solid var(--secondColor);
  background-color: var(--secondColor);
  color: white;
  padding: 5px;
  border-radius: 10px;
  min-width: 70px;
  padding-right: 20px;
}

.mex_sended .entire_message {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.entire_message span {
  font-size: 12px;
  text-align: end;
  color: gray;
}

.chat_input_container {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.chat_input_cont {
  border: 1px solid lightgray;
  width: 83%;
  margin: auto;
  border-radius: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 2px 2px 20px 2px lightgray;
  background-color: white;
}

.sparkles_ai {
  width: 7%;
  font-size: 20px;
  text-align: center;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  cursor: pointer;
  /* border: 1px solid var(--mainColor); */
  /* padding: 5px; */
  /* border-radius: 50px; */
}

.enter_text_chat_input {
  width: 80%;
  border: 1px solid transparent;
  font-size: 15px;
  padding: 5px;
}

.enter_text_chat_input::placeholder {
  color: lightgray;
}

.enter_text_chat_input:focus {
  outline: none;
}

.send_mex_btn {
  width: 10%;
  border: 1px solid var(--mainColor);
  border-radius: 20px;
  font-size: 15px;
  background-color: var(--mainColor);
  color: white;
  padding: 5px;
}

/* Modal AI */
.modal_AI .modal-dialog {
  max-width: 500px;
}

.modal_ai_title {
  font-size: 35px;
}

.ai_message_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.chat_input_ai_cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  height: 40px;
}

.chat_input_cont_ai {
  border: 2px solid var(--lightGrey);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
}

.send_mex_btn_ai {
  width: 40px;
  height: 100%;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  border-radius: 5px;
  font-size: 15px;
}

.ai_message {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}

.ai_img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid var(--mainColor);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.ai_response_cont {
  width: 410px;
  padding: 2px;
  border-radius: 10px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-image: linear-gradient(white, white),
    linear-gradient(45deg, var(--mainColor), var(--secondColor), red, orange, yellow, seagreen);
  margin-bottom: 20px;
}

.ai_resp_cont {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
}

.ai_resp {
  font-size: 15px;
  margin-bottom: 0px;
}

.functionallities_cont {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.functionallitie {
  height: 100%;
  width: 32%;
  border: 2px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  transition: border 0.3s ease-out;
}

.functionallitie:hover {
  border: 2px solid var(--mainColor);
}

.functionallitie i {
  font-size: 25px;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0px;
  margin-top: 10px;
}

.functionallitie p {
  width: 100%;
  text-align: center;
}

/* Profile */
.profile_container {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}

.section_profile {
  height: auto;
  width: 100%;
  border-top: 2px solid var(--lightGrey);
  padding-top: 5px;
  margin-bottom: 50px;
}

.section_profile_title {
  font-size: 15px;
  font-weight: bold;
  color: var(--mainColor);
  margin-bottom: 5px;
}

.under_title_profile {
  margin-bottom: 30px;
  font-size: 12px;
  color: gray;

}

.section_input_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label_input_cont_profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.label_profile {
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: bold;
  width: 120px;
}

.input_profile {
  border: 1px solid transparent;
  border-bottom: 2px solid lightgray;
  font-size: 12px;
  height: 30px;
  transition: border 0.3s ease-in-out;
  width: 200px;
}

.input_profile:focus {
  outline: none;
  border-bottom: 2px solid var(--mainColor);
}

.section_profile_double_face {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}

.activity_log_container {
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  padding: 10px;
  min-height: auto;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
}

.activity_log_entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid var(--lightGrey);
  margin-bottom: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 10px;
}

.activity_check {
  width: 2%;
}

.activity_log_cell {
  margin-bottom: 0px;
  color: gray;
  font-weight: bold;
}

.timestamp {
  width: 30%;
}

.update_type {
  width: 78%;
}

.success_activity {
  border: 2px solid #d4edda;
  color: seagreen;
}

.error_activity {
  border: 2px solid #f8d7da;
  color: var(--danger);
}

.user_id_cont {
  border: 2px solid var(--lightGrey);
  height: 40px;
  width: fit-content;
  border-radius: 10px;
  background-color: var(--milkyWhite);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
}

.user_id {
  padding: 10px;
  margin-bottom: 0px;
  color: gray;
  font-size: 15px;
}

.copy_user_id_btn {
  border: 2px solid gray;
  background-color: gray;
  color: white;
  height: 100%;
  width: auto;
  font-size: 12px;
  border-radius: 7px;
}

.avatar_img_cont {
  border: 2px solid var(--lightGrey);
  height: 150px;
  width: 150px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  overflow: hidden;
}

.avatar_img {
  background-image: url("../public/IMG/avatar_male_beard.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.btn_cont_profile {
  height: 50px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: end;
}

.save_profile_updates_btn {
  height: 30px;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 15px;
  transition: display 0.3s ease-in-out;
}

.show_passowrd_profile {
  border: 1px solid var(--mainColor);
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  font-size: 12px;
  background-color: white;
  color: var(--mainColor);
  height: 30px;
  width: 30px;
}

/* Website builder */
.wb_builder_main {
  border: 1px solid var(--lightGrey);
  border-radius: 10px;
  background-color: var(--milkyWhite);
  width: 100%;
  padding: 20px;
  min-height: 750px;
}

.create_new_website_btn {
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  border-radius: 5px;
  font-size: 12px;
  height: 30px;
  margin-bottom: 20px;
}

.website_cards_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.website_card {
  border: 1px solid gray;
  height: 250px;
  width: 350px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: var(--lightGrey);
}

.website_builder_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.wb_main_view {
  width: 79.5%;
  height: 100%;
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wb_main_container {
  border: 2px solid var(--lightGrey);
  height: 92%;
  border-radius: 10px;
}

.wb_side_container {
  width: 20%;
  height: 750px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 2px var(--milkyWhite);
  border: 2px solid var(--lightGrey);
}

.wb_navbar_cont {
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  height: 50px;
}

.devices_cont {
  width: 28%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.device_choice {
  width: 32%;
  border: 2px solid lightgray;
  background-color: var(--lightGrey);
  height: 100%;
  border-radius: 10px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
  color: gray;
}

.device_choice:hover {
  border: 2px solid var(--mainColor);
  color: var(--mainColor);
}

.device_choice i {
  font-size: 20px;
  margin-right: 5px;
  height: 100%;
}

.device_choice p {
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: bold;
}























/* Tenant panel________________ */
.tenant_panel {
  border: 1px solid black;
  width: 100%;
  height: 100%;
}

.home_tenant {
  width: 100%;
  height: 100%;
}

.top_home_tenant {
  height: 200px;
  border: 2px solid var(--lightGrey);
  border-radius: 10px;
  margin-bottom: 20px;
}

.bottom_home_tenant {
  border: 2px solid var(--lightGrey);
  height: 400px;
  border-radius: 10px;
  width: 100%;
}


/* My rentals */
.no_rentals_cont {
  border: 2px solid var(--lightGrey);
  width: 100%;
  height: 400px;
  border-radius: 10px;
  text-align: center;
  padding-top: 100px;
}

.no_renatls_title {
  color: var(--mainColor);
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 10px;
}

.no_renatls_subtitle {
  font-size: 15px;
  font-weight: bold;
  color: gray;
}

.rentals_list {
  border: 1px solid var(--lightGrey);
  height: 300px;
  border-radius: 10px;
}

.rentals_table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.rentals_table th,
.rentals_table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.rentals_table th {
  background-color: #f2f2f2;
}

.no_rentals_cont {
  margin-top: 20px;
  text-align: center;
}

.rentals_cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.rental_cards {
  width: 30%;
  border: 1px solid lightgray;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  margin-right: 10px;
}

.rental_cards:hover {
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.static_data_cont_rentals {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../public/IMG/house2_pool.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.dynamic_data_cont_rentals {
  width: 55%;
  height: 100%;
  padding: 20px;
}

.street_rented {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.street_rented p {
  margin-bottom: 0px;
}

.checking_data_cont {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-right: 30px;
}

.checking_data {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 10px;
}

.checking_data label {
  margin-bottom: 0px;
  font-size: 12px;
  color: gray;
  font-weight: bold;
}

.checking_data p {
  margin-bottom: 0px;
  font-size: 14px;
}

.pending_status {
  border: 1px solid orange;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  background-color: orange;
  color: white;
}

.inactive_status {
  border: 1px solid var(--danger);
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  background-color: var(--danger);
  color: white;
}

.ok_status {
  border: 1px solid green;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  background-color: green;
  color: white;
}

.pending_status label,
.inactive_status label,
.ok_status label {
  color: white;
}

/* Single prop */
.single_open_prop_cont {
  margin: auto;
  height: auto;
  width: 100%;
}

.header_open_prop_cont {
  border: 1px solid var(--lightGrey);
  border-radius: 30px;
  height: 500px;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.main_img {
  width: 50%;
  height: 100%;
  border: 1px solid var(--lightGrey);
}

.other_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
}

.img_block {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mini_img {
  border: 1px solid var(--milkyWhite);
  width: 50%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img_block_both{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.other_img_both{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
}


.mini_one{
  width: 100%;
}

.mini_two{
  width: 100%;
}


/* Images */
/* .front_img{
  background-image: url('../public/IMG/front_house.avif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mini_one{
  background-image: url('../public/IMG/pool_house.jpg');
}

.mini_two{
  background-image: url('../public/IMG/kitchen_inside.jpg');
}

.mini_three{
  background-image: url('../public/IMG/couch_inside.jpg');
}

.mini_four{
  background-image: url('../public/IMG/inside_table.jpg');
} */


/* ___________ */

.show_all_img_btn {
  width: auto;
  position: absolute;
  right: 50px;
  bottom: 50px;
  border-radius: 10px;
  background-color: var(--milkyWhite);
  border: 1px solid var(--textColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  color: var(--textColor);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.show_all_img_btn i {
  margin-right: 10px;
}

.show_all_img_btn p {
  margin-bottom: 0px;
}

.prop_details {
  width: 80%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-bottom: 10px;
}

.request_cont {
  width: 30%;
  border: 1px solid lightgray;
  background-color: var(--milkyWhite);
  border-radius: 20px;
  position: sticky;
  top: 10px;
  right: 0;
  padding: 10px;
  height: fit-content;
}

.request_cont_title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
}

.request_cont_subtitle {
  font-size: 12px;
  margin-bottom: 10px;
}

.documents_request_cont {
  border: 1px solid white;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  height: auto;
  padding: 10px;
  margin-bottom: 10px;
}

.documents_request_cont label {
  font-size: 14px;
  margin-bottom: 5px;
}

.doc_cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.download_doc {
  font-size: 12px;
  color: var(--mainColor);
  margin-bottom: 5px;
  margin-right: 5px;
  border: 1px solid lightgray;
  padding: 5px;
  padding-right: 10px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.download_doc p {
  margin-bottom: 0px;
}

.to_sign_marker {
  border: 1px solid orange;
  width: auto;
  height: 100%;
  margin-right: 5px;
  border-radius: 100px;
  background-color: orange;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.requested_docs_cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.requested_docs_cont_spec_title {
  margin-bottom: 0px;
  font-size: 12px;
  color: gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

.requested_docs_cont_spec {
  height: 30px;
  border: 1px solid lightgray;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.requested_docs_cont_spec i {
  width: 10%;
  text-align: center;
  color: orange;
}

.doc_single p{
  margin-bottom: 0px;
  color: gray;
}

.doc_single:hover,
.doc_front:hover {
  background-color: var(--milkyWhite);
}

.doc_single {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  border: 1px solid var(--lightGrey);
  cursor: pointer;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  transition: all 0.3s ease-in;
}

.payment_prop_cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.payment_prop_cont label {
  font-size: 14px;
  margin-bottom: 0px;
}

.payment_prop_cont input {
  height: 30px;
  margin-left: 5px;
  border: 1px solid white;
  border-bottom: 2px solid gray;
  font-size: 14px;
  width: 85%;
}

.payment_prop_cont input::placeholder {
  color: lightgray;
}

.payment_prop_cont input:focus {
  outline: none;
}

.confirm_btn_tenant_upload {
  border: 1px solid var(--mainColor);
  color: white;
  background-color: var(--mainColor);
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 10px;
}

.months_payment_cont_toggle{
  border: 1px solid var(--lightGrey);
  width: 100%;
  height: 45px;
  overflow: auto;
  border-radius: 20px;
  padding: 10px;
}

.months_payment_cont_toggle.open{
  height: 150px;
}

.months_payment_cont_toggle_title{
  height: 30px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: var(--mainColor);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.months_payment_cont_toggle_title:hover{
  color: var(--secondColor);
}

.month_to_pay{
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 100px;
  border: 1px solid var(--lightGrey);
  margin-bottom: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  font-size: 12px;
  flex-direction: row;
  justify-content: space-between;
}

.month_to_pay:hover{
  background-color: var(--milkyWhite);
}

.month_to_pay_amount,
.month_to_pay_month{
  width: 50%;
  height: 100%;
  margin-bottom: 0px;
}

/* Modal details */
.modal_contacts_details .modal-dialog{
  max-width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
}

.table_monthly_det{
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 20px;
}

.monthly_det_table{
  width: 100%;
  font-size: 14px;
}

.monthly_details_rent_title{
  font-size: 15px;
  color: var(--mainColor);
  font-weight: bold;
}

.details_cont_label{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 0px;
}

.details_cont_label p{
  margin-bottom: 0px;
}

.details_cont_label label{
  margin-bottom: 0px;
}



/* Modal open images */
.modal_image_cont{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal_image_body{
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
}

.modal_image_body img{
  width: 75%;
  height: auto;
  margin: auto;
  background-size: cover;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.modal_footer{
  height: 60px;
}

.close_img_galery_btn{
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  margin: auto;
  color: white;
  height: 60px;
  width: 60px;
  border-radius: 100px;
  z-index: 100;
  position: fixed;
  bottom: 10px;
  left: 50%;
}

/*  */


.content_cont {
  width: 60%;
  height: 100%;
  color: var(--textColor);
}

.content_cont_title {
  font-size: 35px;
  margin-bottom: 0px;
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
}

.prop_type {
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
  font-weight: bold;
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white;
  font-size: 12px;
  width: auto;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-left: 10px;
  height: 25px;
  border-radius: 100px;
  padding: 5px 10px;
}

.desc_prop_title{
  margin-bottom: 5px;
  font-size: 20px;
}

.content_subtitle_cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.content_cont_elem {
  font-size: 12px;
  margin-right: 15px;
  margin-bottom: 0px;
}

.comforts_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  /* border: 1px solid lightgray; */
  /* padding: 10px 10px 0px 10px; */
  /* border-radius: 20px; */
}

.comfort_bubble {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid lightgray;
  height: 30px;
  border-radius: 100px;
  margin-bottom: 5px;
  margin-right: 5px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.comfort_bubble:hover{
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.comfort_bubble i {
  margin-right: 10px;
}

.comfort_bubble p {
  margin-bottom: 0px;
}

.desc_prop_cont {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.desc_prop {
  font-size: 15px;
}

.map_cont {
  width: 100%;
  height: 300px;
  border: 1px solid lightgray;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
}

.property_details_cont{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  height: auto;
}

.other_prop_info{
  width: 100%;
  height: auto;
  font-size: 15px;
}

.other_prop_info_single{
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  transition: all 0.3s ease-in;
  border-bottom: 1px solid transparent;
  padding: 10px;
  cursor: pointer;
}

.other_prop_info_single:hover{
  background-color: var(--milkyWhite);
}

.other_prop_info_single p{
  margin-bottom: 0px;
  width: 44%;
  font-weight: bold;
  color: gray;
}

.icon_prop_det{
  text-align: center;
  width: 5%;
  margin-right: 10px;
  color: gray;
  /* background-color: var(--azure); */
  height: 30px;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
}

.checked_permission{
  font-size: 25px;
  color: green;
}

.unchecked_permission{
  color: var(--danger);
  font-size: 25px;
}

.owner_data_cont {
  border: 1px solid lightgray;
  border-radius: 20px;
  width: 100%;
  height: auto;
  padding: 20px;
  margin-bottom: 20px;
}

.owner_data {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.owner_img {
  height: 100%;
  width: 100px;
  border-radius: 100%;
  border: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  margin-right: 10px;
}

.owner_data_info {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.owner_name {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: bold;
}

.owner_description {
  font-size: 15px;
}

.last_cont_prop {
  height: 100px;
  width: 100%;
  border: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  margin-bottom: 100px;
  border-radius: 20px;
}

/* Document loader */
.document_file_upload_tenant{
  display: none;
}

/* Payments */
.tenant_payment_req {
  border: 1px solid var(--lightGrey);
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

/*Loading open agency  */
.loading_open_agency{
  border: 1px solid var(--lightGrey);
  height: 700px;
  border-radius: 20px;
  width: 95%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.first_block_loading_open_agency{
  border: 1px solid var(--milkyWhite);
  background-color: var(--lightGrey);
  height: 100px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.second_block_loading_open_agency{
  height: 225px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.third_block_loading_open_agency{
  height: 285px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sec_one_block_loading{
  width: 29%;
  border: 1px solid var(--milkyWhite);
  background-color: var(--lightGrey);  
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.sec_two_block_loading{
  border: 1px solid var(--milkyWhite);
  background-color: var(--lightGrey); 
  width: 70%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.first_block_loading_open_agency::before,
.sec_one_block_loading::before,
.sec_two_block_loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--milkyWhite) 50%, rgba(255, 255, 255, 0) 100%);
  animation: beam 2s linear infinite;
}


/* Loading agency */
.loading_agency{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agency_cube_loading{
  border: 1px solid var(--milkyWhite);
  width: 300px;
  height: auto;
  border-radius: 20px;
  background-color: var(--lightGrey);
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 20px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  opacity: 0.5;
}

.agency_cube_loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--milkyWhite) 50%, rgba(255, 255, 255, 0) 100%);
  animation: beam 2s linear infinite;
}

.agency_cube_loading .agency_cube_header p{
  border: 1px solid var(--milkyWhite);
  width: 90%;
  border-radius: 10px;
  background-color: white;
  color: white;
}

/* Agency */
.agency_content{
  width: 100%;
  max-height: 600px;
  pointer-events: all;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.agency_cube{
  border: 1px solid lightgray;
  width: 300px;
  height: auto;
  border-radius: 20px;
  background-color: white;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 20px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.agency_cube:hover{
  box-shadow: 2px 2px 10px 2px var(--milkyWhite);
  border: 1px solid lightgray;
}

.agency_cube_header{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.agency_cube_header i{
  border: 1px solid var(--lightGrey);
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  margin-right: 10px;
  border-radius: 5px;
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.agency_cube_header p{
  margin-bottom: 0px;
  font-size: 25px;
}

/* New agency */
.new_agency_main_cont{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 700px;
  margin-top: 20px;
}

.new_agency_main_container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.steps_new_element{
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: var(--milkyWhite);
  border-radius: 20px;
}

.main_new_element{
  width: 79%;
  height: 100%;
  padding: 50px;
  border: 1px solid lightgray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.step_cont_element{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: auto;
  font-size: 15px;
}

.active_step{
  color: var(--mainColor);
}

.completed_step{
  color: var(--mainColor);
}

.step_cont_element p{
  margin-bottom: 0px;
  margin-left: 10px;
}

.separator_steps_cont{
  border-left: 1px solid lightgray;
  height: 20px;
  width: 95%;
}

.main_new_element_title{
  font-size: 25px;
  margin-bottom: 5px;
}

.main_new_element_desc{
  font-size: 14px;
  margin-bottom: 20px;
}

.main_new_element_input_desc_cont{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-bottom: 20px;
  overflow: auto;
}

.element_agency_final{
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid var(--lightGrey);
  margin-bottom: 10px;
}

.element_agency_final label{
  margin-bottom: 0px;
  margin-top: 5px;
  font-size: 12px;
}

.element_agency_final p{
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 15px;
}

.selected_elements_agency_cont{
  border: 1px solid var(--lightGrey);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.selected_elements_agency_cont p{
  margin-bottom: 5px;
  margin-right: 5px;
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  padding: 5px 10px;
  border-radius: 100px;
  width: fit-content;
}

.finalize_now_btn{
  border: 1px solid var(--mainColor);
  height: 40px;
  width: 200px;
  border-radius: 5px;
  background-color: var(--mainColor);
  color: white;
  font-size: 14px;
}

.new_element_input_desc_cont{
  display: flex;
  flex-direction: column;
  align-content: start;
  width: 100%;
  margin-bottom: 10px;
}

.main_new_element_input_desc_cont_row{
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.new_element_input_desc_cont label{
  font-size: 14px;
  font-weight: bold;
}

.new_element_input_desc_cont_input_row,
.new_element_input_desc_cont_input{
  border: 1px solid transparent;
  border-bottom: 1px solid var(--lightGrey);
  height: 35px;
  font-size: 14px;
  transition: all 0.3s ease-in;
  width: 50%;
}

.new_element_input_desc_cont_input_row{
  width: 95%;
}

.new_element_input_desc_cont_input_row:focus,
.new_element_input_desc_cont_input:focus{
  outline: none;
  border-bottom: 1px solid var(--mainColor);
}

.new_element_input_desc_cont_textarea{
  border: 1px solid var(--lightGrey);
  border-radius: 10px;
  height: 150px;
  font-size: 14px;
  transition: all 0.3s ease-in;
  padding-left: 5px;
}

.new_element_input_desc_cont_textarea:focus{
  outline: none;
  border: 1px solid var(--mainColor);
}

.next_element_cont{
  width: 98%;
  margin: auto;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.next_element_cont_btn{
  height: 100%;
  font-size: 12px;
  width: 100px;
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  border-radius: 5px;
  transition: all 0.3s ease-in;
}

.next_element_cont_btn:hover{
  background-color: var(--mainColor);
  color: white;
  border: 1px solid var(--mainColor);
}

.disabled_btn_naviga,
  .disabled_btn_naviga:hover{
    border: 1px solid transparent;
    color: transparent;
    background-color: transparent;
}


.new_element_input_desc_cont_checkbox{
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.new_element_input_desc_cont_checkbox label{
  margin-bottom: 0px;
}

.new_element_input_desc_cont_checkbox input{
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 0px;
}

.error-border{
  border-bottom: 2;
}

.choosen_element_container{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.choosen_element_container_single{
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  color: var(--textColor);
  width: auto;
  padding: 5px 10px;
  height: 20px;
  border-radius: 100px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-right: 5px;
  margin-bottom: 5px;
}

.choosen_element_container_single_parag{
  margin-bottom: 0px;
}

.choosen_element_container_single_parag i{
  font-size: 15px;
  margin: 10px 5px ;
}

.remove_item{
  font-size: 12px !important;
  cursor: pointer;
}

.password_element{
  border: 1px solid var(--lightGrey);
  padding: 10px;
}

.password_element_desc{
  font-size: 12px;
}

.licence_element_container{
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.licence_card{
  border: 1px solid lightgray;
  width: 32%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.licence_card:hover{
  border: 1px solid var(--mainColor);
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.selected_licence_card{
  border: 1px solid var(--mainColor);
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
  color: var(--mainColor);
}

.licence_title{
  margin-bottom: 5px;
}

.licence_title_desc{
  font-size: 12px;
}

.price_container{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: auto;
  margin-top: auto;
}

.licence_price{
  margin-bottom: 0px;
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  width: 49%;
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.licence_price span{
  font-size: 10px;
}

.licence_price:hover{
  background-color: var(--lightGrey);
  border: 1px solid var(--lightGrey);
}

.selected_licence_price{
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
  color: white;
}


.icons_price_cont{
  border: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 10px;
}

.icons_price_cont i{
  font-size: 20px;
  border: 1px solid lightgray;
  width: 40px;
  margin: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  transition: all 0.3s ease-in;
}

.icons_price_cont i:hover{
  box-shadow: 2px 2px 5px 2px var(--lightGrey);
}

.cloud_storage_cont{
  border: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  padding: 20px;
}

.cloud_storage_amount{
  margin-bottom: 0px;
}

.payment_container{
  /* border: 1px solid lightgray; */
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 2px 2px 10px 2px var(--lightGrey); */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.payment_container_title{
  font-size: 25px;
}

.payment_bill_cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-bottom: 1px solid var(--lightGrey);
  margin-bottom: 10px;
}

.payment_bill_cont label{
  margin-bottom: 0px;
}

.payment_bill_cont p{
    font-weight: bold;
    margin-bottom: 0px;
}

.total_final{
  font-weight: bold;
  font-size: 25px;
}

.total_final_span{
  font-size: 15px;
}

.pay_now_btn{
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  margin-left: auto;
  font-size: 15px;
  margin-top: 20px;
}

.pay_now_btn #spinner{
  color: white;
}

/* Open agency dashboard */
.main_content_agency{
  margin-bottom: 50px;
}

.agency_dashboard{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.header_dashboard{
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.header_cont_agency_dash{
  border: 1px solid lightgray;
  border-radius: 20px;
  width: 24%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease-in;
  cursor: pointer;
  justify-content: space-between;
}

.header_cont_agency_dash_inner{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_cont_agency_dash:hover{
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
}

.header_cont_agency_dash i{
  font-size: 20px;
  color: white;
  margin-right: 20px;
  margin-bottom: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  padding: 5px;
}

.header_cont_agency_dash_cont{
  display: flex;
  flex-direction: column;
  align-items: start;
}

.header_cont_agency_title{
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: bold;
}

.header_cont_agency_subtitle{
  margin-bottom: 0px;
  font-size: 12px;
}

.header_cont_agency_number{
  font-size: 25px;
  margin-bottom: 0px;
}

.middle_dashboard{
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.middle_second_open_agency{
  border: 1px solid lightgray;
  width: 40%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-around; */
}

.middle_second_open_agency_title{
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
  color: var(--mainColor);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.disancore_chat{
  margin-right: 5px;
  cursor: pointer;
}

.middle_second_open_agency_subtitle{
  margin-bottom: 10px;
  font-size: 12px;
}

.pie_center_data{
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.middle_first_open_agency{
  border: 1px solid lightgray;
  width: 59%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: auto;
  padding: 10px;
}

.no_data_open_agency{
  border: 1px solid var(--mainColor);
  width: 100%;
  height: 80%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no_data_open_agency p{
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 15px;
  margin-top: 80px;
  margin-bottom: 10px;
}

.movements_container{
  height: 100%;
  width: 100%;
  overflow: auto;
}

.users_rows_open_agency{
  width: 100%;
  border: 1px solid lightgray;
  height: 40px;
  border-radius: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  padding: 0px 10px;
  justify-content: space-between;
}

.users_rows_open_agency p{
  width: 24%;
  margin-bottom: 0px;
}

.owner_agency{
  border: 1px solid var(--azure);
}

.user_mov{
  font-size: 14px;
  font-weight: bold;
}

.user_mov_quant{
  font-weight: bold;
}

.user_mov_date{
  text-align: center;
}

.end_dashboard{
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  }


.dashboard_agency_chat_container{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.dashboard_agency_chat_cont{
  height: 80%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.dashboard_agency_mex_cont{
  width: 80%;
  height: auto;
  background-color: transparent;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.dashboard_agency_mex{
  width: auto;
  display: flex;
  flex-direction: column;
  border-left: 2px solid var(--mainColor);
  margin-bottom: 10px;
  padding-left: 5px;
}

.dashboard_agency_sender{
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 10px;
}

.dashboard_agency_message {
  display: inline-block; 
  max-width: fit-content;
  background: linear-gradient(to left, var(--secondColor), var(--azure));
  color: white;
  border-radius: 10px;
  font-size: 12px;
  padding: 5px 10px;
  margin-left: 20px;
  word-wrap: break-word; 
  overflow-wrap: anywhere; 
}


.my_messege_agency_name{
  color: var(--mainColor);
}

.my_messege_agency{
  border: 1px solid var(--mainColor);
  background: none;
  background-color: var(--mainColor);
}

.refresh_chat_btn{
  width: fit-content;
  font-size: 14px;
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--mainColor);
  border-radius: 5px;
  margin-top: 5px;
}

.rotating{
  animation: rotate 1s linear infinite;
}

.no_messages_agency{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-around;
  font-size: 15px;
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.tag_agency{
  font-weight: bold;
}

.dashboard_agency_chat_input_cont{
  height: 19%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  margin: auto;
  justify-content: space-between;
}

.dashboard_agency_chat_input{
  width: 85%;
  height: 35px;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 20px 2px var(--milkyWhite);
  /* border-bottom: 2px solid var(--mainColor); */
  border-radius: 100px;
  margin: auto;
  transition: all 0.3s ease-in;
  padding-left: 10px;
  font-size: 14px;
}

.dashboard_agency_chat_input::placeholder{
  color: lightgray;
}

.dashboard_agency_chat_input:focus{
  outline: none;
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
}

.dashboard_agency_chat_btn{
  width: 100px;
  border: 1px solid var(--mainColor);
  font-size: 14px;
  height: 35px;
  border-radius: 100px;
  background-color: var(--mainColor);
  color: white;
}

.shared_space_agency_cont_input{
  width: 100%;
  margin-bottom: 10px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.shared_space_agency_cont_input input{
  font-size: 12px;
  width: 95%;
  height: 100%;
  margin-right: 5px;
}

.upload_file_btn_agency{
  width: 40px;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: white;
  border-radius: 5px;
  height: 100%;

}

.not_allowed{
  background-color: var(--danger);
  border: 1px solid var(--danger);
}

.not_allowed_input{
  border: 1px solid var(--danger);
  color: var(--danger);
}

.shared_space_agency_cont{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.shared_file_agency{
  height: 30px;
  width: 100%;
  border: 1px solid lightgray;
  background-color: var(--milkyWhite);
  border-radius: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  font-size: 12px;
}

.shared_file_agency i{
  width: 5%;
  text-align: center;
  height: 100%;
  cursor: pointer;
}

.download_icon{
  color: green;
}

.shared_file_agency p{
  margin-bottom: 0px;
  width: 30%;
}

.file_name_agency {
  font-weight: bold;
  white-space: nowrap; /* Prevents the text from wrapping to a new line */
  overflow: hidden; /* Hides overflow content */
  text-overflow: ellipsis; /* Adds ellipsis (...) when the text overflows */
  max-width: 30%; /* Set a maximum width for the ellipsis to work */
  display: inline-block; /* Ensures the ellipsis applies properly */
}


.settings_agency{
  width: 100%;
  text-align: center;
  color: var(--mainColor);
  height: 50px;
  font-size: 12px;
  font-weight: bold;
}

/* New user modal */
.modal_new_user .modal-dialog{
  max-width: 500px;
  margin-top: 100px;
  overflow: hidden;
  border-radius: 20px;
}

.modal_title_new_user{
  height: 40px;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: gray;
  font-size: 18px;
  margin-bottom: 0px;
}

.modal_title_new_user p{
  margin-bottom: 0px;
}

.body_modal_new_user{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.choises_new_user_cont{
  border: 1px solid lightgray;
  font-weight: bold;
  background-color: white;
  color: gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 49%;
  border-radius: 20px;
  height: 150px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.choises_new_user_cont:hover{
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.blocked_choises_new_user_cont{
  pointer-events: none;
  background-color: var(--lightGrey);
  filter: blur(1px);
}

.choise_new_user_parag{
  margin-bottom: 0px;
  font-size: 14px;
}

.choise_new_user_exp{
  font-size: 12px;
}

.double_body_cell{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  height: 100%;
  height: 35px;
  align-items: center;
}

.ok_status_paid{
  color: white;
  font-size: 14px;
  margin-right: 5px;
}

.uploading_status {
  color: white;
  font-size: 14px;
  margin-right: 5px;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ko_status_paid{
  color: white;
  margin-right: 5px;
  font-size: 14px;
}

.loading_cell{
  /* animation: flash 1s infinite; */
  border: 1px solid orange;
  width: 100%;
  background-color: orange;
  color: white;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.paid_cell{
  border: 1px solid seagreen;
  width: 100%;
  background-color: seagreen;
  color: white;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.error_cell{
  border: 1px solid var(--danger);
  width: 100%;
  background-color: var(--danger);
  color: white !important;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.error_cell .custom-dropdown-toggle{
  color: white;
  font-size: 14px;
}

.copy_clipboard_cell{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  height: 30px;
  border: 1px solid transparent;
}

.copy_clipboard_cell i{
  color: var(--azure);
  font-size: 15px;
}

.highlighted{
  color: var(--azure);
}

.error_row{
  border-left: 3px solid var(--danger);
  background-color: rgb(250, 223, 223);
}

.licence_error{
  font-weight: bold;
  color: var(--danger);
}

.cell_beam_loading {
  border: 1px solid var(--lightGrey);
  border-radius: 5px;
  width: 100%;
  height: 25px;
  background-color: var(--lightGrey);
  overflow: hidden;
  position: relative;
}

.cell_beam_loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--milkyWhite) 50%, rgba(255, 255, 255, 0) 100%);
  animation: beam 2s linear infinite;
}

@keyframes beam {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}


/* Sales */
.sales_cont_header{
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sales_container{
  border: 1px solid black;
  width: 100%;
  height: 800px;

}

/* New deal container */
.new_deal_container{
  width: 100%;
  height: auto;
  margin-top: 20px;
  border: 1px solid black;
}


/* Investors */
.investors_cont{
  width: 100%;
  padding: 50px;
  height: 100vh;
}

/* Pitch deck */
.loading_bar{
  border: 1px solid var(--mainColor);
  height: 10px;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  border-radius: 100px;
}

.pitch_deck_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 20px;
  width: 90%;
  margin: auto;
  text-align: center;
}

.inner_loading_bar{
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  border-radius: 100px;
  height: 100%;
  transition: all 0.3s ease-in;
}

.slide {
  border: 1px solid lightgray;
  height: 100%;
  width: 100%;
  background: white;
  padding: 40px;
  animation: fadeIn 1s ease-in-out;
}

.slide_title {
  font-size: 70px;
  color: var(--textColor);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin: auto;
}

.hello_icon{
  animation: swing 1.5s infinite;
}

.slide_content {
  font-size: 30px;
  color: #555;
  line-height: 1.8;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}


.nav_button{
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  color: var(--mainColor);
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.how_it_works{
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  border-radius: 20px;
  width: 300px;
  height: 300px;
  margin: auto;
}

.pitch_message_cont{
  border: 1px solid lightgray;
  background-color: var(--milkyWhite);
  width: 400px;
  height: auto;
  margin: auto;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
  padding: 20px;
}

.pitch_message_cont p{
  margin-bottom: 0px;
  text-align: center;
}

.title_slide{
  font-size: 35px;
  text-align: start;
  margin-bottom: 0px;
}

.content_slide{
  font-size: 25px;
  text-align: start;
  margin-bottom: 0px;
}

.img_cont_slide{
  width: 90%;
  margin: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--lightGrey);
  padding: 20px;
}

.logo_img_job:nth-of-type(1) {
  height: 200px;
  width: 400px;
  background-image: url("../public/IMG/Jobs/lux.png");
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.logo_img_job:nth-of-type(2) {
  height: 200px;
  width: 150px;
  background-image: url("../public/IMG/Jobs/Generali_logo.png");
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.logo_img_job:nth-of-type(3) { /* Fixed to nth-of-type for consistency */
  height: 200px;
  width: 300px;
  background-image: url("../public/IMG/Jobs/accenture.png");
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.content_italic{
  text-align: start;
  font-style: italic;
}

.slide_points{
  font-size: 15px;
  margin-bottom: 0px;
  text-align: start;
}


/* Marketplace */
.market_place{
  width: 100%;
  min-height: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 25px;
}

.property_card_marketplace {
  width: 290px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
}

.loading_market_place{
  width: 100%;
  height: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.property_img_blotter_loading{
  height: 270px;
  border: 1px solid var(--lightGrey);
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--lightGrey);
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.property_img_blotter_loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--milkyWhite) 50%, rgba(255, 255, 255, 0) 100%);
  animation: beam 5s linear infinite;
}

.property_btn_action_loading{
  border: 1px solid white;
  width: 170px;
  border-radius: 100px;
  height: 35px;
  padding: 5px 10px 5px 10px;
  background-color: white;
  color: var(--textColor);
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s ease-in; 
}

.property_btn_action_loading_second{
  border: 1px solid white;
  width: 40px;
  border-radius: 100px;
  height: 35px;
  padding: 5px 10px 5px 10px;
  background-color: white;
  color: var(--textColor);
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s ease-in; 
}

.property_description_cont_loading{
  height: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.property_title_card_loading{
  height: 25px;
  background-color: var(--lightGrey);
  border-radius: 100px;
  margin-bottom: 5px;
  width: 200px;
}

.property_location_card_loading{
  height: 25px;
  background-color: var(--lightGrey);
  border-radius: 100px;
  margin-bottom: 5px;
  width: 120px;
}

.property_amount_card_loading{
  height: 25px;
  background-color: var(--lightGrey);
  border-radius: 100px;
  margin-bottom: 5px;
  width: 80px;
}

.footer_marketplace{
  height: 150px;
  width: 100%;
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  width: 98%;
  border-radius: 20px;
  margin: auto;  
}



/* Property managment */
.home_pm_cont{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_pm_cont{
  height: auto;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 200px;
}

.main_pm_container{
  padding: 50px;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
}

.main_title_pm{
  font-size: 50px;
  margin-bottom: 0px;
}

.ia_span_pm{
  font-weight: bold;
  color: var(--mainColor);
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style:oblique;
}

.main_subtite_pm{
  font-size: 25px;
}

.action_btn_cont{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
}

.action_btns_pm {
  border: 1px solid lightgray;
  font-size: 15px;
  color: white;
  padding: 10px;
  width: 200PX;
  border-radius: 10px;
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  position: relative; 
  box-shadow: 2px 2px 30px 2px lightgray;
  overflow: hidden;
}

.action_btns_pm #spinner{
  color: white;
}

.action_btns_pm_no_pulse {
  border: 1px solid lightgray;
  font-size: 15px;
  color: white;
  padding: 10px;
  width: 200PX;
  border-radius: 10px;
  background: linear-gradient(to right, var(--mainColor), var(--secondColor));
  position: relative; 
  box-shadow: 2px 2px 30px 2px lightgray;
  overflow: hidden;
}


.action_btns_pm::before,
.action_btns_pm::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100px;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%; 
  transform: translate(-50%, -50%);
  pointer-events: none; 
  /* animation: waveAnimation 3s infinite ease-out; */
}

.action_btns_pm::before {
  animation-delay: 0s;
}

.action_btns_pm::after {
  animation-delay: 1.5s; 
}

@keyframes waveAnimation {
  0% {
    width: 0;
    height: 0;
    opacity: 0.7;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}


.phone_container_pm{
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.phone_pm{
  width: 300px;
  height: 100%;
  border: 1px solid lightgray;
  border-radius: 30px;
  box-shadow: 2px 2px 20px 2px gray;
  padding: 3px;
  overflow: hidden;
  background-color: black;
  margin-top: 20px;
}

.inner_phone_pm{
  height: 100%;
  width: 100%;
  border-radius: 26px;
  padding: 5px;
  background-color: white;
}

.phone_mic_pm{
  border: 1px solid black;
  width: 30%;
  margin: auto;
  height: 20px;
  border-radius: 100px;
  background-color: black;
  margin-bottom: 50px;
}

.phone_cont_main_pm{
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rent_type_phone_pm{
  border: 1px solid lightgray;
  width: 80%;
  height: 30px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--lightGrey);
  padding: 2px;
  position: absolute;
  width: 350px;
  margin-top: -650px;
}

.rent_type_phone_inner_pm{
  font-size: 14px;
  margin-bottom: 0px;
  border: 1px solid white;
  height: 100%;
  width: 32%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 100px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.selected_rental_type{
  border: 1px solid white;
  background-color: white;
}

.fade-container {
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Smooth fade effect */
}
.fade-container.fading {
  opacity: 0; /* Fade out */
}

.title_phone_pm_cont{
  margin-bottom: 0px;
  text-align: start;
  width: 95%;
}

.title_phone_pm{
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
}

.subtitle_phone_pm{
  font-size: 12px;
  color: gray;
}

.houses_cards_deck_pm{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.house_card_pm{
  height: 200px;
  border: 1px solid lightgray;
  /* box-shadow: 2px 2px 10px 2px var(--lightGrey); */
  width: 200px;
  border-radius: 20px;
  background-image: url("../public/IMG/bedroom.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.left_right_deck_pm{
  border: 1px solid var(--milkyWhite);
  background-color: var(--milkyWhite);
  height: 150px;
  width: 38px;
  z-index: 0;
}

.left_right_deck_pm:nth-child(1){
  background-image: url("../public/IMG/bathroom.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px 0px 0px 20px;
}

.left_right_deck_pm:nth-child(3){
  background-image: url("../public/IMG/kitchen.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0px 20px 20px 0px;
}

.last_card_pm{
  height: 120px;
  overflow: hidden;
  width: 100%;
  /* border: 1px solid lightgray; */
  margin-top: 50px;
  border-radius: 20px;
  background-image: url("../public/IMG/new_york_3d.png");
  background-size: 120%;
  background-position: center;
  /* background-color: var(--milkyWhite); */
  /* box-shadow: 2px 2px 10px 2px var(--lightGrey); */
  padding: 5px;
}

.last_card_pm_title{
  width: 100px;
  margin-left: auto;
  font-size: 14px;
  border: 1px solid white;
  background-color: white;
  border-radius: 20px;
  color: var(--textColor);
  text-align: start;
  font-weight: bold;
  height: 100%;
  padding: 10px;
}

.pin_cont{
  position: absolute;
  z-index: 20;
  margin-left: 240px;
  animation: jello 3s infinite;
  rotate: -50deg;
}

.first_mex_phone{
  width: 300px;
  height: 80px;
  box-shadow: 2px 2px 30px 2px lightgray;
  border: 1px solid var(--lightGrey);
  position: absolute;
  z-index: 20;
  margin-left: 360px;
  margin-top: -150px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
  border: 1px solid lightgray;
}

.header_first_mex_phone_pm{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_first_mex_phone_pm i{
  font-size: 15px;
  animation: swing 0.7s infinite;
}

.first_mex_phone_title{
  margin-bottom: 0px;
  font-size: 15px;
  margin-left: 5px;
  font-weight: bold;
}

.first_mex_phone_subtitle{
  font-size: 12px;
  margin-bottom: 0px;
  width: 85%;
  margin: auto;
}

.second_mex_phone{
  width: 300px;
  height: 200px;
  box-shadow: 2px 2px 30px 2px lightgray;
  border: 1px solid var(--lightGrey);
  position: absolute;
  z-index: 20;
  margin-right: 350px;
  margin-top: 280px;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid lightgray;
}

.second_mex_phone_title{
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: bold;
}

.second_mex_phone_subtitle{
  font-size: 12px;
  color: gray;
}

.tiny_line_cont{
  width: 100%;
  height:auto;
  position: absolute;
  margin-left: -75px;
  margin-top: -20px;
  z-index: 1;
}

.steps_pm_container{
  height: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 200px;
}

.steps_pm_cont_title{
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}

.steps_pm_cont_subtitle{
  font-size: 20px;
}

.steps_pm_cont{
  height: auto;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 100px;
}

.step_pm_box{
  width: 32%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  border-right: 2px solid var(--textColor);
  min-height: 200px;
}

.step_pm_box:last-child {
  border-right: 2px solid transparent;
}

.step_pm_title{
  color: var(--textColor);
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 20px;
}

.step_pm_subtitle{
  font-size: 15px;
  color: gray;
}

.room_image {
  height: 350px;
  width: 100%;
  background-image: url("../public/IMG/inside_house.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 50px;  
}

.step_pm_box_icon{
  border: 1px solid var(--textColor);
  border-radius: 10px;
  color: white;
  width: 40px;
  height: 40px;
  background-color: var(--textColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}

.testimony_pm_cont{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 200px;
}

.roi_cont_pm{
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.roi_blocks{
  border: 1px solid lightgray;
  background-color: white;
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
  height: auto;
  width: 32%;
  border-radius: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.roi_blocks:nth-child(1){
  height: 500px;
}
.roi_blocks:nth-child(2){
  height: auto;
}
.roi_blocks:nth-child(3){
  height: 500px;
}

.roi_block_title{
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.roi_block_subtitle{
  font-size: 15px;
  margin-bottom: 0px;
  height: 80px;
}

.earnings_calculation_n{
  height: auto;
  border: 1px solid var(--lightGrey);
  background-color: var(--milkyWhite);
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
  justify-content: space-around;
  font-size: 14px;
  font-weight: bold;
}

.earnings_calculation_n p{
  margin-bottom: 5px;
}

.specifics{
  font-size: 12px;
  margin-bottom: 0px;
  border: 1px solid var(--azure);
  background-color: var(--azure);
  color: white;
  border-radius: 100px;
  padding: 0px 10px;
}

.costs_container_tite{
  font-size: 12px;
  margin-bottom: 0px;
}

.btn_cont_action_btns_pm{
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.qa_pm_cont{
  height: auto;
  width: 100%;
  margin: auto;
  border-radius: 50px 50px 0px 00px;
  background-color: var(--milkyWhite);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px;
  border-bottom: 1px solid var(--lightGrey);
}

.qa_cont_pm{
  width: 50%;
  margin: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 100px;
}

.qa_toggle_cont{
  width: 100%;
  border-bottom: 1px solid var(--lightGrey);
  height: auto;
  padding: 20px;
  margin-bottom: 0px;
  cursor: pointer;
}

.qa_toggle_cont_title{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: gray;
  margin-bottom: 15px;
}

.qa_cont_qestion{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
}

.qa_cont_answer{
  font-size: 14px;
}

.qa_cont {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
  padding: 0;
}

.qa_cont.open {
  max-height: 1000px; /* Arbitrary large value to accommodate content */
  padding: 10px 0;
}

.qa_toggle_cont_title {
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
}

.qa_toggle_cont_title:hover {
  color: var(--mainColor);
}

.fa-chevron-down {
  transition: transform 0.3s ease;
}

.qa_toggle_cont .open ~ .qa_toggle_cont_title .fa-chevron-down {
  transform: rotate(180deg);
}

.ask_btn_cont{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--milkyWhite);
}

.contact_us_pm_btn {
  border: 1px solid lightgray;
  font-size: 20px;
  color: white;
  padding: 15px;
  width: 250PX;
  border-radius: 10px;
  background-color: var(--textColor);
  position: relative; 
  box-shadow: 2px 2px 30px 2px lightgray;
  overflow: hidden;
  margin: auto;
}

.fj_comm_cont{
  width: 100%;
  height: auto;
  background-color: var(--milkyWhite);
  padding: 25px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.fj_comm_cont_title{
  font-size: 14px;
  font-weight: bold;
  color: gray;
  text-align: center;
  width: 30%;
  margin-bottom: 0px;
}

.header_modal_info_req{
  margin-bottom: 30px;
  border-bottom: 1px solid lightgray;
}

.modal_req_info{
  z-index: 2000;
}

.modal_req_info .modal-dialog{
  margin-top: 150px;
  max-width: 500px;
  border-radius: 20px;
  overflow: hidden;
}

.info_re_title_cont{
  height: 50px;
  width: 100%;
}

.info_re_title{
  font-size:  18px;
  text-align: center;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-weight: bold;
}

.label_req_info{
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
  color: var(--textColor);
}

.input_label_cont{
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input_label_container{
  width: 49%;
}

.input_label_single_cont{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin-bottom: 20px;
}

.input_req_info{
  width: 100%;
  border-radius: 10px;
  transition: all 0.3s ease-in;
  border: 1px solid lightgray;
  font-size: 18px;
  height: 50px;
  padding: 10px;
}

.input_req_info:focus{
  outline: none;
  box-shadow: 2px 2px 10px 2px lightgray;
}

.text_req{
  height: 150px;
}

.footer_btn_req_cont{
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid lightgray;
}

.start_journey_btn{
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  counter-reset: var(--milkyWhite);
  color: white;
}

/* Start journey */
.jour_navbar{
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding-right: 50px;
}

.jour_navbar i{
  height: 100%;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 35px;
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.main_container_journey{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main_container_journey_cont{
  width: 48%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0px;
  height: 80vh;
}

.main_container_journey_cont:nth-child(2){
  overflow-y: scroll;
}


.main_container_journey_title{
  width: 100%;
  font-size: 80px;
  font-weight: bold;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.steps_cont_jr{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-radius: 20px;
}

.steps_cont_jr_block{
  height: 200px;
  width: 100%;
  border-bottom: 1px solid var(--lightGrey);
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.steps_cont_jr_block_title{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
}

.steps_cont_jr_block_subtitle{
  color: gray;
  margin-bottom: 0px;
}

.steps_cont_jr_block_inner{
  height: 100%;
  width: 49%;
}

.img_cont_jr{
  height: 200px;
  width: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.footer_journey_cont{
  height: 70px;
  display: flex;
  flex-direction: row-reverse;
  align-items: start;
  padding-right: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.steps_cont_jr_block_inputs{
  height: auto;
  width: 100%;
  /* border-bottom: 1px solid var(--lightGrey); */
  margin-bottom: 20px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.steps_cont_jr_block_input{
  border: 1px solid lightgray;
  height: 45px;
  border-radius: 10px;
  width: 100%;
  transition: all 0.3s ease-in;
  font-size: 18px;
  padding: 10px;
}


.steps_cont_jr_block_input:focus{
  outline: none;
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
  border: 1px solid var(--mainColor);
}


.steps_cont_jr_block_input.input_error_pm{
  border: 1px solid var(--danger);
}

.how_many_prop_cont{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.how_may_prop_choises_cont{
  width: 100%;
  height: auto;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.how_may_prop_choises{
  border: 1px solid lightgray;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  /* box-shadow: 2px 2px 10px 2px var(--lightGrey); */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.how_may_prop_choises_title{
  margin-bottom: 0px;
}

.how_may_prop_choises_title_num{
  font-size: 50px;
  margin-bottom: 0px;
  width: 230px;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 10px;
  transition: all 0.3s ease-in;
}

.how_may_prop_choises_title_num:focus{
  outline: none;
  border: 2px solid var(--mainColor);
  border-radius: 10px;
}

.prop_amount_btn_cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.prop_amount_btn{
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  height: 40px;
  width: 40px;
  border-radius: 10px;
}

.property_input_block{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
}

.error_message_pm{
  color: var(--danger);
  height: 20px;
  font-size: 18px;
  margin-bottom: 0px;
}

.service_block_cont{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.service_choise_block{
  min-height: 220px;
  max-height: auto;
  cursor: pointer;
  border: 1px solid lightgray;
  width: 32%;
  border-radius: 20px;
  transition: all 0.3s ease-in;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  transition: all 0.3s ease-in;
}

.service_choise_block:hover{
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.selected_service_choise{
  border: 1px solid var(--mainColor);
}

.service_choise_header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.service_choise_block_title{
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}

.service_choise_block_desc{
  font-size: 14px;
  height: 100px;
}

.service_choise_block_type{
  font-size: 12px;
  border: 1px solid var( --mainColor);
  border-radius: 100px;
  margin-bottom: 0px;
  padding: 0px 10px;
  color: white;
  background-color: var(--mainColor);
}

.how_does_service_work_cont{
  width: 100%;
  height: auto;
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 5px;
}

.how_does_service_work_cont i{
  font-size: 15px;
  margin-bottom: 0px;
  margin-left: auto;
  text-align: end;
  border: 1px solid lightgray;
  background-color: white;
  padding: 10px;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  transition: all 0.3s ease-in;
  margin-bottom: 10px;
}

.how_does_service_work_cont i:hover{
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
  color: white;
}

.service_desc_cont{
  border: 1px solid var(--lightGrey);
  border-radius: 10px;
  background-color: var(--lightGrey);
  width: 100%;
  height: auto;
  padding: 10px;
  font-size: 12px;
  transition: all 0.3s ease-in;
}

.open_desc{
  height: auto;
  transition: all 0.3s ease-in;
}

.closed_desc{
  height: 0px;
}

/* Completed PM request */
.completed_pm_request_cont{
  width: 70%;
  margin: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;
}

.completed_pm_request_block_cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.completed_pm_request_block{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.img_completed_pm_block{
  background-image: url("../public/IMG/rocket.jpg");
  rotate: 45deg;
  margin-top: 30px;
  height: 500px;
  width: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
