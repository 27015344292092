:root {
  --mainColor: #5659ff;
  --secondColor: #a483f0;
  --azure: #269ae2;
  --textColor: #2e3242;
  --lightGrey: #eaedf0;
  --milkyWhite: rgb(247, 247, 247);
  --danger: #ff5869;
  --font: Sohne, sans-serif;
  --logoFont: "Tilt Warp", sans-serif;
}

@media (max-width: 866px) {
  /* Logo page */
  .logo_page_cont {
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-content: start;
  }

  #title_logo {
    /* margin-top: 200px; */
    font-size: 40px;
    color: var(--textColor);
    /* font-weight: bold; */
    margin-bottom: 0px;
    cursor: pointer;
    font-family: var(--logoFont);
  }

  #title_logo i {
    color: var(--mainColor);
    background: linear-gradient(
      to bottom,
      var(--mainColor),
      var(--secondColor)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .slogan {
    margin-left: 60px;
    font-size: 10px;
  }

  /* Policies */
  .policies_container {
    text-align: start;
  }

  .policies_cont {
    border: 2px solid var(--lightGrey);
    border-radius: 20px;
    background-color: var(--milkyWhite);
    width: 95%;
    margin: auto;
    padding: 20px;
    margin-bottom: 50px;
  }

  .policy_title_logo {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    align-items: center;
    width: 95%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .policy_title_logo i {
    color: var(--mainColor);
    background: linear-gradient(
      to bottom,
      var(--mainColor),
      var(--secondColor)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .policy_title_logo p {
    font-family: var(--logoFont);
    margin-bottom: 0px;
  }

  .title_policy {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .declaration_policy {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .section_titles {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .section_sub_title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .description_policy_title {
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 12px;
  }

  .description_policy {
    margin-bottom: 10px;
    font-size: 12px;
  }

  /* Body */
  body {
    background-color: white;
    font-family: var(--font);
    font-size: 20px;
    color: var(--textColor);
  }

  /* Main Frame For all pages */
  .main_frame {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  /* Navbar */
  .main_navbar {
    position: sticky;
    top: 0;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    backdrop-filter: blur(10px);
    z-index: 2000;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.3s ease;
  }

  .main_navbar.scrolled {
    top: 10px;
    width: 95%;
    box-shadow: 2px 2px 20px 10px rgb(245, 245, 250, 0.8);
  }

  .navbar {
    width: 100%;
    margin: auto;
    height: 50px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .main_navbar a {
    color: var(--textColor);
    text-decoration: none;
  }

  .nav_cont_1 {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav_cont_1 span {
    font-size: 15px;
  }

  .nav_cont_1:last-child {
    flex-direction: row-reverse;
  }

  .nav_cont_1 a {
    text-decoration: none;
  }

  #title_navbar {
    font-size: 20px;
    color: var(--textColor);
    margin-bottom: 0px;
    cursor: pointer;
    font-family: var(--logoFont);
  }

  #title_navbar i {
    color: var(--mainColor);
    background: linear-gradient(
      to bottom,
      var(--mainColor),
      var(--secondColor)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .links_navbar {
    margin-right: 20px;
  }

  .register_btn {
    text-align: center;
    border: 1px solid var(--lightGrey);
    width: auto;
    padding: 5px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: none;
  }

  .login_btn {
    text-align: center;
    border: 1px solid transparent;
    background-color: transparent;
    width: auto;
    padding: 5px;
    border-radius: 4px;
    color: var(--textColor);
    font-size: 12px;
    display: none;
  }

  /* Main Content */
  .main_content {
    height: auto;
    text-align: center;
    margin-bottom: 200px;
  }

  .main_section {
    display: flex;
    padding-top: 50px;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }

  .scroller_cont {
    width: 95%;
    margin: auto;
    text-align: start;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bolder;
    text-wrap: wrap;
  }

  .main_title {
    margin-bottom: 0px;
  }

  .descriptions_sec {
    width: 95%;
    font-weight: bold;
    margin: auto;
    text-align: start;
    height: 100px;
  }

  .main_description {
    color: gray;
    font-size: 20px;
  }

  .ia_span {
    font-weight: bold;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .widget_scroller_arrow_cont {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    transition: all 1s ease-in;
  }

  .arrow_widget_scroller {
    border: 1px solid transparent;
    background-color: transparent;
    font-size: 20px;
    display: none;
  }

  .widget_scroller {
    height: 100px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
  }

  .scrolling {
    display: flex;
    width: max-content;
    transition: all 0.5s ease;
    /* Adjust timing and easing as necessary */
  }

  /* Globe Section */
  .globe_home {
    background-color: var(--textColor);
    width: 100%;
    height: 700px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 100px;
  }

  .globe_elem {
    width: 100%;
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .title_globe_cont {
    width: 100%;
    padding: 5px;
    text-align: start;
  }

  .globe_title {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .globe_subtitle {
    font-size: 15px;
  }

  .title_cont_globe {
    height: 40%;
  }

  .globe_cont {
    height: 60%;
  }

  .widget_element {
    border: 1px solid var(--lightGrey);
    background-color: var(--lightGrey);
    width: 220px;
    height: 42px;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--textColor);
    font-weight: bold;
    transition: border 1s ease-in-out;
    cursor: pointer;
  }

  .widget_element:hover {
    border: 1px solid var(--mainColor);
  }

  .widget_element span {
    font-size: 25px;
    height: 100%;
    width: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-around;
  }

  .widget_element p {
    width: 170px;
    text-align: center;
    font-size: 15px;
    margin-bottom: 0px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }

  .scrolling {
    display: flex;
    animation: scroll 25s linear infinite;
  }

  /* OnBoarding Section */
  .onboarding_container {
    height: 100%;
    width: 95%;
    margin: auto;
    text-align: start;
    margin-bottom: 150px;
  }

  .steps_title {
    font-size: 30px;
    font-weight: bold;
  }

  .steps_desc {
    font-weight: bold;
    color: gray;
  }

  .onboarding_sections {
    height: 300px;
    width: 100%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: unset;
  }

  .onboarding_sections::-webkit-scrollbar {
    display: none;
    /* Hide the scrollbar */
  }

  /* Hide scrollbar for Firefox */
  .onboarding_sections {
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
  }

  .card_onboarding {
    border: 2px solid var(--lightGrey);
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 2px 2px 20px 2px var(--lightGrey);
    transition: all 0.3s ease-in;
    padding: 10px;
    border: 1px solid var(--lightGrey);
    height: 100%;
    background-color: white;
    min-width: 300px;
    margin-right: 20px;
  }

  .card_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    text-align: start;
    background-color: transparent;
    opacity: 0.8;
  }

  .card_icon {
    margin-right: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    font-size: 20px;
    color: var(--danger);
    border: 2px solid var(--danger);
    border-radius: 10px;
    width: 40px;
    padding: 5px;
    text-align: center;
  }

  .card_title p {
    margin-bottom: 0px;
  }

  .card_text {
    height: 80%;
    padding: 10px;
    text-align: start;
    border-radius: 20px;
    background-color: white;
  }

  .personalized_words {
    font-weight: bold;
    color: var(--mainColor);
  }

  /* payment Process */
  .payment_process_container {
    height: 100%;
    width: 95%;
    margin: auto;
    text-align: start;
    background-color: var(--milkyWhite);
    margin-bottom: 100px;
    border-radius: 20px;
  }

  .pay_cont {
    border: 2px solid var(--lightGrey);
    border-radius: 30px;
    height: auto;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pay_container {
    width: 100%;
    margin: auto;
    height: auto;
    padding: 20px;
  }

  .phone_cont {
    border: 1px solid var(--textColor);
    background-color: var(--textColor);
    width: 270px;
    margin: auto;
    height: 520px;
    border-radius: 30px;
    /* margin-bottom: 2%; */
    padding: 3px;
  }

  .phone_screen {
    border: 1px solid var(--lightGrey);
    width: 100%;
    height: 100%;
    border-radius: 27px;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  .phone_camera {
    border: 1px solid var(--lightGrey);
    background-color: var(--lightGrey);
    width: 80px;
    margin: auto;
    height: 12px;
    border-radius: 50px;
  }

  .phone_content {
    height: 90%;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid black; */
  }

  .phone_hour {
    font-size: 50px;
    margin-bottom: 0px;
  }

  .phone_date {
    font-size: 15px;
    margin-bottom: 0px;
    color: gray;
    margin-bottom: 20px;
  }

  .phone_notification_cont {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @keyframes scaleUp {
    0%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }
  }

  .phone_notification {
    height: 50px;
    width: 95%;
    border: 1px solid var(--lightGrey);
    background-color: rgb(128, 128, 128, 0.1);
    filter: blur(0.1);
    border-radius: 10px;
    margin-bottom: 5px;
    padding: 10px;
    transition: transform 0.5s ease-in-out;
  }

  .phone_notification.active {
    animation: scaleUp 1s ease-in-out;
  }

  .message_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .message_header {
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .message_header i {
    font-size: 12px;
    color: var(--mainColor);
    margin-right: 5px;
  }

  .message_title {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 0px;
    width: 80%;
    text-align: start;
  }

  .hour_mex {
    font-size: 10px;
    color: gray;
  }

  .message_not {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
  }

  .message_not p {
    margin-bottom: 0px;
    margin-left: 20px;
    font-size: 10px;
  }

  .phone_bar {
    height: 5px;
    background-color: var(--lightGrey);
    border: 1px solid var(--lightGrey);
    border-radius: 50px;
    width: 120px;
    margin: auto;
  }

  .phone_shadow {
    /* background-color: var(--lightGrey); */
    height: 0%;
    width: 240px;
    margin: auto;
    box-shadow: 2px 20px 30px 3px black;
    border-radius: 150px;
  }

  .pay_desc_title {
    width: 100%;
    text-align: start;
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 25px;
    height: auto;
  }

  .pay_description {
    height: auto;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-bottom: 2px solid var(--mainColor);
    text-align: start;
  }

  /* Calendar Process */
  .calendar_manage_container {
    height: 100%;
    width: 95%;
    text-align: start;
    margin: auto;
    margin-bottom: 100px;
  }

  .calendar_process {
    width: 100%;
  }

  .cal_cont {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 800px;
    margin-top: 5%;
  }

  .cal_sec {
    width: 100%;
    height: 50%;
  }

  .long_term_img {
    height: 70%;
    width: 100%;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../public/IMG/scooter_boy.jpg");
  }

  .cal_desc_cont {
    margin: auto;
    height: 100%;
    width: 100%;
    text-align: start;
    border: 2px solid var(--danger);
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 20px 2px var(--lightGrey);
  }

  .cal_desc_title {
    font-size: 20px;
    font-weight: bold;
    color: var(--danger);
  }

  /* Property marketing */
  .property_marketing {
    width: 95%;
    height: auto;
    margin-bottom: 100px;
    max-width: 100%;
    margin: auto;
    text-align: start;
    overflow: hidden;
    margin-bottom: 100px;
  }

  .arrow_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }

  .arrow_widget_scroller_prop {
    border: 1px solid var(--milkyWhite);
    background-color: var(--milkyWhite);
    width: 100px;
    font-size: 20px;
    border-radius: 10px;
  }

  .property_marketing_cont {
    width: 95%;
    height: 600px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    transition: transform 0.5s ease;
    padding-left: 20px;
  }

  .property_marketing_card {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .property_marketing_card_img_cont {
    border: 1px solid var(--lightGrey);
    width: 100%;
    height: 65%;
    border-radius: 20px;
    box-shadow: 2px 2px 20px 2px var(--milkyWhite);
  }

  .property_marketing_desc_cont {
    width: 100%;
    height: 35%;
    /* border: 2px solid var(--lightGrey); */
    padding: 5px;
    background-color: white;
  }

  .description_title {
    width: 100%;
    color: var(--mainColor);
    text-align: start;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 20px;
  }

  .description_desc {
    width: 100%;
    font-size: 15px;
    text-align: start;
  }

  /* Pricing */
  .pricing_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .pricing_block {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    border: 1px solid var(--lightGrey);
    padding: 5px;
    margin-bottom: 100px;
  }

  .pricing_block_title_cont {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 100%;
  }

  .pricing_block_title {
    font-size: 40px;
    width: 100%;
    text-align: start;
  }

  .pricing_block_subtitle {
    font-size: 20px;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .pricing_block_main {
    border: 1px solid var(--lightGrey);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 2px 2px 20px 2px var(--milkyWhite);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
  }

  .method_pricing_block {
    height: 40%;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .method_pricing_title {
    font-weight: bold;
    color: gray;
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 5px;
  }

  .method_pricing_block_inner {
    border: 2px solid var(--lightGrey);
    background-color: var(--milkyWhite);
    border-radius: 10px;
    width: 48%;
    height: 100%;
    text-align: center;
    padding: 10px;
  }

  .method_pricing_block_title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .fee_cost {
    font-size: 20px;
    font-weight: bold;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fees_table_container {
    height: 100%;
    width: 95%;
    margin: auto;
    margin-bottom: 100px;
  }

  .fee_table_title {
    font-weight: bold;
    color: var(--textColor);
  }

  .fee_table {
    width: 100%;
    height: auto;
  }

  .body_fee {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }

  .body_row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;
    border: 1px solid var(--lightGrey);
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .body_cell_first,
  .body_cell_second,
  .body_cell_third {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    font-size: 20px;
    font-weight: bold;
    color: var(--textColor);
  }

  .body_cell_first {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .list_functionallities_title {
    font-size: 15px;
  }

  .list_functionallities_section {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .list_functionallities_section i {
    color: var(--mainColor);
  }

  .list_functionallities_section .circle {
    color: green;
  }

  .fee_third {
    font-size: 15px;
    color: green;
    text-decoration: underline;
    cursor: pointer;
  }

  .body_cell_third {
    border: 1px solid var(--lightGrey);
    width: 90%;
    margin: auto;
    margin-top: 20px;
    text-align: center;
    color: var(--mainColor);
  }

  .show_fee {
    border: 1px solid var(--mainColor);
    transition: all 0.5s ease-in;
  }

  .hide_fee {
    border: 1px solid var(--lightGrey);
    transition: all 0.5s ease-in;
  }

  /* Payment transactions */
  .payment_transactions_main_cont {
    width: 100%;
    margin: auto;
    height: 800px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 200px;
    padding: 20px;
  }

  .payment_transactions_cont {
    position: relative;
    width: 95%;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 200px;
    padding: 20px;
    border-radius: 30px;
    background-color: white;
    z-index: 1;
  }

  .payment_transactions_cont::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    padding: 2px;
    background: linear-gradient(
      90deg,
      var(--mainColor),
      var(--milkyWhite),
      white,
      var(--milkyWhite),
      var(--secondColor),
      var(--milkyWhite),
      var(--azure)
    );
    background-size: 300% 300%;
    animation: rotate-border 5s linear infinite;
    z-index: -1;
    mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
    -webkit-mask: linear-gradient(white 0 0) content-box,
      linear-gradient(white 0 0);
    mask-composite: exclude;
  }

  @keyframes rotate-border {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  .payment_transaction_title_cont {
    width: 100%;
    height: 100%;
  }

  .payment_transaction_title {
    font-size: 40px;
  }

  .payment_transaction_subtitle {
    font-size: 20px;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .transactions_punchline {
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
    width: 100%;
    margin: auto;
    height: 400px;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .svg_cont {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .svg_cont svg {
    width: 100%;
    height: 110%;
    color: white;
    rotate: 90deg;
  }

  .transaction_title {
    font-size: 30px;
    text-align: start;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .transaction_subtitle {
    width: 100%;
    padding-left: 5px;
    color: gray;
    font-weight: bold;
  }

  .transaction_desc {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box-shadow: 2px 2px 10px 2px var(--lightGrey); */
    border: 1px solid var(--lightGrey);
    padding: 10px;
  }

  .bullet_points {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 10px;
  }

  .bullet_points p {
    margin-bottom: 0px;
  }

  .bullet_point_explanation_title {
    font-size: 18px;
    font-weight: bold;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
  }

  .bullet_point_explanation {
    font-weight: bold;
    color: gray;
    font-size: 15px;
  }

  /* Animations transactions */
  .phone_transactions_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tenant_manager_container {
    border: 2px solid var(--lightGrey);
    border-radius: 20px;
    width: 300px;
    height: 40px;
    margin-bottom: 20px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
  }

  .tenant_manager_btns {
    border: 1px solid lightgray;
    background-color: var(--lightGrey);
    width: 32%;
    height: 100%;
    font-size: 12px;
    border-radius: 20px;
    transition: all 0.3s ease-in;
    font-weight: bold;
    color: gray;
  }

  .tenant_manager_btns:hover {
    background-color: var(--mainColor);
    color: white;
    border: 1px solid var(--mainColor);
  }

  .phone_transaction {
    border: 3px solid lightgray;
    border-bottom: 0px;
    height: 350px;
    width: 300px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 5px;
  }

  .phone_screen_transaction {
    border: 1px solid var(--lightGrey);
    border-radius: 27px;
    height: 100%;
    display: flex;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding-top: 30px;
    box-shadow: 2px 2px 10px 2px var(--lightGrey);
  }

  .transaction_section {
    min-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .transaction_section_title {
    color: var(--mainColor);
    font-weight: bold;
    opacity: 0.8;
    font-size: 15px;
    margin-bottom: 0px;
    height: 10%;
  }

  .creation_tenant_transaction_cont {
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    border-radius: 20px;
    height: 80%;
    width: 100%;
    overflow: hidden;
    padding: 10px;
  }

  .rows_transactions {
    height: 30%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid white;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    padding-left: 10px;
    justify-content: space-between;
    transform: scale(0.8);
  }

  .add_rows_transactions {
    width: 15%;
    text-align: center;
    color: var(--secondColor);
    border: 1px solid var(--secondColor);
    height: 70%;
    border-radius: 10px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .content_row_transaction {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    animation: grow 0.5s ease-in-out forwards;
  }

  @keyframes grow {
    0% {
      transform: scale(0.8);
    }

    100% {
      transform: scale(1);
    }
  }

  .rows_transactions_animation {
    animation: grow 0.5s ease-in-out forwards;
    cursor: pointer;
  }

  .rows_transactions:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }

  .rows_transactions_img {
    border: 1px solid var(--azure);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: var(--azure);
  }

  .rows_transactions_tenant {
    border: 1px solid white;
    width: 70%;
    height: 15px;
    border-radius: 50px;
    background-color: white;
  }

  .combine_transaction_property {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .prop_tenant_transaction {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }

  .rows_transactions_img_house {
    height: 35px;
    width: 35px;
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
  }

  #ball,
  #house {
    width: 70px;
    height: 70px;
    position: relative;
  }

  /* Define the animations */
  @keyframes moveRight {
    from {
      left: 0;
    }

    to {
      left: 50%;
    }
  }

  @keyframes moveLeft {
    from {
      left: 0;
    }

    to {
      left: -50%;
    }
  }

  .animate-ball {
    animation: moveRight 2s ease forwards;
  }

  .animate-house {
    animation: moveLeft 2s ease forwards;
  }

  .payment_transaction_cont {
    border: 1px solid var(--milkyWhite);
    width: 100%;
    height: 70%;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .test {
    font-size: 80px;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: bounce 2s infinite;
  }

  .shadow_pay {
    background-color: gray;
    box-shadow: 2px 2px 5px 2px gray;
    width: 70px;
    height: 10px;
    border-radius: 100%;
    opacity: 0.2;
  }

  /* Waiting list form */
  .waiting_list_container {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }

  .waiting_list_cont {
    border: 2px solid transparent;
    height: 100%;
    width: 100%;
    margin: auto;
    border-radius: 30px;
    padding: 10px;
  }

  .waiting_list_form_title {
    font-size: 15px;
    margin-bottom: 0px;
    font-weight: bold;
    color: gray;
  }

  .first_waiting {
    width: 100%;
    border: 2px solid transparent;
    color: var(--mainColor);
    /* background-color: var(--mainColor); */
  }

  .waitlist_title {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .waitlist_title i {
    color: var(--mainColor);
    background: linear-gradient(
      to bottom,
      var(--mainColor),
      var(--secondColor)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .waitlist_title p {
    font-family: var(--logoFont);
    margin-bottom: 0px;
    color: var(--textColor);
  }

  .main_img_waitlist {
    background-image: url("../public/IMG/city.jpg");
    height: 450px;
    width: auto;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
  }

  .input_label_waitlist_cont {
    border: 1px solid transparent;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .user_type_container_waitlist label,
  .input_label_waitlist_cont label {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    color: gray;
    position: sticky;
    left: 0;
    top: 0;
  }

  .input_waitlist {
    width: 100%;
    border: 1px solid transparent;
    border-bottom: 2px solid var(--lightGrey);
    height: 35px;
    font-size: 15px;
    font-weight: bold;
    transition: all 0.3s ease-in;
    color: var(--mainColor);
  }

  .input_waitlist:focus {
    outline: none;
    font-weight: normal;
    transition: all 0.3s ease-in;
    color: var(--textColor);
  }

  .input_waitlist:hover {
    border-bottom: 2px solid var(--mainColor);
  }

  .user_type_container_waitlist {
    /* border: 1px solid var(--lightGrey); */
    height: 215px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    /* padding: 20px; */
    margin-bottom: 20px;
    overflow: auto;

    /* Hide scrollbar for WebKit browsers */
    scrollbar-width: none;
    /* Firefox */
  }

  .user_type_container_waitlist::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
  }

  .user_type_container_waitlist_cont {
    height: auto;
    width: 800px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  .user_type_card_waitlist {
    border: 2px solid var(--lightGrey);
    height: 100%;
    padding: 5px;
    margin-right: 10px;
    transition: all 0.3s ease-in;
    cursor: pointer;
    width: 150px;
  }

  .selected_usertype {
    border: 2px solid var(--mainColor);
    border-radius: 10px;
    color: var(--mainColor);
  }

  .user_type_card_waitlist:hover {
    border-radius: 10px;
    box-shadow: 2px 2px 5px 2px var(--lightGrey);
    border: 2px solid lightgray;
    color: var(--mainColor);
  }

  .usertype_title {
    width: 100%;
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: bold;
    height: 20px;
  }

  .img_cont_waitlist {
    height: 130px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .problematics_cont {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .options_problems {
    border: 1px solid var(--lightGrey);
    background-color: var(--milkyWhite);
    height: 30px;
    font-size: 13px;
    margin-right: 10px;
    padding: 5px;
    border-radius: 50px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }

  .options_problems:hover {
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
    color: white;
  }

  .selected_problem {
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
    color: white;
  }

  .waitin_list_btn_home{
    text-align: center;
    border: 1px solid var(--lightGrey);
    width: auto;
    padding: 15px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    color: white;
    background: linear-gradient(to right, var(--secondColor), var(--azure));
    background-clip: padding-box;
    width: 95%;
    margin: auto;
  }

  .waitin_list_btn_start {
    text-align: center;
    border: 1px solid var(--lightGrey);
    width: auto;
    padding: 5px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    color: white;
    background: linear-gradient(to right, var(--secondColor), var(--azure));
    background-clip: padding-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 220px;
    height: 40px;
  }

  .waitin_list_btn_start span {
    width: 100%;
    margin-right: 20px;
  }

  .waitin_list_btn_start .spinner_cont {
    width: 20px;
    height: 20px;
  }

  .waitin_list_btn_start #spinner {
    color: white;
  }

  /* Waitlist confirmation */
  .waitlist_confirm_main_cont {
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 10px;
  }

  .waitlist_confirm_title {
    width: 100%;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    color: var(--mainColor);
    margin-bottom: 0px;
  }

  .waitlist_confirm_subtitle {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: gray;
  }

  .suprise_waitlist {
    position: relative;
    width: 100%;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    padding: 10px;
  }

  .suprise_waitlist_cont {
    text-align: center;
    width: 100%;
    height: 50%;
    opacity: 0;
    margin-top: 0px;
    transform: translateY(100px);
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    /* Smooth transition for both opacity and movement */
  }

  .waitlist_animation {
    opacity: 1;
  }

  .show_text {
    opacity: 1;
    transform: translateY(0);
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .rocket_img {
    background-image: url("../public/IMG/rocket.jpg");
    height: 200px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    rotate: 0deg;
    border-radius: 50%;
    position: relative;
    top: -100px;
    transition: top 1s ease-in-out, opacity 1s ease-in-out,
      transform 1s ease-in-out;
    /* Add transform transition for rotation */
    opacity: 0;
  }

  .rocket_down {
    top: 0;
    opacity: 1;
    transform: rotate(0deg);
  }

  .rocket_up {
    top: -50px;
    opacity: 0;
    transform: rotate(45deg);
  }

  .explanation_waitlist_title {
    color: var(--mainColor);
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 0px;
  }

  .explanation_waitlist_subtitle {
    color: gray;
    font-size: 15px;
    font-weight: bold;
  }

  .explanation_waitlist {
    border: 1px solid var(--lightGrey);
    padding: 10px;
  }

  .waitlist_confirm_btn_cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .explanation_waitlist button {
    margin-top: 20px;
  }

  .explanation_waitlist p {
    margin-bottom: 10px;
    font-size: 15px;
    /* color: gray; */
  }

  .become_partner_btn {
    border: 2px solid var(--mainColor);
  }

  .waitin_list_btn_no {
    font-size: 12px;
  }

  /* Check email */
  .check_email_cont {
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
    height: 300px;
    width: 95%;
    margin: auto;
    margin-top: 100px;
    border-radius: 30px;
    color: white;
    padding: 20px;
    text-align: center;
  }

  .check_email_title {
    font-size: 40px;
    /* font-family: var(--logoFont); */
    font-weight: bold;
    margin-bottom: 20px;
  }

  .check_email_cont i {
    font-size: 60px;
    margin-bottom: 50px;
  }

  .close_home {
    font-size: 12px;
  }

  .link_email {
    color: white;
  }

  .link_email:hover {
    color: white;
  }

  /* Footer */
  .footer_homepage {
    border: 2px solid var(--lightGrey);
    background-color: var(--milkyWhite);
    height: auto;
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
  }

  .footer_container {
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* padding: 100px; */
  }

  .sections_footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */
  }

  .footer_titles {
    font-weight: bold;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
  }

  .link_cont_footer {
    height: 100%;
    width: 100%;
  }

  .link_footer {
    font-size: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
  }

  .link_footer:hover {
    color: var(--mainColor);
  }

  .language_select {
    border: 1px solid var(--mainColor);
    font-size: 15px;
    background-color: var(--milkyWhite);
    padding: 5px;
    border-radius: 5px;
  }

  .language_select:focus {
    outline: none;
  }

  .section_footer_text {
    width: 100%;
    padding: 20px;
    height: 100%;
    margin: auto;
    /* margin-top: 100px; */
    /* margin-bottom: 300px; */
  }

  .brand_logo {
    font-weight: bold;
    font-size: 25px;
  }

  .brand_rights {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .logo_social_container {
    margin: auto;
    text-align: start;
    width: 100%;
    margin-bottom: 20px;
  }

  .logo_social_container i {
    margin-right: 10px;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
  }

  .logo_social_container i:hover {
    color: var(--mainColor);
  }

  .newsletter_cont {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .newsletter_title {
    margin-bottom: 0px;
    color: var(--mainColor);
    font-size: 15px;
  }

  .input_newsletter {
    border: 1px solid var(--mainColor);
    border-radius: 5px;
    font-size: 15px;
    padding-left: 5px;
    width: 200px;
    height: 30px;
    margin-right: 5px;
  }

  .input_newsletter:focus {
    outline: none;
  }

  .input_newsletter::placeholder {
    color: lightgray;
  }

  .button_newsletter {
    height: 30px;
    font-size: 20px;
    width: 30px;
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
    color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  /* Register Initial page */
  .register_page {
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  .register_conts_reg,
  .register_conts {
    width: 100%;
    height: 100%;
  }

  .reg_cont {
    margin: auto;
    margin-top: 100px;
    width: 100%;
    height: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* box-shadow: 200px 10px 1000px 1px var(--mainColor); */
    border: 2px solid transparent;
    margin-bottom: 10px;
  }

  .title_form {
    font-family: var(--logoFont);
    text-align: start;
    width: 100%;
    color: var(--textColor);
  }

  .btn_a_start {
    width: 100%;
    height: 40px;
    /* border-radius: 10px; */
  }

  .start_btn {
    border: 2px solid var(--milkyWhite);
    font-size: 15px;
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    color: white;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in;
  }

  .start_btn:hover {
    border: 2px solid var(--mainColor);
  }

  .register_form_first {
    padding: 10px;
    border: 1px solid var(--lightGrey);
    height: 40px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 2px var(--milkyWhite);
    width: 100%;
    margin-bottom: 20px;
    color: gray;
    font-size: 15px;
  }

  .register_form_first:focus {
    outline: none;
  }

  .register_form_first::placeholder {
    font-size: 13px;
    color: rgb(184, 181, 181);
  }

  .persona_reg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .person_reg {
    width: 49%;
  }

  .password_eye {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    justify-content: space-between;
  }

  .show_passowrd {
    margin-bottom: 0px;
    border: 1px solid var(--lightGrey);
    background-color: var(--lightGrey);
    height: 40px;
    width: 15%;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 15px;
    border: 2px solid var(--lightGrey);
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .phone_number {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .phone_number select {
    width: 15%;
    margin-bottom: 20px;

    border: 1px solid var(--lightGrey);
    background-color: var(--lightGrey);
    height: 40px;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 15px;
    border: 2px solid var(--lightGrey);
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .phone_number input {
    width: 83%;
  }

  .already_present {
    font-size: 12px;
    text-align: start;
    width: 100%;
    margin-bottom: 0px;
  }

  .already_present span {
    color: var(--mainColor);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
  }

  .mini_logo {
    margin-bottom: 0px;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .second_cont_reg {
    width: 100%;
    margin: auto;
    height: auto;
    border-radius: 5px;
    /* border: 2px solid var(--milkyWhite); */
    /* background-color: var(--milkyWhite); */
    margin-bottom: 10px;
  }

  .priv_mail_cont {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    color: gray;
    padding: 5px;
    border-radius: 5px;
  }

  .priv_mail_cont p {
    margin-bottom: 0px;
  }

  .priv_mail_cont input {
    margin-right: 5px;
    border: 1px solid var(--lightGrey);
    box-shadow: 0px 0px 5px 0px none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .priv_mail_cont input:hover {
    box-shadow: 0px 0px 3px 0px var(--azure);
  }

  /* Spinner and errors */
  .spinner_cont {
    width: 100%;
    text-align: center;
  }

  #spinner {
    color: var(--mainColor);
    font-size: 15px;
    height: 20px;
    width: 20px;
  }

  .error_message {
    color: var(--danger);
    font-size: 12px;
    font-weight: bold;
  }

  /* Externa signature */
  .external_signature_container {
    display: block;
    height: 400px;
    text-align: center;
    padding: 20px;
  }

  .external_signature_title {
    color: var(--mainColor);
    font-weight: bold;
    font-size: 15px;
  }

  .signing_external_header_choise {
    border: 2px solid var(--lightGrey);
    height: 40px;
    width: fit-content;
    margin: auto;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 12px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .signing_external_header_btn {
    width: auto;
    height: 100%;
    border-radius: 7px;
    border: 1px solid var(--lightGrey);
    background-color: var(--lightGrey);
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5px;
  }

  .signing_external_header_btn p {
    font-size: 12px;
    margin-bottom: 0px;
    margin-right: 5px;
  }

  .canvas_signing_external {
    border: 1px solid var(--lightGrey);
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  /* Marketplace */
  .market_place {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 25px;
  }

  .property_card_marketplace {
    width: 98%;
    margin: auto;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;
  }

  .loading_market_place {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .property_img_blotter_loading {
    height: 270px;
    border: 1px solid var(--lightGrey);
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--lightGrey);
    transition: all 0.3s ease-in;
    cursor: pointer;
  }

  .property_btn_action_loading {
    border: 1px solid white;
    width: 170px;
    border-radius: 100px;
    height: 35px;
    padding: 5px 10px 5px 10px;
    background-color: white;
    color: var(--textColor);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }

  .property_btn_action_loading_second {
    border: 1px solid white;
    width: 40px;
    border-radius: 100px;
    height: 35px;
    padding: 5px 10px 5px 10px;
    background-color: white;
    color: var(--textColor);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }

  .property_description_cont_loading {
    height: 75px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .property_title_card_loading {
    height: 25px;
    background-color: var(--lightGrey);
    border-radius: 100px;
    margin-bottom: 5px;
    width: 200px;
  }

  .property_location_card_loading {
    height: 25px;
    background-color: var(--lightGrey);
    border-radius: 100px;
    margin-bottom: 5px;
    width: 120px;
  }

  .property_amount_card_loading {
    height: 25px;
    background-color: var(--lightGrey);
    border-radius: 100px;
    margin-bottom: 5px;
    width: 80px;
  }

  /* Property managment */
  .home_pm_cont {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main_pm_cont {
    height: auto;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 200px;
  }

  .main_pm_container {
    padding: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    margin-bottom: 200px;
  }

  .main_title_pm {
    font-size: 40px;
    margin-bottom: 0px;
  }

  .ia_span_pm {
    font-weight: bold;
    color: var(--mainColor);
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: oblique;
  }

  .main_subtite_pm {
    font-size: 18px;
  }

  .action_btn_cont {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;
  }

  .action_btns_pm {
    border: 1px solid lightgray;
    font-size: 20px;
    color: white;
    padding: 15px;
    width: 250px;
    border-radius: 10px;
    background: linear-gradient(to right, var(--mainColor), var(--secondColor));
    position: relative;
    box-shadow: 2px 2px 30px 2px lightgray;
    overflow: hidden;
  }

  .action_btns_pm::before,
  .action_btns_pm::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 120px;
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    animation: waveAnimation 3s infinite ease-out;
  }

  .action_btns_pm::before {
    animation-delay: 0s;
  }

  .action_btns_pm::after {
    animation-delay: 1.5s;
  }

  @keyframes waveAnimation {
    0% {
      width: 0;
      height: 0;
      opacity: 0.7;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }

  .phone_container_pm {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .phone_pm {
    width: 300px;
    height: 100%;
    border: 1px solid lightgray;
    border-radius: 30px;
    box-shadow: 2px 2px 20px 2px gray;
    padding: 3px;
    overflow: hidden;
    background-color: black;
    margin-top: 20px;
  }

  .inner_phone_pm {
    height: 100%;
    width: 100%;
    border-radius: 26px;
    padding: 5px;
    background-color: white;
  }

  .phone_mic_pm {
    border: 1px solid black;
    width: 30%;
    margin: auto;
    height: 20px;
    border-radius: 100px;
    background-color: black;
    margin-bottom: 50px;
  }

  .phone_cont_main_pm {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rent_type_phone_pm {
    border: 1px solid lightgray;
    width: 95%;
    height: 30px;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--lightGrey);
    padding: 2px;
    position: absolute;
    /* width: 350px; */
    margin-top: -650px;
    margin-left: -5px;
    display: none;
  }

  .rent_type_phone_inner_pm {
    font-size: 14px;
    margin-bottom: 0px;
    border: 1px solid white;
    height: 100%;
    width: 24%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 100px;
    transition: all 0.3s ease-in;
    cursor: pointer;
  }

  .selected_rental_type {
    border: 1px solid white;
    background-color: white;
  }

  .fade-container {
    width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease-in-out; /* Smooth fade effect */
  }
  .fade-container.fading {
    opacity: 0; /* Fade out */
  }

  .title_phone_pm_cont {
    margin-bottom: 0px;
    text-align: start;
    width: 95%;
  }

  .title_phone_pm {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .subtitle_phone_pm {
    font-size: 12px;
    color: gray;
  }

  .houses_cards_deck_pm {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .house_card_pm {
    height: 200px;
    border: 1px solid lightgray;
    /* box-shadow: 2px 2px 10px 2px var(--lightGrey); */
    width: 200px;
    border-radius: 20px;
    background-image: url("../public/IMG/bedroom.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .left_right_deck_pm {
    border: 1px solid var(--milkyWhite);
    background-color: var(--milkyWhite);
    height: 150px;
    width: 38px;
    z-index: 0;
  }

  .left_right_deck_pm:nth-child(1) {
    background-image: url("../public/IMG/bathroom.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px 0px 0px 20px;
  }

  .left_right_deck_pm:nth-child(3) {
    background-image: url("../public/IMG/kitchen.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0px 20px 20px 0px;
  }

  .last_card_pm {
    height: 120px;
    overflow: hidden;
    width: 100%;
    /* border: 1px solid lightgray; */
    margin-top: 50px;
    border-radius: 20px;
    background-image: url("../public/IMG/new_york_3d.png");
    background-size: 120%;
    background-position: center;
    /* background-color: var(--milkyWhite); */
    /* box-shadow: 2px 2px 10px 2px var(--lightGrey); */
    padding: 5px;
  }

  .last_card_pm_title {
    width: 100px;
    margin-left: auto;
    font-size: 14px;
    border: 1px solid white;
    background-color: white;
    border-radius: 20px;
    color: var(--textColor);
    text-align: start;
    font-weight: bold;
    height: 100%;
    padding: 10px;
  }

  .pin_cont {
    position: absolute;
    z-index: 1000;
    margin-left: 240px;
    animation: jello 3s infinite;
    rotate: -50deg;
  }

  .first_mex_phone {
    width: 300px;
    height: 80px;
    box-shadow: 2px 2px 30px 2px lightgray;
    border: 1px solid var(--lightGrey);
    position: absolute;
    z-index: 100;
    margin-left: 0px;
    margin-top: -670px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px;
    border: 1px solid lightgray;
  }

  .header_first_mex_phone_pm {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header_first_mex_phone_pm i {
    font-size: 15px;
    animation: swing 0.7s infinite;
  }

  .first_mex_phone_title {
    margin-bottom: 0px;
    font-size: 15px;
    margin-left: 5px;
    font-weight: bold;
  }

  .first_mex_phone_subtitle {
    font-size: 12px;
    margin-bottom: 0px;
    width: 85%;
    margin: auto;
  }

  .second_mex_phone {
    width: 300px;
    height: 200px;
    box-shadow: 2px 2px 30px 2px lightgray;
    border: 1px solid var(--lightGrey);
    position: absolute;
    z-index: 100;
    margin-right: 20px;
    margin-top: 670px;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    border: 1px solid lightgray;
  }

  .second_mex_phone_title {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: bold;
  }

  .second_mex_phone_subtitle {
    font-size: 12px;
    color: gray;
  }

  .tiny_line_cont {
    width: 100%;
    height: auto;
    position: absolute;
    margin-left: -75px;
    margin-top: -20px;
    z-index: 1;
  }

  .steps_pm_container {
    height: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 200px;
  }

  .steps_pm_cont_title {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
  }

  .steps_pm_cont_subtitle{
    font-size: 20px;
    text-align: center;
  }

  .steps_pm_cont {
    height: auto;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .step_pm_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    border-right: 2px solid var(--textColor);
    height: 170px;
    margin-bottom: 20px;
  }

  .step_pm_box:last-child {
    border-right: 2px solid var(--textColor);
  }

  .step_pm_title {
    color: var(--textColor);
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 20px;
  }

  .step_pm_subtitle {
    font-size: 15px;
    color: gray;
  }

  .room_image {
    height: 350px;
    width: 100%;
    background-image: url("../public/IMG/inside_house.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 50px;
  }

  .step_pm_box_icon {
    border: 1px solid var(--textColor);
    border-radius: 10px;
    color: white;
    width: 40px;
    height: 40px;
    background-color: var(--textColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  .testimony_pm_cont{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 200px;
  }
  
  .roi_cont_pm{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
  }
  
  .roi_blocks{
    border: 1px solid lightgray;
    background-color: white;
    box-shadow: 2px 2px 20px 2px var(--lightGrey);
    height: auto;
    width: 95%;
    margin: auto;
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .roi_blocks:nth-child(1){
    height: 500px;
  }
  .roi_blocks:nth-child(2){
    height: 700px;
  }
  .roi_blocks:nth-child(3){
    height: 500px;
  }
  
  .roi_block_title{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .roi_block_subtitle{
    font-size: 15px;
    margin-bottom: 0px;
    height: 80px;
  }
  
  .earnings_calculation_n{
    height: auto;
    border: 1px solid var(--lightGrey);
    background-color: var(--milkyWhite);
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px;
    justify-content: space-around;
    font-size: 14px;
    font-weight: bold;
  }
  
  .earnings_calculation_n p{
    margin-bottom: 5px;
  }
  
  .specifics{
    font-size: 12px;
    margin-bottom: 0px;
    border: 1px solid var(--azure);
    background-color: var(--azure);
    color: white;
    border-radius: 100px;
    padding: 0px 10px;
  }
  
  .costs_container_tite{
    font-size: 12px;
    margin-bottom: 0px;
  }
  
  .btn_cont_action_btns_pm{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .qa_pm_cont {
    height: auto;
    width: 100%;
    margin: auto;
    border-radius: 50px 50px 0px 00px;
    background-color: var(--milkyWhite);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 10px;
    border-bottom: 1px solid lightgray;
  }

  .qa_cont_pm {
    width: 100%;
    margin: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 100px;
  }

  .qa_toggle_cont {
    width: 100%;
    border-bottom: 1px solid var(--lightGrey);
    height: auto;
    padding: 20px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .qa_toggle_cont_title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: gray;
    margin-bottom: 15px;
  }

  .qa_cont_qestion {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .qa_cont_answer {
    font-size: 14px;
  }

  .qa_cont {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    padding: 0;
  }

  .qa_cont.open {
    max-height: 1000px; /* Arbitrary large value to accommodate content */
    padding: 10px 0;
  }

  .qa_toggle_cont_title {
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: color 0.3s ease;
  }

  .qa_toggle_cont_title:hover {
    color: var(--mainColor);
  }

  .fa-chevron-down {
    transition: transform 0.3s ease;
  }

  .qa_toggle_cont .open ~ .qa_toggle_cont_title .fa-chevron-down {
    transform: rotate(180deg);
  }

  .contact_us_pm_btn {
    border: 1px solid lightgray;
    font-size: 20px;
    color: white;
    padding: 15px;
    width: 250px;
    border-radius: 10px;
    background-color: var(--textColor);
    position: relative;
    box-shadow: 2px 2px 30px 2px lightgray;
    overflow: hidden;
    margin: auto;
  }

  .fj_comm_cont {
    width: 100%;
    height: auto;
    background-color: var(--milkyWhite);
    padding: 25px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .fj_comm_cont_title {
    font-size: 14px;
    font-weight: bold;
    color: gray;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
  }

  /* Start journey */
.jour_navbar{
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding-right: 0px;
}

.jour_navbar i{
  height: 100%;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 35px;
  color: var(--mainColor);
  background: linear-gradient(to bottom, var(--mainColor), var(--secondColor));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.main_container_journey{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.main_container_journey_cont{
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0px;
  height: auto;
  margin-bottom: 10px;
}

.main_container_journey_cont:nth-child(2){
  overflow-y: scroll;
  margin-bottom: 100px;
}


.main_container_journey_title{
  width: 100%;
  font-size: 55px;
  font-weight: bold;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  padding: 10px;
}

.steps_cont_jr{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
}

.steps_cont_jr_block{
  height: auto;
  width: 100%;
  border-bottom: 1px solid var(--lightGrey);
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.steps_cont_jr_block_title{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
}

.steps_cont_jr_block_subtitle{
  color: gray;
  margin-bottom: 0px;
}

.steps_cont_jr_block_inner{
  height: 100%;
  width: 100%;
}

.img_cont_jr{
  height: 200px;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.footer_journey_cont{
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  padding-right: 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.steps_cont_jr_block_inputs{
  height: auto;
  width: 100%;
  /* border-bottom: 1px solid var(--lightGrey); */
  margin-bottom: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.steps_cont_jr_block_input{
  border: 1px solid lightgray;
  height: 45px;
  border-radius: 10px;
  width: 100%;
  transition: all 0.3s ease-in;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 20px;
}


.steps_cont_jr_block_input:focus{
  outline: none;
  box-shadow: 2px 2px 20px 2px var(--lightGrey);
  border: 1px solid var(--mainColor);
}


.steps_cont_jr_block_input.input_error_pm{
  border: 1px solid var(--danger);
}

.how_many_prop_cont{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.how_may_prop_choises_cont{
  width: 100%;
  height: auto;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.how_may_prop_choises{
  border: 1px solid lightgray;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  /* box-shadow: 2px 2px 10px 2px var(--lightGrey); */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.how_may_prop_choises_title{
  margin-bottom: 0px;
}

.how_may_prop_choises_title_num{
  font-size: 50px;
  margin-bottom: 0px;
  width: 130px;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 10px;
  transition: all 0.3s ease-in;
}

.how_may_prop_choises_title_num:focus{
  outline: none;
  border: 2px solid var(--mainColor);
  border-radius: 10px;
}

.prop_amount_btn_cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.prop_amount_btn{
  border: 1px solid var(--lightGrey);
  background-color: var(--lightGrey);
  height: 40px;
  width: 40px;
  border-radius: 10px;
}

.property_input_block{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
}

.error_message_pm{
  color: var(--danger);
  height: 20px;
  font-size: 18px;
  margin-bottom: 0px;
}

.service_block_cont{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.service_choise_block{
  min-height: 220px;
  max-height: auto;
  cursor: pointer;
  border: 1px solid lightgray;
  width: 95%;
  margin-bottom: 20px;
  border-radius: 20px;
  transition: all 0.3s ease-in;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  transition: all 0.3s ease-in;
}

.service_choise_block:hover{
  box-shadow: 2px 2px 10px 2px var(--lightGrey);
}

.selected_service_choise{
  border: 1px solid var(--mainColor);
}

.service_choise_header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.service_choise_block_title{
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}

.service_choise_block_desc{
  font-size: 14px;
  height: 100px;
}

.service_choise_block_type{
  font-size: 12px;
  border: 1px solid var( --mainColor);
  border-radius: 100px;
  margin-bottom: 0px;
  padding: 0px 10px;
  color: white;
  background-color: var(--mainColor);
}

.how_does_service_work_cont{
  width: 100%;
  height: auto;
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 5px;
}

.how_does_service_work_cont i{
  font-size: 15px;
  margin-bottom: 0px;
  margin-left: auto;
  text-align: end;
  border: 1px solid lightgray;
  background-color: white;
  padding: 10px;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  transition: all 0.3s ease-in;
  margin-bottom: 10px;
}

.how_does_service_work_cont i:hover{
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
  color: white;
}

.service_desc_cont{
  border: 1px solid var(--lightGrey);
  border-radius: 10px;
  background-color: var(--lightGrey);
  width: 100%;
  height: auto;
  padding: 10px;
  font-size: 12px;
  transition: all 0.3s ease-in;
}

.open_desc{
  height: auto;
  transition: all 0.3s ease-in;
}

.closed_desc{
  height: 0px;
}

/* Completed PM request */
.completed_pm_request_cont{
  width: 100%;
  text-align: center;
  margin: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 100px;
}

.completed_pm_request_block_cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.completed_pm_request_block{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.img_completed_pm_block{
  background-image: url("../public/IMG/rocket.jpg");
  rotate: 0deg;
  margin-top: 50px;
  height: 300px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

}
